// material
import { Stack, Button, Divider, Typography } from '@mui/material';
// component
import { GoogleLogin } from "react-google-login";
import Iconify from '../../components/Iconify';
import axios from 'axios';

export default function AuthSocial(props) {
  const handleLogin = async googleData => {
    await axios({
      method: "POST",
      url: '/api/v1/auth/google',
      data:{
        token: googleData.tokenId
      },
      headers: {
        "Content-Type": "application/json"
      }
    } ).then((response) => {
      let data = response.data?.data;
      console.log(data);
      if(response.data?.status) {
         localStorage.setItem("auth-token", googleData.tokenId);
         localStorage.setItem("email", data.email);
         localStorage.setItem("username", data.name);
         localStorage.setItem("dp", data.picture);
         localStorage.setItem("loginMethod", "google");
         
        if(data?.userAuthStatus) { 
          getUser(data.email);
        } else {
          window.location.assign("/register")
        }
      } else {
        alert("error");
      }

    }).catch((err) => {
      console.log(err);
    })
  }

  const getUser = (mail) => {
    axios
      .post(`/api/user/getParticularUserByEmail`, { emailID: mail })
      .then((response) => {
        const userData = response.data.data;
        console.log(userData[0].role)
        if (userData[0].role.includes("coach") || userData[0].role.includes("superAdmin")){
          window.location.assign("/dashboard/academy")
        }
        else(
          window.location.assign("/dashboard/app")
        )
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  return (
    <>
     <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
      <GoogleLogin
          clientId="202843778625-642d81403phmel0jmfnue19jbvb2erlv.apps.googleusercontent.com"
          buttonText="Login with Google"
          style={{background: "transparent"}}
          onSuccess={handleLogin}
          onFailure={handleLogin}
          render={renderProps => (
            <Button fullWidth size="large" color="inherit" variant="outlined" 
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}>
          <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} sx={{mr:1}} />
          {props?.msg}
        </Button>
           
           )}
        
          cookiePolicy={"none"}
        />
        
        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
        </Button> */}
      </Stack>
    </>
  );
}
