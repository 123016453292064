import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import { EditorState, convertToRaw, convertFromHTML,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import Markdown from 'markdown-to-jsx';
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css"
import {userDetails} from '../constants'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const MyDiary = () => {

  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [deleteData, setDelete] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});
  const [user, setUser] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [userid, setUserId] = React.useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
      axios
      .post(`/api/user/getParticularUserByEmail`,{"emailID":userDetails.email})
      .then((response) => {
        const userData = response.data.data;
        setUser(userData);
        setUserId(userData[0]._id)
        getNotes(userData[0]._id);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getNotes = (userid) => {
    axios
      .post(`/api/notes/getNotesByUserId`, {
        userId: userid
      })
      .then((response) => {
        const notesArray = response.data.data;
        setNotes(notesArray.reverse());
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const onEditorStateChange = (editorState) => {
    // console.log(editorState)
      setEditorState(editorState);
  }

  const handleNotes =() =>{
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())).length <= 8){
      toast.error("Add some notes before save");
    }else{
      axios
        .post(`/api/notes/add`,{
          details: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          userId: userid
        })
        .then((response) => {
          setOpen(false);
          getNotes(userid);  
          setEditorState(EditorState.createEmpty())
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

  const handleUpdate = () => {
      axios
        .put(`/api/notes/update`, {
          details: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          userId: userid,
          _id: selectedData._id
        })
        .then((response) => {
          setOpen(false);
          getNotes(userid);
          setUpdate(false);
          setSelectedData({});
          setEditorState(EditorState.createEmpty())
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
  }
  
  const handleDelete = () => {
    let data = { _id: selectedData._id,userId:userid}
    axios
      .delete(`/api/notes/delete`, {data})
      .then((response) => {
        getNotes(userid);
        setDelete(false);
        setSelectedData({});
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  return (
    <Page title="MyDiary">
      < Toast / >
      <Container>
        {/* {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )} */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        
          <Typography variant="h4" gutterBottom>
            My Diary
          </Typography>
          <Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New note
          </Button>
          {/* <Iconify icon="akar-icons:arrow-back-thick" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} width={22} height={22} onClick={()=> {window.location.href = '/dashboard/match'}} /> */}
        </Stack>      
        {open === true ?
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
          <Editor 
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
          />   
          <Stack alignItems="center" direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={{xs:15,sm:8,md:3}}>  
          {/* <Typography variant="h4" gutterBottom mt={7}>
              Preview
            </Typography>
          <textarea style={{width:'100%'}}
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          /> */}
          {update === true ? <Button variant="outlined" onClick={handleUpdate}>Update</Button> 
          :<Button variant="outlined" onClick={handleNotes}>save</Button>
          }
          <Button variant="outlined" color="error" onClick={() => {setOpen(false)}}>Cancel</Button>
          </Stack>
        </Stack> :<div>
          <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }} >
            {notes.length >0 ? notes.map((item) => {
              return (
                  <Box sx={{mt:2}}>
                    <Stack direction="row" mb={1}>
                    <h4>{item.createdAt.split('T')[0]} {item.createdAt.split('T')[1].split('.')[0]} </h4>
                    <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} width={22} height={22} 
                        onClick = {
                          () => {
                            setOpen(true);
                            setEditorState(EditorState.createWithContent(
                              ContentState.createFromBlockArray(
                                convertFromHTML(item.details)
                              )
                            ));
                            setUpdate(true);
                            setSelectedData(item);
                          }
                        }
                        />
                        <Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} width={22} height={22} 
                        onClick = {
                          () => {
                            setDelete(true);
                            setSelectedData(item);
                          }
                        }
                        />
                    </Stack>
                    <Markdown style={{marginLeft:"2%"}}>{item.details}</Markdown>
                    {/* <p>{item.details}</p> */}
                    <Stack mb={4}/>
                  </Box>
              )
            }):<p>No Notes</p>}
            </Scrollbar>
          </div>
        }
        <Modal
          open={deleteData}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5" gutterBottom component="div">
              Do you need to delete the notes?
            </Typography>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
              <Button variant="outlined" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant = "outlined" color = "error" onClick = {() => {setDelete(false)}} >
                No
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
export default MyDiary;