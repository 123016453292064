import React from "react";
import { Toaster } from "react-hot-toast";

const Toast = (props) => {
  return (
    <div>
      <Toaster position={props.position} reverseOrder={false} duration={props.duration} />
    </div>
  );
};

export default Toast;