import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import Markdown from 'markdown-to-jsx';
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container, Modal, Box, Autocomplete,TextField,
  Card, CardContent, Accordion, AccordionSummary, AccordionDetails,
  Table,TableContainer, TableBody, TableCell, TableRow, Paper, TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css"
import {userDetails} from '../constants'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const MyLinks = () => {

  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});
  const [user, setUser] = React.useState([]);
  const [links, setLinks] = React.useState([{mylinks:[],globallinks:[],links:[]}]);
  const [expanded, setExpanded] = React.useState('Myself');
  const [userid, setUserId] = React.useState('');
  const [viewLevel, setViewLevel] = React.useState('');
  const [values, setValues] = React.useState({
    viewLevel:{label: 'Myself'},
    summary:'',
    link:''
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [page, setPage] = React.useState(0);

  const viewLevelList = [{
    label: "Global"
  }, {
    label: "Academy"
  }, {
    label: "Myself"
  }]

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
     axios
       .post(`/api/user/getParticularUserByEmail`, {
         "emailID":userDetails.email
       })
       .then((response) => {
         const userData = response.data.data;
        //  console.log(userData)
         setUser(userData);
         setUserId(userData[0]._id);
         getLinks()
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }

  const getLinks = () => {
    axios
      .post(`/api/myLinks/getAllUserLink`, {
        "emailID": userDetails.email
      })
      .then((response) => {
        const linksArray = response.data.data;  
        setLinks(linksArray);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleChange = (props) => (event) => {
    // console.log(event.target.value)
    setValues({
      ...values,
      [props]: event.target.value
    });
  };

  const handleChangeAccordion = (panel) => (event, newExpanded) => {    
    setExpanded(newExpanded ? panel : false);
  }

  const handleLink =() =>{
    
    if (values.summary === '' && values.link === ''){
      toast.error("Fill all the data");
    }
    else{
      axios
        .post(`/api/myLinks/add`, {
          summary: values.summary,
          userId: userid,
          link: values.link,
          viewLevel: values.viewLevel.label,
          academyId: user[0].academyId
        })
        .then((response) => {
          setOpen(false);
          getLinks();
          setValues({
            viewLevel: {
              label: 'Myself'
            },
            summary: '',
            link: ''
          })
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

  const updateLink = () => {

    axios
      .put(`/api/myLinks/update`, {
        _id:selectedData._id,
        summary: values.summary,
        link: values.link,
        viewLevel: values.viewLevel.label,
        academyId: user[0].academyId
      })
      .then((response) => {
        setOpen(false);
        setUpdate(false);
        getLinks();
        setValues({
          viewLevel: {
            label: 'Myself'
          },
          summary: '',
          link: ''
        });
        setSelectedData({});
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

   const deleteLink = (val) => {
     let data ={_id:val._id}
     axios
       .delete(`/api/myLinks/delete`, {data})
       .then((response) => {
         setOpen(false);
         setUpdate(false);
         getLinks();
         setValues({
           viewLevel: {
             label: 'Myself'
           },
           summary: '',
           link: ''
         });
         setSelectedData({});
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
   }

   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(+event.target.value);
     setPage(0);
   };

  const input = () =>{
      
      return (
      <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
        {user[0].role.includes('coach')?
        <Autocomplete
          id = "disable-clearable"
          disableClearable
          options={viewLevelList}
          defaultValue={values.viewLevel}
          getOptionLabel={(option) => {return option.label}}
          inputValue={values.viewLevel1}
          onInputChange={(event, newInputValue) => {
                setViewLevel(newInputValue);
          }}
           onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['viewLevel']: newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="View Level" sx={{mt:2}}/>}
        />:null}
        <TextField
          id = "filled-flexible"
          defaultValue = {values.summary}
          value={values.summary}
          varient="standard"
          label="Summary"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('summary')}
          />
          <TextField
          id = "filled-flexible"
          defaultValue = {values.link}
          value={values.link}
          varient="standard"
          label="Link"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('link')}
          />
      </Stack>
      )}

  const card = (links) => {
    
    return (
      <div>
      {/* <Accordion expanded={expanded === links[0]._id.viewLevel} onChange={handleChangeAccordion(links[0]._id.viewLevel)}>
          <AccordionSummary
            expandIcon={<Iconify icon = "flat-color-icons:expand" sx={{width:"100%"}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          > */}
          <Typography>{links[0]._id.viewLevel.toUpperCase() === 'MYSELF'?'My Links':links[0]._id.viewLevel}</Typography>
          {/* </AccordionSummary>
          <AccordionDetails> */}
          <Stack direction="row"  justifyContent="space-between" mb={2}>
             <Box sx={{ width: '100%' }}>         
                <Scrollbar>
                <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                <TableBody>
                  {links.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
                    return (
                      <TableRow tabIndex={-1}>
                        <TableCell align="left">
                          <Typography variant="body1" sx={{inlineSize:{xs:'195px',sm:'450px',md:'550px'},overflowWrap:"breakWord",wordBreak:"breakAll"}}>
                          <a href= {item._id.link} target="_blank"> {item._id.summary} </a>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {item._id.userId === userid? 
                          <Stack direction='row'>
                            <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                              onClick={()=>{
                                setOpen(true);
                                setValues({
                                  viewLevel:{label:item._id.viewLevel},
                                  summary:item._id.summary,
                                  link: item._id.link
                                })
                                setUpdate(true);
                                setSelectedData(item)
                              } }                            
                            /> 
                            <Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                              onClick={()=>{
                                setValues({
                                  viewLevel:{label:item._id.viewLevel},
                                  summary:item._id.summary,
                                  link: item._id.link
                                })
                                setSelectedData(item)
                                deleteLink(item)
                              }}
                            />
                          </Stack>
                          :null}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3, 5, 10, 25]}
                colSpan={3}
                count={links.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              </Scrollbar>
             </Box>
          </Stack>
          {/* </AccordionDetails>
          </Accordion> */}
        </div>
    )}

  return (
    <Page title="My Links">
      < Toast / >
      <Container>
        {/* {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )} */}
        {open === true || update === true ? null :
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>        
            <Typography variant="h4" gutterBottom>
              My Links
            </Typography>    
            <Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
              Link
            </Button>       
          </Stack>      
        }
        {open === true ?
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {update === true ?<span>Update Link</span>: <span>Add Link</span>}
          </Typography>
          {input()}
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
            {update === true ?
            <Button variant="outlined" onClick={updateLink}>update</Button>
            :<Button variant="outlined" onClick={handleLink}>save</Button>}
            <Button variant="outlined" color="error" onClick={() => {setOpen(false); setUpdate(false)
                setValues({
                  viewLevel:{label:'Myself'},
                  summary:'',link:''
                })}}>
            Cancel</Button>
            {/* {update === true ? <Button variant="outlined"  color="error" onClick={deleteLink}>delete</Button> :null} */}
          </Stack>
          </Stack> :    
          links[0].mylinks.length !== 0 || links[0].globallinks.length !== 0 || links[0].links.length !== 0 ?
            <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }}>     
              {links[0].mylinks.length > 0 ? card(links[0].mylinks) :null}
              {links[0].globallinks.length > 0 ? card(links[0].globallinks) :null}
              {links[0].links.length > 0 ? card(links[0].links) :null}
            </Scrollbar>
          :   
          <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'7%'},marginLeft:{sm:'20%',md:'29%'}}}>
            <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No Data</Typography> 
            <img src="/static/illustrations/empty_bin.png" alt="No Details" style={{width:'300px'}} />
          </Box>
        } 
       
      </Container>
    </Page>
  );
}
export default MyLinks;