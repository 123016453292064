import * as React from 'react';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Typography, Container, Autocomplete,Grid,TextField,Stack,
  Card, CardHeader,Box,Modal,
Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
import Scrollbar from "react-scrollbar";
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import Iconify from '../components/Iconify';
import {  AppWidgetSummary,MatchHeatMap} from '../sections/@dashboard/app';
import toast from "react-hot-toast";
import Toast from "../components/Toast";

export default function LiveScore() {  
  const [timeNow, setTimeNow] = useState({time:new Date()})

  useEffect(() => {
    getValues();
    const interval = setInterval(() => getTime(timeNow.time), 1000);
    return () => clearInterval(interval);
  }, [timeNow.time]);
  useEffect(() => {
    const timer = setInterval(() => {
      myFunction();
    }, 24 * 60 * 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  },[]);

   const [values, setValues] = React.useState({
     name:"",
     type: "10m Air Pistol",
    score: "",
    sum:0,
    sumDecimal:0,
    average8:0,
    average9:0,
    average10:0,
    noOfShot:'10',
    completedTime:""
  });
  const type = ["10m Air Pistol","10m Air Rifle"]
  const noOfShots = [5,10,20,30,40,60]

  const [score,setScore] = React.useState({score:[],sum:0,shot:0,sumDecimal:0});
  const [breakValue,setBreak] = React.useState({break:[]})
  const [rowsPerPage, setRowsPerPage] = useState(60);
  const [page, setPage] = useState(0);
  const [match, setMatch] = useState(false);
  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [editScore, setEditScore] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [newScore, setNewScore] = React.useState(0);

  const getTime = () => {
    const time = Date.now() - Date.parse(timeNow.time) ;

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (props) => (event) => {
    
    if (props === 'score' && (parseFloat(event.target.value)>10.9)){
      toast.error("Score cannot be greater than 10.9");
    }else{
      let value = event.target.value;
      if(props === 'score'){value = parseFloat(event.target.value)}
      setValues({
        ...values,
        [props]: value
      });
    }
  };

  const series = [{
    name: "shots",
    data: score.score,
  }]
  const options = {
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'stepline'
    },
     yaxis: [{
       min:7,max:10.9,
       labels: {
         formatter: function (val) {
           return val.toFixed(1);
         },
       }
     }]
  }

  const getValues = () =>{
    // console.log("getvalue ", JSON.parse(localStorage.getItem("liveScore")))
    if (JSON.parse(localStorage.getItem("liveScore")) != null) {
      let latestValue = JSON.parse(localStorage.getItem("liveScore"))
      
      setScore({...score,score:latestValue.score,sum:latestValue.sum,shot:latestValue.noOfShot.toString()})
      setBreak({...breakValue,break:latestValue.break})
      setValues({
        ...values,
        ["type"]: latestValue.matchType,
        ["noOfShot"]: latestValue.noOfShot.toString(),
        "sum": latestValue.sum,
        "sumDecimal": latestValue.sumDecimal,
        "average8": latestValue.average8,
        "average9": latestValue.average9,
        "average10": latestValue.average10,
        "score":"",
        "name":latestValue.name,
        "completedTime":latestValue.completedTime
      });
    }
  }

  const save =()=>{
    let scoreVal = parseFloat(values.score).toFixed(1)    
    let storeValue = JSON.parse(localStorage.getItem("liveScore")) != null ? JSON.parse(localStorage.getItem("liveScore")):{score:[],sum:0,sumDecimal:0,break:[]}
    
    let sumDecimal = Math.round((storeValue.sumDecimal + parseFloat(scoreVal)) * 10) / 10;
    let sum = Math.round(storeValue.sum + parseInt(scoreVal));
    
    setValues({
      ...values,
      "sumDecimal": sumDecimal,
      "sum": sum
    });
    let array = storeValue.score
    array.push(parseFloat(scoreVal))
    setScore({...score,score:array,sum:sum,sumDecimal:sumDecimal})
    let scoreLength = parseInt(values.noOfShot) - array.length
    let avg8 = Math.floor((scoreLength * 8) + sum)
    let avg9 = Math.floor((scoreLength * 9) + sum)
    let avg10 = Math.floor((scoreLength * 10) + sum)
    localStorage.setItem("liveScore", JSON.stringify({name:values.name,score:array,sum:sum,sumDecimal:sumDecimal,average8:avg8,average9:avg9,average10:avg10,matchType:values.type,noOfShot:parseInt(values.noOfShot),break:storeValue.break,completedTime:`${hours<10?'0'+hours:hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`}));
    
    myFunction()
    getValues()
    if (parseInt(values.noOfShot) === array.length) {
      setMatch(false)
    }
    // console.log(localStorage.removeItem("liveScore"))
  }
  const onkeyScore = () => (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      save();
    }
  }
  function myFunction() {
    var hours = 24; // to clear the localStorage after 24 hour
    var now = new Date().getTime();
    var setupTime = typeof window !== 'undefined' ? localStorage.getItem('setupTime') : null
    if (setupTime == null) {
      localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.removeItem("liveScore")
        localStorage.setItem('setupTime', now);
      }
    }
  }
  const clearStorage = () =>{
    
    localStorage.removeItem("liveScore");
    setScore({...score,score:[],sum:0,shot:0})
    setValues({...values,name:"",type: "10m Air Pistol", score: 0,sum:0,sumDecimal:0,average8:0,average9:0,average10:0,noOfShot:'10'})
  }
  
  const breakPoint = () => {
    let storeValue = JSON.parse(localStorage.getItem("liveScore")) != null ? JSON.parse(localStorage.getItem("liveScore")):{break:[]}
    let breakPoint = storeValue.break
    
    breakPoint.push(storeValue.score.length)
    storeValue.break = breakPoint
    setBreak({...breakValue,break:breakPoint})
    localStorage.setItem("liveScore", JSON.stringify(storeValue))
  }

  const editScoreFun = (index) => {
    let storeValue = JSON.parse(localStorage.getItem("liveScore")) != null ? JSON.parse(localStorage.getItem("liveScore")):{break:[]}
    let scoreArray = storeValue.score
    if (index !== -1) {
      scoreArray[index] = newScore;
    }    
    storeValue.score = scoreArray
    setScore({...score,score:scoreArray})
    let sumDecimal = 0
    let sum = 0
    scoreArray.map((item)=>{
      
      sumDecimal = Math.round((sumDecimal + item) * 10) / 10;
      sum = Math.round(sum + parseInt(item));
    })
    
    setValues({
      ...values,
      "sumDecimal": sumDecimal,
      "sum": sum
    });
    setScore({...score,score:scoreArray,sum:sum,sumDecimal:sumDecimal})
    let avg8 = Math.floor(sum)
    storeValue.sum = sum
    storeValue.sumDecimal = sumDecimal
    storeValue.average8 = avg8
    storeValue.average9 = avg8
    storeValue.average10 = avg8
    localStorage.setItem("liveScore", JSON.stringify(storeValue))
    setEditScore(false);
    setSelectedItem({});
    setSelectedItemIndex(0)
    getValues()
  }

  const handleChangeEdit = (props) => (event) => {
    if (props === 'new score' && (parseFloat(event.target.value) > 10.9)) {
      toast.error("Score cannot be greater than 10.9");
    } else {
      setNewScore(parseFloat(event.target.value))
      setValues({...values,score:parseFloat(event.target.value)})
    }
  };


  return (
    <Page title="Live Score">
      <Toast />
      <Container>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={3} md={3} sm={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Logo/>
            </Stack>
          </Grid>
          <Grid item xs={9} md={9} sm={9}>
         {/* <Box component="img" src="/static/intellishoot.png" alt="" sx={{ width: {md:'40%',sm:"70%",xs:"80%"}, height: {md:'25%',sm:'30%',xs:"70%"},marginLeft:'auto',marginRight:'auto'}} /> */}
            <Stack direction="column" alignItem="center" justifyContent="center">
            <Typography variant="h3" gutterBottom style={{textAlign:"center"}}><div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div></Typography>
            <Typography variant="subtitle1" gutterBottom style={{textAlign:"center"}}>Pro-level Shooter Performance Management & Analytics</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction={{md:"row",sx:'row',xs:"column"}} alignItems="center" justifyContent="space-between" mt={2}>          
          <Typography variant="h4" gutterBottom>Live Score</Typography> 
          
        {parseInt(values.noOfShot) === score.score.length?
          <Typography variant="subtitle1" gutterBottom>"You Match is complete in {values.completedTime}. To view more analytics, register to <a href="https://intellishoot.com/login" target="_blank">intelliShoot"</a></Typography> 
        :    
        match===true?
        <div className="timer" role="timer">
          {/* <p id="day">{days < 10 ? "0" + days : days}</p> */}
           <Typography variant="h4" gutterBottom>{hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</Typography>
        </div>
        : <Button variant="contained" onClick={() => {setTimeNow({...timeNow,time:new Date()});clearStorage();setMatch(true); }} >Start Match</Button> 
        }
        </Stack> 
        <Grid container spacing={2} mt={0} mb={4}>
          <Grid item xs={6} sm={3} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:'20vh'}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"55%",md:"25%"}}} score="" title={`${score.score.length}/${values.noOfShot} shots`} total= {`${values.sum}` + `(${values.sumDecimal})`} icon={'ant-design:android-filled'} />
          </Grid>  
          <Grid item xs={6} sm={1.5} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"75%",md:"25%"}}} score="" title="All 8s" total= {values.average8} icon={'ant-design:windows-filled'} />
          </Grid> 
          <Grid item xs={6} sm={1.5} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"75%",md:"25%"}}} score="" title="All 9s" total= {values.average9} icon={'ant-design:bug-filled'} />
          </Grid> 
          <Grid item xs={6} sm={2} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"55%",md:"25%"}}} score="" title="Max" total= {values.average10} icon={'ant-design:android-filled'} />
          </Grid> 
        <Grid item xs={12} sm={5} md={4}> 
         {match===true?<div>
        {score.score.length <1?<div>
          <TextField
          id = "outlined-number"
          defaultValue = {values.name}
          value={values.name}
          label="Name"
          type="text"
          sx={{mt:2,width:'100%'}}
          onChange={handleChange('name')}
          />
          <Autocomplete
          id = "disable-clearable"
          disableClearable
          disabled={localStorage.getItem("liveScore") != null?true:false}
          options={type}
          defaultValue={values.type}
          getOptionLabel={(option) => {return option}}
          inputValue={values.type}
          onInputChange={(event, newInputValue) => {
            if (event !== null) {
                setValues({
                ...values,
                ['type']: newInputValue
              });
            }
          }}
          renderInput={(params) => <TextField {...params} label="Type" sx={{mt:2}}/>}
        />   
        <Autocomplete
          id = "disable-clearable"
          disableClearable
          disabled={localStorage.getItem("liveScore") != null?true:false}
          options={noOfShots}
          defaultValue={values.noOfShot}
          getOptionLabel={(option) => {return option}}
          inputValue={values.noOfShot}
          onInputChange={(event, newInputValue) => {
            if (event !== null) {
                setValues({
                ...values,
                ['noOfShot']: newInputValue
              });
            }
          }}
          renderInput={(params) => <TextField {...params} label="No Of Shots" sx={{mt:2}}/>}
        /> </div>:null}
       
       <TextField
          id = "outlined-number"
          defaultValue = {values.score}
          value={values.score}
          label="Score"
          type="number"
          inputProps={{ min: 0, max: 10.9, maxLength: 5}}
          step= "2"
          sx={{mt:2,width:'100%'}}
          onChange={handleChange('score')}          
          onKeyDown={onkeyScore()}
        /></div> :null}

          {parseInt(values.noOfShot) === score.score.length ?<div><p>You Match is complete in {values.completedTime}. Your match summary is below</p> 
          <p>click on New Match to start another match</p></div> :
          match===true ?
          <Stack direction={{md:"row",sm:"row",xs:'column'}} justifyContent="" spacing={2} mt={2}>
          <Button variant="outlined" onClick={save} mt={2}>
            Submit
          </Button>
          <Button variant="outlined" color="error" mt={2} onClick={()=>{setValues({...values,"score":0})}}>
            Clear
          </Button>
          <Button variant="outlined" color="secondary" onClick={()=>{breakPoint()}} >
            Break
          </Button>
          </Stack>:null}
          <Stack direction={{md:"row",sm:"row",xs:'column'}} justifyContent="" spacing={2} mt={2}>
          <Button variant="outlined" color="secondary" 
          onClick={()=>{setMatch(false);clearStorage();}}>
            New Match
          </Button>
          </Stack>
          </Grid>
        </Grid>
        {breakValue.break.length > 0?<Typography variant="h6" mb={1}>Breaks taken after {breakValue.break.map((item=>{return `${item},`}))} shots</Typography>:null}
        
        <Card>
          <CardHeader title="Shot Details" />
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ReactApexChart options={options} series={series} type="line" height={350} />
          </Box>
        </Card>
        {/* <Grid item xs={12} md={12} lg={12}>
          {console.log(score.score.length)}
            <MatchHeatMap Details={score.score} seriesDataFormated={(score.score.length ===10 || score.score.length ===5)?{name:'series1',data:score.score}:
            score.score.length ===20?[{name:'series1',data:},{name:'series2',data:[9,9,5,8,9]}]}/>
        </Grid> */}
        
        {/* <Scrollbar style={{ height: "70vh", width: "98.5%", paddingRight: "1rem",marginTop:'2%' }}> */}
          <TableContainer component={Paper} style={{marginTop:"2%"}}>
          <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
            <TableCell align="left">SNo</TableCell>
            <TableCell align="left">Score</TableCell>
            <TableCell align="left">Score Decimal</TableCell>
            <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {score.score.length >0 && score.score.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
              return (
                <TableRow >
                  <TableCell align="left">{index+1+(page * rowsPerPage)}</TableCell>
                  <TableCell align="left">{Math.floor(item)}</TableCell>
                  <TableCell align="left">{item}</TableCell>
                  <TableCell align="left">{<Iconify icon = "material-symbols:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} onClick={()=>{setNewScore(item);setEditScore(true);setSelectedItem(item);setSelectedItemIndex(index)}} />}</TableCell>                  
                  </TableRow>
              ) 
            })}
          </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5,10,20,30,40,60]}
          colSpan={3}
          count={score.score.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* </Scrollbar> */}

        <Modal
            open={editScore}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {md:'46%',sm:'90%',xs:'90%'},
                bgcolor: 'background.paper',
                p: 4,
              }}
              noValidate
              autoComplete="off"
            >
            <Typography variant="h5" gutterBottom component="div">
              Please enter the score you need to update
            </Typography>
            <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>
            <TextField
              id = "outlined-number"
              defaultValue = {newScore}
              value={newScore}
              label="New Score"
              type="number"
              inputProps={{ min: 0, max: 10.9, maxLength: 5}}
              step= "2"
              sx={{mt:2,width:'100%'}}
              onChange={handleChangeEdit("new score")}
            />
            </Stack>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
            <Button variant="outlined" onClick = {() => {editScoreFun(selectedItemIndex)}}>
              submit
            </Button>              
            <Button variant = "outlined" color = "error" onClick = {() => {setEditScore(false);setSelectedItem({});setSelectedItemIndex(0)}} >
              cancel
            </Button>
            </Stack>
          </Box>
        </Modal>        
      </Container>
    </Page>
  );
}
