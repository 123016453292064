export const countryPincode =[
    {
      "name": "Afghanistan",
      "iso2": "AF",
      "code": "93"
    },
    {
      "name": "Albania",
      "iso2": "AL",
      "code": "355"
    },
    {
      "name": "Algeria",
      "iso2": "DZ",
      "code": "213"
    },
    {
      "name": "American Samoa",
      "iso2": "AS",
      "code": "1684"
    },
    {
      "name": "Andorra",
      "iso2": "AD",
      "code": "376"
    },
    {
      "name": "Angola",
      "iso2": "AO",
      "code": "244"
    },
    {
      "name": "Anguilla",
      "iso2": "AI",
      "code": "1264"
    },
    {
      "name": "Antarctica",
      "iso2": "AQ",
      "code": "672"
    },
    {
      "name": "Antigua And Barbuda",
      "iso2": "AG",
      "code": "1268"
    },
    {
      "name": "Argentina",
      "iso2": "AR",
      "code": "54"
    },
    {
      "name": "Armenia",
      "iso2": "AM",
      "code": "374"
    },
    {
      "name": "Aruba",
      "iso2": "AW",
      "code": "297"
    },
    {
      "name": "Ascension Island",
      "iso2": "AC",
      "code": "247"
    },
    {
      "name": "Australia",
      "iso2": "AU",
      "code": "61"
    },
    {
      "name": "Austria",
      "iso2": "AT",
      "code": "43"
    },
    {
      "name": "Azerbaijan",
      "iso2": "AZ",
      "code": "994"
    },
    {
      "name": "Bahamas",
      "iso2": "BS",
      "code": "1242"
    },
    {
      "name": "Bahrain",
      "iso2": "BH",
      "code": "973"
    },
    {
      "name": "Bangladesh",
      "iso2": "BD",
      "code": "880"
    },
    {
      "name": "Barbados",
      "iso2": "BB",
      "code": "1246"
    },
    {
      "name": "Belarus",
      "iso2": "BY",
      "code": "375"
    },
    {
      "name": "Belgium",
      "iso2": "BE",
      "code": "32"
    },
    {
      "name": "Belize",
      "iso2": "BZ",
      "code": "501"
    },
    {
      "name": "Benin",
      "iso2": "BJ",
      "code": "229"
    },
    {
      "name": "Bermuda",
      "iso2": "BM",
      "code": "1441"
    },
    {
      "name": "Bhutan",
      "iso2": "BT",
      "code": "975"
    },
    {
      "name": "Bolivia, Plurinational State Of",
      "iso2": "BO",
      "code": "591"
    },
    {
      "name": "Bonaire, Saint Eustatius And Saba",
      "iso2": "BQ",
      "code": "599"
    },
    {
      "name": "Bosnia & Herzegovina",
      "iso2": "BA",
      "code": "387"
    },
    {
      "name": "Botswana",
      "iso2": "BW",
      "code": "267"
    },
    {
      "name": "Bouvet Island",
      "iso2": "BV",
      "code": "47"
    },
    {
      "name": "Brazil",
      "iso2": "BR",
      "code": "55"
    },
    {
      "name": "British Indian Ocean Territory",
      "iso2": "IO",
      "code": "246"
    },
    {
      "name": "Brunei Darussalam",
      "iso2": "BN",
      "code": "673"
    },
    {
      "name": "Bulgaria",
      "iso2": "BG",
      "code": "359"
    },
    {
      "name": "Burkina Faso",
      "iso2": "BF",
      "code": "226"
    },
    {
      "name": "Burundi",
      "iso2": "BI",
      "code": "257"
    },
    {
      "name": "Cambodia",
      "iso2": "KH",
      "code": "855"
    },
    {
      "name": "Cameroon",
      "iso2": "CM",
      "code": "237"
    },
    {
      "name": "Canada",
      "iso2": "CA",
      "code": "1"
    },
    {
      "name": "Canary Islands",
      "iso2": "IC",
      "code": "0034"
    },
    {
      "name": "Cape Verde",
      "iso2": "CV",
      "code": "238"
    },
    {
      "name": "Cayman Islands",
      "iso2": "KY",
      "code": "1345"
    },
    {
      "name": "Central African Republic",
      "iso2": "CF",
      "code": "236"
    },
    {
      "name": "Ceuta, Mulilla",
      "iso2": "EA",
      "code": "956"
    },
    {
      "name": "Chad",
      "iso2": "TD",
      "code": "235"
    },
    {
      "name": "Chile",
      "iso2": "CL",
      "code": "56"
    },
    {
      "name": "China",
      "iso2": "CN",
      "code": "86"
    },
    {
      "name": "Christmas Island",
      "iso2": "CX",
      "code": "61"
    },
    {
      "name": "Clipperton Island",
      "iso2": "CP",
      "code": "55"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "iso2": "CC",
      "code": "61"
    },
    {
      "name": "Colombia",
      "iso2": "CO",
      "code": "57"
    },
    {
      "name": "Comoros",
      "iso2": "KM",
      "code": "269"
    },
    {
      "name": "Cook Islands",
      "iso2": "CK",
      "code": "682"
    },
    {
      "name": "Costa Rica",
      "iso2": "CR",
      "code": "506"
    },
    {
      "name": "Cote d'Ivoire",
      "iso2": "CI",
      "code": "225"
    },
    {
      "name": "Croatia",
      "iso2": "HR",
      "code": "385"
    },
    {
      "name": "Cuba",
      "iso2": "CU",
      "code": "53"
    },
    {
      "name": "Curacao",
      "iso2": "CW",
      "code": "599"
    },
    {
      "name": "Cyprus",
      "iso2": "CY",
      "code": "357"
    },
    {
      "name": "Czech Republic",
      "iso2": "CZ",
      "code": "420"
    },
    {
      "name": "Democratic Republic Of Congo",
      "iso2": "CD",
      "code": "243"
    },
    {
      "name": "Denmark",
      "iso2": "DK",
      "code": "45"
    },
    {
      "name": "Diego Garcia",
      "iso2": "DG",
      "code": "246"
    },
    {
      "name": "Djibouti",
      "iso2": "DJ",
      "code": "253"
    },
    {
      "name": "Dominica",
      "iso2": "DM",
      "code": "1767"
    },
    {
      "name": "Dominican Republic",
      "iso2": "DO",
      "code": "1809"
    },
    {
      "name": "East Timor",
      "iso2": "TL",
      "code": "670"
    },
    {
      "name": "Ecuador",
      "iso2": "EC",
      "code": "593"
    },
    {
      "name": "Egypt",
      "iso2": "EG",
      "code": "20"
    },
    {
      "name": "El Salvador",
      "iso2": "SV",
      "code": "503"
    },
    {
      "name": "Equatorial Guinea",
      "iso2": "GQ",
      "code": "240"
    },
    {
      "name": "Eritrea",
      "iso2": "ER",
      "code": "291"
    },
    {
      "name": "Estonia",
      "iso2": "EE",
      "code": "372"
    },
    {
      "name": "Ethiopia",
      "iso2": "ET",
      "code": "251"
    },
    {
      "name": "European Union",
      "iso2": "EU",
      "code": "388"
    },
    {
      "name": "Falkland Islands",
      "iso2": "FK",
      "code": "500"
    },
    {
      "name": "Faroe Islands",
      "iso2": "FO",
      "code": "298"
    },
    {
      "name": "Fiji",
      "iso2": "FJ",
      "code": "679"
    },
    {
      "name": "Finland",
      "iso2": "FI",
      "code": "358"
    },
    {
      "name": "France",
      "iso2": "FR",
      "code": "33"
    },
    {
      "name": "France, Metropolitan",
      "iso2": "FX",
      "code": "241"
    },
    {
      "name": "French Guiana",
      "iso2": "GF",
      "code": "44"
    },
    {
      "name": "French Polynesia",
      "iso2": "PF",
      "code": "689"
    },
    {
      "name": "French Southern Territories",
      "iso2": "TF",
      "code": ""
    },
    {
      "name": "Gabon",
      "iso2": "GA",
      "code": "44"
    },
    {
      "name": "Gambia",
      "iso2": "GM",
      "code": "220"
    },
    {
      "name": "Georgia",
      "iso2": "GE",
      "code": "594"
    },
    {
      "name": "Germany",
      "iso2": "DE",
      "code": "49"
    },
    {
      "name": "Ghana",
      "iso2": "GH",
      "code": "233"
    },
    {
      "name": "Gibraltar",
      "iso2": "GI",
      "code": "350"
    },
    {
      "name": "Greece",
      "iso2": "GR",
      "code": "30"
    },
    {
      "name": "Greenland",
      "iso2": "GL",
      "code": "299"
    },
    {
      "name": "Grenada",
      "iso2": "GD",
      "code": "995"
    },
    {
      "name": "Guadeloupe",
      "iso2": "GP",
      "code": "590"
    },
    {
      "name": "Guam",
      "iso2": "GU",
      "code": "1671"
    },
    {
      "name": "Guatemala",
      "iso2": "GT",
      "code": "502"
    },
    {
      "name": "Guernsey",
      "iso2": "GG",
      "code": "441481"
    },
    {
      "name": "Guinea",
      "iso2": "GN",
      "code": "224"
    },
    {
      "name": "Guinea-bissau",
      "iso2": "GW",
      "code": "245"
    },
    {
      "name": "Guyana",
      "iso2": "GY",
      "code": "592"
    },
    {
      "name": "Haiti",
      "iso2": "HT",
      "code": "509"
    },
    {
      "name": "Heard Island And McDonald Islands",
      "iso2": "HM",
      "code": "00672"
    },
    {
      "name": "Honduras",
      "iso2": "HN",
      "code": "504"
    },
    {
      "name": "Hong Kong",
      "iso2": "HK",
      "code": "852"
    },
    {
      "name": "Hungary",
      "iso2": "HU",
      "code": "36"
    },
    {
      "name": "Iceland",
      "iso2": "IS",
      "code": "354"
    },
    {
      "name": "India",
      "iso2": "IN",
      "code": "91"
    },
    {
      "name": "Indonesia",
      "iso2": "ID",
      "code": "62"
    },
    {
      "name": "Iran, Islamic Republic Of",
      "iso2": "IR",
      "code": "98"
    },
    {
      "name": "Iraq",
      "iso2": "IQ",
      "code": "964"
    },
    {
      "name": "Ireland",
      "iso2": "IE",
      "code": "353"
    },
    {
      "name": "Isle Of Man",
      "iso2": "IM",
      "code": "44"
    },
    {
      "name": "Israel",
      "iso2": "IL",
      "code": "972"
    },
    {
      "name": "Italy",
      "iso2": "IT",
      "code": "39"
    },
    {
      "name": "Jamaica",
      "iso2": "JM",
      "code": "1876"
    },
    {
      "name": "Japan",
      "iso2": "JP",
      "code": "81"
    },
    {
      "name": "Jersey",
      "iso2": "JE",
      "code": "44"
    },
    {
      "name": "Jordan",
      "iso2": "JO",
      "code": "962"
    },
    {
      "name": "Kazakhstan",
      "iso2": "KZ",
      "code": "7"
    },
    {
      "name": "Kenya",
      "iso2": "KE",
      "code": "254"
    },
    {
      "name": "Kiribati",
      "iso2": "KI",
      "code": "686"
    },
    {
      "name": "Korea, Democratic People's Republic Of",
      "iso2": "KP",
      "code": "850"
    },
    {
      "name": "Korea, Republic Of",
      "iso2": "KR",
      "code": "82"
    },
    {
      "name": "Kuwait",
      "iso2": "KW",
      "code": "965"
    },
    {
      "name": "Kyrgyzstan",
      "iso2": "KG",
      "code": "996"
    },
    {
      "name": "Lao People's Democratic Republic",
      "iso2": "LA",
      "code": "856"
    },
    {
      "name": "Latvia",
      "iso2": "LV",
      "code": "371"
    },
    {
      "name": "Lebanon",
      "iso2": "LB",
      "code": "961"
    },
    {
      "name": "Lesotho",
      "iso2": "LS",
      "code": "266"
    },
    {
      "name": "Liberia",
      "iso2": "LR",
      "code": "231"
    },
    {
      "name": "Libya",
      "iso2": "LY",
      "code": "218"
    },
    {
      "name": "Liechtenstein",
      "iso2": "LI",
      "code": "423"
    },
    {
      "name": "Lithuania",
      "iso2": "LT",
      "code": "370"
    },
    {
      "name": "Luxembourg",
      "iso2": "LU",
      "code": "352"
    },
    {
      "name": "Macao",
      "iso2": "MO",
      "code": "853"
    },
    {
      "name": "Macedonia, The Former Yugoslav Republic Of",
      "iso2": "MK",
      "code": "389"
    },
    {
      "name": "Madagascar",
      "iso2": "MG",
      "code": "261"
    },
    {
      "name": "Malawi",
      "iso2": "MW",
      "code": "265"
    },
    {
      "name": "Malaysia",
      "iso2": "MY",
      "code": "60"
    },
    {
      "name": "Maldives",
      "iso2": "MV",
      "code": "960"
    },
    {
      "name": "Mali",
      "iso2": "ML",
      "code": "223"
    },
    {
      "name": "Malta",
      "iso2": "MT",
      "code": "356"
    },
    {
      "name": "Marshall Islands",
      "iso2": "MH",
      "code": "692"
    },
    {
      "name": "Martinique",
      "iso2": "MQ",
      "code": "596"
    },
    {
      "name": "Mauritania",
      "iso2": "MR",
      "code": "222"
    },
    {
      "name": "Mauritius",
      "iso2": "MU",
      "code": "230"
    },
    {
      "name": "Mayotte",
      "iso2": "YT",
      "code": "262"
    },
    {
      "name": "Mexico",
      "iso2": "MX",
      "code": "52"
    },
    {
      "name": "Micronesia, Federated States Of",
      "iso2": "FM",
      "code": "691"
    },
    {
      "name": "Moldova",
      "iso2": "MD",
      "code": "373"
    },
    {
      "name": "Monaco",
      "iso2": "MC",
      "code": "377"
    },
    {
      "name": "Mongolia",
      "iso2": "MN",
      "code": "976"
    },
    {
      "name": "Montenegro",
      "iso2": "ME",
      "code": "382"
    },
    {
      "name": "Montserrat",
      "iso2": "MS",
      "code": "1664"
    },
    {
      "name": "Morocco",
      "iso2": "MA",
      "code": "212"
    },
    {
      "name": "Mozambique",
      "iso2": "MZ",
      "code": "258"
    },
    {
      "name": "Myanmar",
      "iso2": "MM",
      "code": "95"
    },
    {
      "name": "Namibia",
      "iso2": "NA",
      "code": "264"
    },
    {
      "name": "Nauru",
      "iso2": "NR",
      "code": "674"
    },
    {
      "name": "Nepal",
      "iso2": "NP",
      "code": "977"
    },
    {
      "name": "Netherlands",
      "iso2": "NL",
      "code": "31"
    },
    {
      "name": "New Caledonia",
      "iso2": "NC",
      "code": "687"
    },
    {
      "name": "New Zealand",
      "iso2": "NZ",
      "code": "64"
    },
    {
      "name": "Nicaragua",
      "iso2": "NI",
      "code": "505"
    },
    {
      "name": "Niger",
      "iso2": "NE",
      "code": "227"
    },
    {
      "name": "Nigeria",
      "iso2": "NG",
      "code": "234"
    },
    {
      "name": "Niue",
      "iso2": "NU",
      "code": "683"
    },
    {
      "name": "Norfolk Island",
      "iso2": "NF",
      "code": "672"
    },
    {
      "name": "Northern Mariana Islands",
      "iso2": "MP",
      "code": "1670"
    },
    {
      "name": "Norway",
      "iso2": "NO",
      "code": "47"
    },
    {
      "name": "Oman",
      "iso2": "OM",
      "code": "968"
    },
    {
      "name": "Pakistan",
      "iso2": "PK",
      "code": "92"
    },
    {
      "name": "Palau",
      "iso2": "PW",
      "code": "680"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "iso2": "PS",
      "code": "970"
    },
    {
      "name": "Panama",
      "iso2": "PA",
      "code": "507"
    },
    {
      "name": "Papua New Guinea",
      "iso2": "PG",
      "code": "675"
    },
    {
      "name": "Paraguay",
      "iso2": "PY",
      "code": "595"
    },
    {
      "name": "Peru",
      "iso2": "PE",
      "code": "51"
    },
    {
      "name": "Philippines",
      "iso2": "PH",
      "code": "63"
    },
    {
      "name": "Pitcairn",
      "iso2": "PN",
      "code": ""
    },
    {
      "name": "Poland",
      "iso2": "PL",
      "code": "48"
    },
    {
      "name": "Portugal",
      "iso2": "PT",
      "code": "351"
    },
    {
      "name": "Puerto Rico",
      "iso2": "PR",
      "code": "1787"
    },
    {
      "name": "Qatar",
      "iso2": "QA",
      "code": "974"
    },
    {
      "name": "Republic Of Congo",
      "iso2": "CG",
      "code": "242"
    },
    {
      "name": "Reunion",
      "iso2": "RE",
      "code": "262"
    },
    {
      "name": "Romania",
      "iso2": "RO",
      "code": "40"
    },
    {
      "name": "Russian Federation",
      "iso2": "RU",
      "code": "7"
    },
    {
      "name": "Rwanda",
      "iso2": "RW",
      "code": "250"
    },
    {
      "name": "Saint Barthélemy",
      "iso2": "BL",
      "code": "590"
    },
    {
      "name": "Saint Helena, Ascension And Tristan Da Cunha",
      "iso2": "SH",
      "code": "290"
    },
    {
      "name": "Saint Kitts And Nevis",
      "iso2": "KN",
      "code": "1869"
    },
    {
      "name": "Saint Lucia",
      "iso2": "LC",
      "code": "1758"
    },
    {
      "name": "Saint Martin",
      "iso2": "MF",
      "code": "590"
    },
    {
      "name": "Saint Pierre And Miquelon",
      "iso2": "PM",
      "code": "508"
    },
    {
      "name": "Saint Vincent And The Grenadines",
      "iso2": "VC",
      "code": "1784"
    },
    {
      "name": "Samoa",
      "iso2": "WS",
      "code": "685"
    },
    {
      "name": "San Marino",
      "iso2": "SM",
      "code": "378"
    },
    {
      "name": "Sao Tome And Principe",
      "iso2": "ST",
      "code": "239"
    },
    {
      "name": "Saudi Arabia",
      "iso2": "SA",
      "code": "966"
    },
    {
      "name": "Senegal",
      "iso2": "SN",
      "code": "221"
    },
    {
      "name": "Serbia",
      "iso2": "RS",
      "code": "381"
    },
    {
      "name": "Seychelles",
      "iso2": "SC",
      "code": "248"
    },
    {
      "name": "Sierra Leone",
      "iso2": "SL",
      "code": "232"
    },
    {
      "name": "Singapore",
      "iso2": "SG",
      "code": "65"
    },
    {
      "name": "Sint Maarten",
      "iso2": "SX",
      "code": "1721"
    },
    {
      "name": "Slovakia",
      "iso2": "SK",
      "code": "421"
    },
    {
      "name": "Slovenia",
      "iso2": "SI",
      "code": "386"
    },
    {
      "name": "Solomon Islands",
      "iso2": "SB",
      "code": "677"
    },
    {
      "name": "Somalia",
      "iso2": "SO",
      "code": "252"
    },
    {
      "name": "South Africa",
      "iso2": "ZA",
      "code": "27"
    },
    {
      "name": "South Georgia And The South Sandwich Islands",
      "iso2": "GS",
      "code": "500"
    },
    {
      "name": "Spain",
      "iso2": "ES",
      "code": "34"
    },
    {
      "name": "Sri Lanka",
      "iso2": "LK",
      "code": "94"
    },
    {
      "name": "Sudan",
      "iso2": "SD",
      "code": "249"
    },
    {
      "name": "Suriname",
      "iso2": "SR",
      "code": "597"
    },
    {
      "name": "Svalbard And Jan Mayen",
      "iso2": "SJ",
      "code": "47"
    },
    {
      "name": "Swaziland",
      "iso2": "SZ",
      "code": "268"
    },
    {
      "name": "Sweden",
      "iso2": "SE",
      "code": "46"
    },
    {
      "name": "Switzerland",
      "iso2": "CH",
      "code": "41"
    },
    {
      "name": "Syrian Arab Republic",
      "iso2": "SY",
      "code": "963"
    },
    {
      "name": "Taiwan, Province Of China",
      "iso2": "TW",
      "code": "886"
    },
    {
      "name": "Tajikistan",
      "iso2": "TJ",
      "code": "992"
    },
    {
      "name": "Tanzania, United Republic Of",
      "iso2": "TZ",
      "code": "255"
    },
    {
      "name": "Thailand",
      "iso2": "TH",
      "code": "66"
    },
    {
      "name": "Togo",
      "iso2": "TG",
      "code": "228"
    },
    {
      "name": "Tokelau",
      "iso2": "TK",
      "code": "690"
    },
    {
      "name": "Tonga",
      "iso2": "TO",
      "code": "676"
    },
    {
      "name": "Trinidad And Tobago",
      "iso2": "TT",
      "code": "1868"
    },
    {
      "name": "Tristan de Cunha",
      "iso2": "TA",
      "code": "290"
    },
    {
      "name": "Tunisia",
      "iso2": "TN",
      "code": "216"
    },
    {
      "name": "Turkey",
      "iso2": "TR",
      "code": "90"
    },
    {
      "name": "Turkmenistan",
      "iso2": "TM",
      "code": "993"
    },
    {
      "name": "Turks And Caicos Islands",
      "iso2": "TC",
      "code": "1649"
    },
    {
      "name": "Tuvalu",
      "iso2": "TV",
      "code": "688"
    },
    {
      "name": "USSR",
      "iso2": "SU",
      "code": "7"
    },
    {
      "name": "Uganda",
      "iso2": "UG",
      "code": "256"
    },
    {
      "name": "Ukraine",
      "iso2": "UA",
      "code": "380"
    },
    {
      "name": "United Arab Emirates",
      "iso2": "AE",
      "code": "971"
    },
    {
      "name": "United Kingdom",
      "iso2": "GB",
      "code": "1473"
    },
    {
      "name": "United Kingdom",
      "iso2": "UK",
      "code": "44"
    },
    {
      "name": "United States",
      "iso2": "US",
      "code": "1"
    },
    {
      "name": "United States Minor Outlying Islands",
      "iso2": "UM",
      "code": "581"
    },
    {
      "name": "Uruguay",
      "iso2": "UY",
      "code": "598"
    },
    {
      "name": "Uzbekistan",
      "iso2": "UZ",
      "code": "998"
    },
    {
      "name": "Vanuatu",
      "iso2": "VU",
      "code": "678"
    },
    {
      "name": "Vatican City State",
      "iso2": "VA",
      "code": "379"
    },
    {
      "name": "Venezuela, Bolivarian Republic Of",
      "iso2": "VE",
      "code": "58"
    },
    {
      "name": "Viet Nam",
      "iso2": "VN",
      "code": "84"
    },
    {
      "name": "Virgin Islands (British)",
      "iso2": "VG",
      "code": "1284"
    },
    {
      "name": "Virgin Islands (US)",
      "iso2": "VI",
      "code": "1340"
    },
    {
      "name": "Wallis And Futuna",
      "iso2": "WF",
      "code": "681"
    },
    {
      "name": "Western Sahara",
      "iso2": "EH",
      "code": "212"
    },
    {
      "name": "Yemen",
      "iso2": "YE",
      "code": "967"
    },
    {
      "name": "Zambia",
      "iso2": "ZM",
      "code": "260"
    },
    {
      "name": "Zimbabwe",
      "iso2": "ZW",
      "code": "263"
    }
]