import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import PropTypes from 'prop-types';
import Scrollbar from "react-scrollbar";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box,Tabs,Tab,TextField,Autocomplete,MenuItem,
  Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {formatTime} from './../utils/formatTime';
import "./style.css"
import {userDetails,BookingDurations} from '../constants'
import { timelineDotClasses } from '@mui/lab';

const Booking = () => {

  const [user, setUser] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [timeToggle, settimeToggle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [viewLevel, setViewLevel] = React.useState('');
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [bookingDetails, setBookingDetails] = React.useState([{lane:[],weapon:[]}]);  
  const [approve, setApprove] = React.useState(false);
  const [statusChange, setStatusChange] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [values, setValues] = React.useState({
    viewLevel:{label: "Lane"},
    status:"requested",
    name: '',
    userName:"",
    comment:'',
    duration: {
      label: '1 Hr',
      key: 70900
    },
    fromTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
    toTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 70900)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 70900)).toISOString().split(':')[1]}`
  });

  const viewLevelList = [{
    label: "Lane"
  }, {
    label: "Weapon"
  }]

  const status = [{
    label: "Request",
    key: "requested"
  }, {
    label: "Approve",
    key:"approved"
  }, {
    label: "Available",
    key:"available"
  }]

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
     let time = "";
     if (timeToggle) {
       time = 'future'
     } else {
       time = 'today'
     }
     axios
       .post(`/api/user/getParticularUserByEmail`, {
         "emailID":userDetails.email
       })
       .then((response) => {
         const userData = response.data.data;
         getBookings(userData[0].academyId,time)
         setValues({
           ...values,
           "userName": userData[0].displayName,
           "userId": userData[0]._id
          });
          setUser(userData);
          getUserList(userData)
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }

  const getUserList = (userId) => {
    axios
      .post(`/api/user/academyUserDetails`, {
        emailId: userId[0].emailID,
        userId: userId[0]._id
      })
      .then((response) => {
        let data = response.data.data;
        let newArray = [];
        data.map((item,index)=>{
          newArray.push(item._id);
          if(data.length === index+1){  
            let indexValue = newArray.map(e => e.displayName).indexOf(userId[0].displayName);
            let cutOut = newArray.splice(indexValue, 1)[0]; // cut the element at index 'from'
            newArray.splice(0, 0, cutOut); //insert in the to position
            setUserList(newArray)
          }
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getBookings = (data,time) => {   
    axios
      .post(`/api/booking/getDetailsByAcademyId`, {
        "academyId": data,
        "filter":time
      })
      .then((response) => {
        let value= [{lane:[],weapon:[]}]
        const details = response.data.data;
        details.map((item)=>{
          if (item.type.toLowerCase() == "lane"){
            value[0].lane.push(item)
          }else{
            value[0].weapon.push(item)
          }
        })
        setBookingDetails(value);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeSwitch = (event) => {
    // console.log(event.target.name, "****",event.target.checked)
    settimeToggle(event.target.checked);
    let time = "";
    if (event.target.checked) {
      time = 'future'
    } else {
      time = 'today'
    }
    getBookings(user[0].academyId,time)
  };

  function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
              {/* {card(children)} */}
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangeInput = (props) => (event) => {
      console.log(event.target.value)
      if(props === 'fromTime'){
        setValues({
          ...values,
          [props]: event.target.value,
          "toTime": `${(new Date(new Date(event.target.value) - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[0]}:${(new Date(new Date(event.target.value) - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[1]}`
        });
      }else{
        setValues({
          ...values,
          [props]: event.target.value
        });
      }
    };

    const handleInput = () => {
      setisSubmitting(true)
      if (values.name === '') {
        toast.error("Enter all the fields before save");
        setisSubmitting(false)
      }else{
        axios
          .post(`/api/booking/add`, {
            type: values.viewLevel.label,
            name: values.name,
            userName: values.userName,
            fromTime: `${values.fromTime}:00.000Z`,
            toTime: `${values.toTime}:00.000Z`,
            academyId: user[0].academyId,
            userId: values.userId,
            bookedBy:user[0]._id,
            status: user[0].role.includes("coach") || user[0].role.includes("superAdmin") || user[0].role.includes("academy") ? values.status : "requested"
          })
          .then((response) => {
            setisSubmitting(false)
            setOpen(false);
            getBookings(user[0].academyId, timeToggle)
            setValues({
              name: '',
              userName: user[0].displayName,
              status: 'requested',
              viewLevel:{label:"Lane"},
              type: {label:'Lane'},
              userId: user[0]._id,
              duration: {label: '1 Hr',key: 70900},
              fromTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
              toTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[1]}`,
            })
          })
          .catch((error) => {
          setisSubmitting(false)
            toast.error(error.response.data);
          });
      }
    }

    const handleUpdate =(item,status) =>{
      setisSubmitting(true);
      axios
          .put(`/api/booking/update`, {
            _id: item._id,
            status: status,
            comment: values.comment
          })
          .then((response) => {
            
            setApprove(false)
            setisSubmitting(false)
            setSelectedItem({})
            setStatusChange(false)
            getBookings(user[0].academyId, timeToggle)
            setValues({
              name: '',
              userName: user[0].displayName,
              status:'requested',
              viewLevel:{label:"Lane"},
              type: {label:'Lane'},
              comment:"",
              userId: user[0]._id,
              duration: {label: '1 Hr',key: 70900},
              fromTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
              toTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[1]}`,
            })
          })
          .catch((error) => {
          setisSubmitting(false)
            toast.error(error.response.data);
          });
    }

    const input = () =>{      
      return (
      <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
       <Autocomplete
          id = "disable-clearable"
          disableClearable
          options={viewLevelList}
          defaultValue={values.viewLevel}
          getOptionLabel={(option) => {return option.label}}
          inputValue={values.viewLevel1}
          onInputChange={(event, newInputValue) => {
                setViewLevel(newInputValue);
          }}
           onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['viewLevel']: newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="View Level" sx={{mt:2}}/>}
        />
          <Autocomplete
          id = "disable-clearable"
          disableClearable
          options={userList}
          defaultValue={userList[0]}
          getOptionLabel={(option) => {return option.displayName}}
          inputValue={values.viewLevel1}
          onInputChange={(event, newInputValue) => {
                setViewLevel(newInputValue);
          }}
           onChange={(event, newValue) => {  
            setValues({
              ...values,
              ['userName']: newValue.displayName,
              ['userId']:newValue.id
            });
          }}
          renderInput={(params) => <TextField {...params} label="userName" sx={{mt:2}}/>}
        />
        <TextField
          id = "filled-flexible"
          defaultValue = {values.name}
          value={values.name}
          varient="standard"
          label = {values.viewLevel.label==="Lane"?"Lane Name":"Weapon Name"}
          type = 'text'
          sx={{mt:2}}
          onChange={handleChangeInput('name')}
          />
          <TextField
          id="datetime-local"
          label="From Time"
          type="datetime-local"
          value={values.fromTime}   
          InputProps = {
            {
              inputProps: {
                min: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
              }
            }
          }
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChangeInput('fromTime')}
        /> 
        <Autocomplete
          id = "disable-clearable"
          disableClearable
          options={BookingDurations}
          defaultValue={values.duration}
          getOptionLabel={(option) => {return option.label}}
          inputValue={values.viewLevel1}
          onInputChange={(event, newInputValue) => {
                setViewLevel(newInputValue);
          }}
           onChange={(event, newValue) => {  
            //  console.log(newValue)          
            setValues({
              ...values,
              ['duration']: newValue,
              "toTime": `${(new Date(new Date(values.fromTime) - (new Date()).getTimezoneOffset() * newValue.key)).toISOString().split(':')[0]}:${(new Date(new Date(values.fromTime) - (new Date()).getTimezoneOffset() * newValue.key)).toISOString().split(':')[1]}`
            });
          }}
          renderInput={(params) => <TextField {...params} label="Duration" sx={{mt:2}}/>}
        />
        <TextField
          id="datetime-local"
          label="To Time"
          type="datetime-local"
          value={values.toTime}   
          InputProps = {
            {
              inputProps: {
                min: `${(new Date(new Date(values.fromTime) - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[0]}:${(new Date(new Date(values.fromTime) - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[1]}`
              }
            }
          }
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChangeInput('toTime')}
        /> 
        {user[0].role.includes("coach") || user[0].role.includes("superAdmin") || user[0].role.includes("academy") ?
        <TextField
          id = "filled-flexible"
          select
          label = "Status"
          defaultValue = {values.status}
          value={values.status}
          onChange={handleChangeInput('status')}
          variant = "standard"
          style={{align:"right"}}
        >
          {
            status.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        :null}
      </Stack>
      )}

  const card = (links) => {    
    return (
      <div>
          <Stack direction="row"  justifyContent="space-between" mb={2}>
             <Box sx={{ width: '100%' }}>         
                <Scrollbar>
                <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                  <TableHead>
                  <TableRow>
                  <TableCell align="left">Shooter</TableCell>
                  <TableCell align="left">{links[0].type==="Lane"?"Lane":"Weapon"}</TableCell>
                  {timeToggle === true?<TableCell align="left">Date</TableCell>:null}
                  <TableCell align="left">Time</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {links.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
                    return (
                      <TableRow tabIndex={-1}>
                        <TableCell align="left">{item.userName}</TableCell>
                        <TableCell align="left">{item.name}</TableCell>
                        {timeToggle === true?<TableCell align="left">{item.fromTime.split('T')[0]}</TableCell>:null}
                        <TableCell align="left">
                          {formatTime(item.fromTime.split('T')[1]).split(" ")[0]} - {formatTime(item.toTime.split('T')[1])}</TableCell>
                        <TableCell align="left">{item.status.toUpperCase()=== "APPROVED"?
                          // <Iconify icon = "subway:tick" sx={{width:"100%",color:'green'}} />
                          <div style={{color:"green"}}> {item.status.toUpperCase()}</div>
                          :
                            item.status.toUpperCase() === "REQUESTED" ?
                             user[0].role.includes("coach") || user[0].role.includes("superAdmin") || user[0].role.includes("academy")?
                              <Button variant="contained" onClick={() => {setApprove(true);setSelectedItem(item)}}>
                                {item.status.toUpperCase()}
                              </Button>
                              :<div style={{color:"darkorange"}}>{item.status.toUpperCase()}</div>
                            :
                            item.status.toUpperCase() === "AVAILABLE" ?
                             user[0].role.includes("coach") || user[0].role.includes("superAdmin")  | user[0].role.includes("academy")?
                              <Button variant="contained" onClick={() => {setStatusChange(true);setSelectedItem(item)}}>
                                {item.status.toUpperCase()}
                              </Button>
                              :<div style={{color:"darkblue"}}>{item.status.toUpperCase()}</div>
                            :<div style={{color:"red"}}>{item.status.toUpperCase()}</div>
                         }
                        </TableCell>
                        <TableCell align="left">{item.comment === undefined ?"":item.comment}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3, 5, 10, 25]}
                colSpan={3}
                count={links.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              </Scrollbar>
             </Box>
          </Stack>
        </div>
    )}

  const NoData = () => {
    return (
      <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'7%'},marginLeft:{sm:'20%',md:'29%'}}}>
        <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No Data</Typography> 
        <img src="/static/illustrations/empty_bin.png" alt="No Details" style={{width:'300px'}} />
      </Box>
    )}

  return (
    <Page title="Booking">
      <Toast />
      <Container>
       {open === true ?null:
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>        
          <Typography variant="h4" gutterBottom>
            Booking for the day
          </Typography>    
          <Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Booking
          </Button>     
        </Stack> 
        }
        {open === true ?
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {update === true ?<span>Update Booking</span>: <span>Add Booking</span>}
          </Typography>
          {input()}
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
            {update === true ?
            <Button variant="outlined">update</Button>
            :<Button variant="outlined" onClick={handleInput}>save</Button>} 
            <Button variant="outlined" color="error" onClick={() => {setOpen(false); setUpdate(false)
                setValues({
                  viewLevel:{label:'Lane'},
                  name:'',userName: user[0].displayName,
                  status:'requested',
                  type: {label:'Lane'},
                  comment: "",
                  userId: '',
                  duration: {label: '1 Hr',key: 70900},
                  fromTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
                  toTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * values.duration.key)).toISOString().split(':')[1]}`,
                })}}>
            Cancel</Button>
          </Stack>
          </Stack> :    
            <Stack>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={{md:22,sm:7,xs:5}} >
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              // sx={{ borderRight: 1, borderColor: 'divider',width:{xs:250,sm:100,md:100} }}
            >
              <Tab wrapped label="Lane Booking" {...a11yProps(0)} />
              <Tab wrapped label="Weapon Booking" {...a11yProps(1)} />
            </Tabs> 
            <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Today</Typography>
              <FormControlLabel
                control={
                  <Switch checked={timeToggle} onChange={handleChangeSwitch} name="timeToggle" />
                }
                label="All"
              />
              </Stack>
            </FormGroup>
          </FormControl>
          </Stack>
            <TabPanel value={value} index={0}>
              { bookingDetails[0].lane.length !== 0 ?card(bookingDetails[0].lane):NoData()}
            </TabPanel>
            <TabPanel value={value} index={1}>
              { bookingDetails[0].weapon.length !== 0 ?card(bookingDetails[0].weapon):NoData()}
            </TabPanel>
            </Stack>
        }
        <Modal open={approve}>         
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>        
            <Typography variant="h4" gutterBottom>
              Do you need to approve the request?
            </Typography>    
          </Stack> 
            <TextField
            id = "filled-flexible"
            defaultValue = {values.comment}
            value={values.comment}
            varient="standard"
            label = "comment"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('comment')}
            />
            <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={1}>
            <Button variant="outlined" onClick={()=>{handleUpdate(selectedItem,"approved")}}>Approve</Button>
            <Button variant="outlined" color="error" onClick={() => {handleUpdate(selectedItem,"declined")}}>Decline</Button>
             <Button variant="outlined" color="error" onClick={() => {setApprove(false)}}>Close</Button>
          </Stack>  
          </Box>
          </Modal>
          <Modal open={statusChange}>         
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >       
          <Typography variant="h4" gutterBottom>
            Status change for the available slot
          </Typography>   
          <TextField
          id = "filled-flexible"
          select
          label = "Status"
          defaultValue = {values.status}
          value={values.status}
          onChange={handleChangeInput('status')}
          variant = "standard"
          style={{align:"right",width:"50%"}}
          >
            {
              status.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={2}>
            <Button variant="outlined" onClick={()=>{handleUpdate(selectedItem,values.status)}}>Save</Button>
            <Button variant="outlined" color="error" onClick={() => {setStatusChange(false)}}>Close</Button>
          </Stack>  
          </Box>
          </Modal>
        {isSubmitting && (
          <Modal
            open={isSubmitting}
          >         
            <Box
              component="form"
              sx = {{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height:'80%',
                bgcolor: 'background.paper',
              }}
              noValidate
              autoComplete="off"
            >
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              bgcolor: 'background.paper',
            }}
          />
          </Box>
          </Modal>
        )}
      </Container>
    </Page>
  );
}
export default Booking;