// @mui
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {
    Card,
    Box, CardHeader,
    TableContainer,
    TableRow,
    TableBody,
    TableCell,
    Table,
    Paper,
    TableHead,
} from '@mui/material';

UserDashboardTable.propTypes = {
    header: PropTypes.array,
    data: PropTypes.array,
};

export default function UserDashboardTable({
    header,
    data,
    title
}) {
    
    return (
        <Card>
            <CardHeader title={title} />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            {data.length !==0?          
            <TableContainer component={Paper}>
                      <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">{header[0]}</TableCell>
                            <TableCell align="left">{header[1]}</TableCell>
                            <TableCell align="left">{header[2]}</TableCell>
                            <TableCell align="left">{header[3]}</TableCell>
                            <TableCell align="left">{header[4]}</TableCell>
                            <TableCell align="left">{header[5]}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>                           
                              <TableRow tabIndex={-1}>
                                <TableCell  align="left">{data.matchCount[0].toUpperCase()}</TableCell>
                                <TableCell align="left">{data.matchCount[1]}</TableCell>
                                <TableCell align="left">{data.matchCount[2]}</TableCell>
                                <TableCell  align="left">{data.matchCount[3]}</TableCell>
                                <TableCell align="left">{data.matchCount[4]}</TableCell>
                                <TableCell align="left">{data.matchCount[5]}</TableCell>
                                <TableCell align="left">{data.matchCount[6]}</TableCell>
                              </TableRow>   
                           
                              <TableRow tabIndex={-1}>
                                <TableCell  align="left">{data.seriesCount[0].toUpperCase()}</TableCell>
                                <TableCell align="left">{data.seriesCount[1]}</TableCell>
                                <TableCell align="left">{data.seriesCount[2]}</TableCell>
                                <TableCell  align="left">{data.seriesCount[3]}</TableCell>
                                <TableCell align="left">{data.seriesCount[4]}</TableCell>
                                <TableCell align="left">{data.seriesCount[5]}</TableCell>
                                <TableCell align="left">{data.seriesCount[6]}</TableCell>
                              </TableRow> 

                              <TableRow tabIndex={-1}>
                                <TableCell  align="left">{data.shotCount[0].toUpperCase()}</TableCell>
                                <TableCell align="left">{data.shotCount[1]}</TableCell>
                                <TableCell align="left">{data.shotCount[2]}</TableCell>
                                <TableCell  align="left">{data.shotCount[3]}</TableCell>
                                <TableCell align="left">{data.shotCount[4]}</TableCell>
                                <TableCell align="left">{data.shotCount[5]}</TableCell>
                                <TableCell align="left">{data.shotCount[6]}</TableCell>
                              </TableRow> 
                        </TableBody>
                      </Table>
                    </TableContainer>
                    :null}
              </Box>
            </Card>
    );
}
