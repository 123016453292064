import * as React from 'react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container, Modal, Box, TextField, Tabs, Tab,
  Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import {userDetails} from '../constants'

const AdminConfig = ()=> {
    
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);  
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState({});
  const [valueTab, setValueTab] = React.useState(0);
  const [comment, setComment] = React.useState('');

  useEffect(() => {
    getDetails()
  }, []);

   function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeInput = (props) => (event) => {
    setComment(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDetails = ()=>{
    axios
      .post(`/api/userAcademyRequest/getAllDetails`, {})
      .then((response) => {
        setDetails(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.message);
      });
  }
  const imageCheck =(id)=>{
       axios.get(`/paymentPic/${id}.png`, {responseType: 'blob'})
        .then(res => {
            var imageUrl = URL.createObjectURL(res.data);
            window.open(imageUrl, '_blank');
        }).
        catch(e=>{
          toast.error("error in fetching the screenshot/ No Screenshot")
        })  
  }

  const handleApprove =()=>{
    if (comment === '') {
      toast.error("Please provide the comment to approve");
    } else {
    let data ={
      "_id": selectedFile.user[0][0]._id,
      academyId: selectedFile.academy[0][0]._id
    }
    let academyData = {
      "academyId": selectedFile.academy[0][0]._id,
      "userId": selectedFile.user[0][0]._id
    }
    let data1={
      _id: selectedFile.request[0].id,
      status:"approved",
      comment:comment
    }
    axios
      .put(`/api/user/update`, data)
      .then((response1) => {
        axios
          .post(`/api/user/addPaymentOn`, { "_id": selectedFile.user[0][0]._id})
          .then((response1) => {
            axios
              .put(`/api/myLinks/updateAcademyId`, academyData)
              .then((response2) => {
                axios
                  .put(`/api/userAcademyRequest/update`, data1)
                  .then((response3) => {
                      setOpen(false);
                      setComment("");
                      getDetails();
                      sendMail(data1)
                  })
                  .catch((error) => {
                    //   console.log(error)
                    toast.error(error.message);
                  });
                })
                .catch((error) => {
                  //   console.log(error)
                  toast.error(error.message);
                });
            })
            .catch((error) => {
              //   console.log(error)
              toast.error(error.message);
            });
      })
      .catch((error) => {
        //   console.log(error)
        toast.error(error.message);
      });
    }
  }
  const handleDecline = () => {
    if(comment === ''){
      toast.error("Please provide the comment to decline");
    }else{
      let data1 = {
        _id: selectedFile.request[0].id,
        status: "declined",
        comment:comment
      }
      axios
        .put(`/api/userAcademyRequest/update`, data1)
        .then((response3) => {
          setOpen(false);
          setComment("");
          getDetails();
          sendMail(data1)
        })
        .catch((error) => {
          //   console.log(error)
          toast.error(error.message);
        });
    }
  }

  const sendMail = (data) => {
    axios
      .post(`/api/userAcademyRequest/sendMailApproval`, {
        emailID: selectedFile.user[0][0].emailID,
        user: selectedFile.user[0][0].displayName,
        requestBy: selectedFile.requestedUser[0][0].emailID,
        requestedUser: selectedFile.requestedUser[0][0].displayName,
        status: data.status,
        comment: data.comment
      })
      .then((response) => {
        // console.log(response.data.data._id)
        setValue({
          ...value,
          tnxId: "",
          userId: '',
          tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
        })
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  return (
    <Page title="User Request">
      <Toast />
      <Container>
        <Stack direction="row" justifyContent="space-between" mt={-2}>        
          <Typography variant="h6" gutterBottom>
            Admin Config
          </Typography>
        </Stack>    
        <Stack >
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={{md:22,sm:7,xs:5}} >
          <Tabs
            variant="scrollable"
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab wrapped label="Shooter Academy Change" {...a11yProps(0)} />
          </Tabs> 
          </Stack>
          <TabPanel value={valueTab} index={0}>
          <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }} >
                <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                  <TableHead>
                  <TableRow>
                  <TableCell align="left">Shooter Id</TableCell>
                  <TableCell align="left">Shooter Name</TableCell>
                  <TableCell align="left">Academy Name</TableCell>
                  <TableCell align="left">Requested By</TableCell>
                  <TableCell align="left">Requested On</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.length >0 && details.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
                    return (
                      <TableRow tabIndex={-1}>
                        <TableCell align="left">{item.user[0].length>0?item.user[0][0].userId:null}</TableCell>
                        <TableCell align="left">{item.user[0].length>0?item.user[0][0].displayName:null}</TableCell>
                        <TableCell align="left">{item.academy[0][0].displayName}</TableCell>
                        <TableCell align="left">{item.requestedUser[0][0].displayName}</TableCell>
                        <TableCell align="left">{item.request[0].tnxDate.split('T')[0]}</TableCell>
                        <TableCell align="left" sx={{color:item.request[0].status==='approved'?"green":item.request[0].status==='declined'?"red":"orange"}}>{item.request[0].status.toUpperCase()}</TableCell>
                        <TableCell align="left">
                          <Iconify icon = "ion:attach-sharp" sx={{cursor: "pointer"}} onClick={()=>{imageCheck(item.request[0].id)}}/>
                          {item.request[0].status.toLowerCase() == "requested"?<Iconify icon = "mdi:account-tick-outline" sx={{cursor: "pointer"}} onClick={()=>{setOpen(true);setSelectedFile(item)}}/>:null}
                        </TableCell>
                        </TableRow>
                    )
                  })}
                </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3, 5, 10, 25]}
                colSpan={3}
                count={details.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </Scrollbar>
          </TabPanel>
        </Stack>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5" gutterBottom component="div">
              Do you need to approve the academy change? 
            </Typography>
            <TextField
            id = "filled-flexible"
            defaultValue = {comment}
            value={comment}
            multiple
            varient="standard"
            label = "Comment"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('comment')}
            /> 
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >            
              <Button variant="outlined" onClick={handleApprove}>
                Approve
              </Button>
              <Button variant = "outlined" color = "error" onClick={handleDecline} >
                Decline
              </Button>
              <Button variant = "outlined" color = "error" onClick = {() => {setOpen(false)}} >
                close
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
export default AdminConfig;