import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import axios from "axios";
import toast from "react-hot-toast";
import PropTypes from 'prop-types';
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box,Rating,
  Card, CardContent, Grid,
  Tab, TextField, Popover, IconButton,
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,TableHeader,
  ToggleButtonGroup, ToggleButton
} from '@mui/material';
// import SearchBar from "material-ui-search-bar"; 
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import CircularProgress from '@mui/material/CircularProgress';
import {
  BarChart,
} from '../sections/@dashboard/app';

import jsPDF from "jspdf";
require('jspdf-autotable');
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {competitionCategory,Type,userDetails,teamType,Gender,matchType,ACADEMY_POINT_SEQUENCE} from '../constants'
import LiveScoreCard from './LiveScoreCard';
import { now } from 'lodash';

const competitionDashboard = () => {

  const { competitionId } = useParams();
  const [alignment, setAlignment] = React.useState('table');
  const [alignmentScore, setAlignmentScore] = React.useState('non-zero');
  const [open, setOpen] = React.useState(false);
  const [feedBackModel, setfeedBackModel] = React.useState(false);
  const [protestModel, setProtestModel] = React.useState(false);
  const [addData, setAddData] = React.useState(false);
  const [dataUpdate, setDataUpdate] = React.useState(false);
  const [filterPop, setFilterPop] = React.useState(false);
  const [valueTab, setValueTab] = React.useState(0);
  const [selectedData, setSelectedData] = React.useState({});
  const [value, setValue] = React.useState(0);
  const [newData, setNewData] = React.useState({value:''});
  const [userRole, setUserRole] = React.useState(['competition']);
  const [Course, setCourse] = React.useState([]);
  const [tabList, setTabList] = React.useState([]);
  const [competitionData, setCompetitionData] = React.useState([]);
  const [particularCompData, setParticularCompData] = React.useState([]);
  const [particularCompExcel, setParticularCompExcel] = React.useState([]);
  const [selectedFile, setselectedFile] = useState("");
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [reportLoader, setreportLoader] = React.useState(false)
  const [consreportLoader, setconsreportLoader] = React.useState(false)
  
  const [values, setValues] = React.useState({
    name:'',
    overAllScore:0,
    overAllScoreDeciaml:0,
    noOf10x:0,
    noOf10:0,
    percentage:0,
    seriesScore:[],
    penalty:0,
    rem:"C"
  });
  const [filterValues, setFilterValues] = React.useState({
    // gender: 'Men',
    // gameType: 'Air Pistol 10m',
    // teamType: 'Individual',
    // matchType: 'NR',
    // category: 'Senior'
    gender: '',
    gameType: '',
    teamType: '',
    matchType: '',
    category: '',
    shooterCategory: ''
  });
  const [rating,setRating] = React.useState(5);
  const [feedback,setFeedback] = React.useState({
    comment:"",
    commentEmail:""
  });const [protest,setProtest] = React.useState({
    comment:` Match No : 
    Score :
    Protest summary :`,
    shooterName:""
  });
  const [searchFocus, setSearchFocus] = React.useState(false);
  const [commentFocus, setCommentFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [compSummary, setCompSummary] = React.useState([]);
  const [topAcademy, setTopAcademy] = React.useState({data:[],name:[]});
  const [topAcademyDetails, setTopAcademyDetails] = React.useState({});
  const [academyCateWise, setAcademyCateWise] = React.useState({});
  const [academyUserCount, setAcademyUserCount] = React.useState([]);
  const [sortTableData, setSortTableData] = React.useState([]);
  const [compeletedMatch, setCompeletedMatch] = React.useState({data:[],name:[],gold:[],silver:[],bronze:[]});
  const [compeletedMatchCateWise, setCompeletedMatchCateWise] = React.useState({});
  const [compeletedMatchTable, setCompeletedMatchTable] = React.useState([]);
  const [matchGroup, setMatchGroup] = React.useState({data:[],name:[]});
  const [matchGroupCateWise, setMatchGroupCateWise] = React.useState({});
  const [matchGroupTable, setMatchGroupTable] = React.useState([]);
  const [completedList,setCompletedList] = React.useState({});

  useEffect(() => {
    // getCompetitions();
    getAllCompData(alignmentScore)
    getUserDetail();
    getCompetitionDetail();
    // getMatchSummary();
    topAcademyList();
    CompletedMatchList();
    MatchGroup();
  }, []);

   // useEffect(()=>{
    // AlwaysScrollToBottom() 
  // },[Course])

  const getUserDetail = () => {
    if (userDetails.email === undefined){
      setUserRole(['competition'])
    }else{
      axios
        .post(`/api/user/getParticularUserByEmail`, {
          "emailID": userDetails.email
        })
        .then((response) => {
          let userData = [];
          if(response.data.data.length >0){
            userData = response.data.data;
            setUserRole(userData[0].role);
          }
          
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

  const getCompetitionDetail = () =>{
     axios
       .post(`/api/competition/getParticularCompetition`, {
         _id: competitionId,
       })
       .then((response) => {
        //  console.log(response.data.data)
         const res = response.data.data
          setParticularCompData(res[0]);
       })
       .catch((error) => {
         toast.error(error.response.data);
       });
  }

  const getMatchSummary = () =>{
    axios
      .post(`/api/competition/getMatchSummary`, {
        id: competitionId,
      })
      .then((response) => {
        // console.log(response.data.data)
        const res = response.data.data
         setCompSummary(res);
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const topAcademyList = () =>{
    axios
      .post(`/api/competition/topAcademyList`, {
        id: competitionId,
      })
      .then((response) => {
        // console.log(response.data.data)
        const res = response.data.data
        setTopAcademyDetails(res)
        AcademyUserCount(res,'list');
        const transformedObject = res.academyRank.reduce((result, item) => {
          result.name.push(item.academy);
          result.data.push(item.totalPoints);
          return result;
        }, { name: [], data: [] });
        // console.log(transformedObject)
        setTopAcademy(transformedObject);
        const transformedObject1= Object.keys(res.groupScoresByCategory).map((cateName) => {
          const categoryData = res.groupScoresByCategory[cateName];
        
          return {
            cateName,
            name: categoryData.map((item) => item.academy),
            data: categoryData.map((item) => item.totalPoints)
          };
        });
        setAcademyCateWise(transformedObject1)
        // console.log(transformedObject1)
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const metalsRanking = (data,userCount,val) =>{
    const mergedData = data.medalDetail.map((medal) => {
      const userCountData = userCount.find((userCount) => userCount._id === medal.academy);
      return {
        academy: medal.academy,
        gold: medal.gold,
        silver: medal.silver,
        bronze: medal.bronze,
        totalPoints: medal.totalPoints,
        userCount: userCountData ? userCountData.userCount : 0
      };
    });
    
    // Calculate the count, points, and rank for each academy
    const rankedData = mergedData.map((academy, index) => {
      return {
        // rank: index + 1,
        academy: academy.academy,
        count: academy.userCount,
        gold: academy.gold,
        silver: academy.silver,
        bronze: academy.bronze,
        points: academy.totalPoints
      };
    });
    
    // Sort the result based on points to determine the rank
    const sortedData = rankedData.sort((a, b) => b.points - a.points);
    
    // Display the result
    val == 'list'?setSortTableData(sortedData):val=='match'?setCompeletedMatchTable(sortedData):setMatchGroupTable(sortedData)
    // console.log(sortedData)
    // console.table(sortedData, ['rank', 'academy', 'count', 'gold', 'silver', 'bronze', 'points']);
  }

  const AcademyUserCount = (data,val) =>{
    axios
      .post(`/api/competition/userInAcademy`, {
        id: competitionId,
      })
      .then((response) => {
        // console.log(response.data.data,data)
        setAcademyUserCount(response.data.data)
        metalsRanking(data,response.data.data,val)
      })
      .catch((error) => {
        console.log(error)
        // toast.error(error.response);
      });
  } 

  const CompletedMatchList = () =>{
    axios
      .post(`/api/competition/CompletedMatchList`, {
        id: competitionId,
        status: 'COMPLETED'
      })
      .then((response) => {
        const res = response.data.data
        // console.log(res)
        setCompletedList(res)
        AcademyUserCount(res,'match');
        const transformedObject = res.academyRank.reduce((result, item) => {
          result.name.push(item.academy);
          result.data.push(item.totalPoints);
          return result;
        }, { name: [], data: [] });
        // console.log(transformedObject)
        setCompeletedMatch(transformedObject);
        const transformedObject1= Object.keys(res.groupScoresByCategory).map((cateName) => {
          const categoryData = res.groupScoresByCategory[cateName];
        
          return {
            cateName,
            name: categoryData.map((item) => item.academy),
            data: categoryData.map((item) => item.totalPoints),
            gold:categoryData.map((item) => item.gold),
            silver:categoryData.map((item) => item.silver),
            bronze:categoryData.map((item) => item.bronze)

          };
        });
        setCompeletedMatchCateWise(transformedObject1)
        // console.log(transformedObject1)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const MatchGroup = () =>{
    axios
      .post(`/api/competition/MatchGroup`, {
        id: competitionId
      })
      .then((response) => {
        const res = response.data.data
        AcademyUserCount(res,'matchGroup');
        const transformedObject = res.academyRank.reduce((result, item) => {
          result.name.push(item.academy);
          result.data.push(item.totalPoints);
          return result;
        }, { name: [], data: [] });
        // console.log(transformedObject)
        setMatchGroup(transformedObject);
        const transformedObject1= Object.keys(res.groupScoresByCategory).map((cateName) => {
          const categoryData = res.groupScoresByCategory[cateName];
        
          return {
            cateName,
            name: categoryData.map((item) => item.academy),
            data: categoryData.map((item) => item.totalPoints)
          };
        });
        setMatchGroupCateWise(transformedObject1)
        // console.log(transformedObject1)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getCompetitions = () => {
    // console.log(filterValues)
    axios
      .post(`/api/compMatchMapper/getParticularcompetition`, {
        competitionId: competitionId,
        filterValues: filterValues
      })
      .then((response) => {
        // console.log("****",response.data.data)
        const res = response.data.data
      //  if (response.data.data.length === 0) {
      //    setCompetitionData([])
      //  } else {
      //    setCompetitionData(res[0].competition);
      //  }
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const getAllCompData = (alignmentScore) => {
    // console.log(filterValues)
    let data = { competitionId: competitionId}
    if(alignmentScore == 'non-zero'){
      data = {
        competitionId: competitionId,
        overAllScore :{$gt:0}
      }
    }else if(alignmentScore == 'zero'){
      data = {
        competitionId: competitionId
      }
    }
    axios
      .post(`/api/compMatchMapper/getParticularcompMatchMapper`, data)
      .then((response) => {
        // console.log(response.data.data)
        const res = response.data.data
       if (response.data.data.length === 0) {
         setCompetitionData([])
       } else {
        // console.log(res)
         const sortedData = res.sort((a, b) => b.overAllScore - a.overAllScore);
         setCompetitionData(sortedData);
       }
       getCompetitionExcel();
        getCourse();
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }
  const getCompetitionExcel = () =>{
    axios
      .post(`/api/competition/readCompExcel`, {
        _id: competitionId,
      })
      .then((response) => {
        const res = response.data.data
         setParticularCompExcel(res);
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
 }

  const getCourse = () => {
    axios
      .get(`/api/course/getAll`)
      .then((response) => {
        const courseData = response.data.data;
        const value = [];
        courseData.map((item, index) => {
          const value1 = {
            value: `${item.type}-${item.range}`,
            label: `${item.displayName} ${item.range}m`,
          }
          value.push(value1)
        })
        setCourse(value);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const sortFunction = (data) =>{
    data.sort(function (a, b) {
      return b.overAllScore - a.overAllScore;
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {setOpen(false);};

  const onChangeHandlerForZip = (e) => {
    try {
      let extension = e.target.files[0].name.split(".")[1];
      if (extension.toLowerCase() !== "xlsx") {
        toast.error("Please upload the image in .xlsx format");
      } else {
        setselectedFile(e.target.files[0]);
      }
    } catch {
      console.log("No File selected");
    }
  };

  const handleUpload = () => {
    setisSubmitting(true);
    const data1 = new FormData();
    data1.append("file", selectedFile);
    data1.append("id", competitionId);
    // console.log(selectedFile)
    axios
      .post(`/api/competition/uploadFile`, data1, {})
      .then((res) => {
        if (res.status === 200) {
          // getMatchSummary();
          setOpen(false);
          setisSubmitting(false)
          setselectedFile('')
          // getCompetitions();
          getAllCompData(alignmentScore);
          toast.success(res.data.message);
        } else {
          toast.error("Error in uploading the file");
        }
      })
      .catch((e) => {
        // console.log(e.response);
        toast.error(e.response.data.message);
      });
  };
  const onsearch = (props) => (event)=>{
    // console.log(" *** ",event.target.value)
    // console.log(" *** new data ** ",newData.value)
    setSearchFocus(true);setCommentFocus(false);setEmailFocus(false)
    setNewData({...newData,value:event.target.value})
  }

  const Search = (e) =>{
    // console.log("in search",e.target ,newData)
    setValueTab(3)
    let search = newData.value
    if (e.target !== undefined) {
      search = e.target.value
    }
    // console.log(filterValues)
    setNewData({...newData,value:search})
    let data = {}
    let compUrl = 'getCompetitionOfUser'
    let align ={};
    let align1={};
    if(alignmentScore == 'non-zero'){
      align = {'$gt' : ["$overAllScore",0]}
      align1 = {'$gt':0}
    }else if(alignmentScore == 'zero'){
      align = {'$gte': ["$overAllScore",0]}
      align1 = {'$gte':0}
    }
    // if(filterValues.category !="" && filterValues.gameType !="" && filterValues.gender !="" && filterValues.matchType !="" && filterValues.teamType !=""){
    //   compUrl = 'getCompetitionOfUser'
    //   data={
    //     competitionId: competitionId, 
    //     filterValues: filterValues,
    //     user: search
    //   }
    // }
    if(filterValues.category !="" || filterValues.gameType !="" || filterValues.gender !="" || filterValues.matchType !="" || filterValues.teamType !="" || filterValues.shooterCategory !=""){
      let hasValue= {};
      for (const key in filterValues){
        if(filterValues[key] != ""){          
          hasValue[key]={$regex: `^${filterValues[key]}$`,$options: "i"}
        }
      }
      compUrl = 'getCompetitionOfUser'
      data={
        competitionId: competitionId, 
        filterValues: hasValue,
        user: search,
        alignScore: align
      }
      // console.log(hasValue)
    }
    else{
      compUrl = 'getCompOfUserWithoutFilter'
      data={competitionId: competitionId, user: search,alignScore:align1}
    }

    console.log(compUrl, data)
    axios
      .post(`/api/compMatchMapper/${compUrl}`, data)
      .then((response) => {
        console.log("search *** ",response.data.data)
        const res = response.data.data
        if (response.data.data.length === 0){
          setCompetitionData([])
        }
        else{
          if(filterValues.category !="" && filterValues.gameType !="" && filterValues.gender !="" && filterValues.matchType !="" && filterValues.teamType !="" && filterValues.shooterCategory !=""){
            const sortedData = res[0].competition.sort((a, b) => b.overAllScore - a.overAllScore);
            setCompetitionData(sortedData);
          }
          else if(filterValues.category !="" || filterValues.gameType !="" || filterValues.gender !="" || filterValues.matchType !="" || filterValues.teamType !="" || filterValues.shooterCategory !=""){
            let newarr = []
            let uniqueArray= []
            res.map((row)=>{
              if(row.competition.length != 0)       
                newarr= newarr.concat(row.competition)
              })
            
            // newarr = newarr.filter((value, index, self) =>
            // index === self.findIndex((t) => (
            //   t.userName === value.userName 
            // )))
            let jsonObject = newarr.map(JSON.stringify);
            let uniqueSet = new Set(jsonObject);
            uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        
            const sortedData = uniqueArray.sort((a, b) => b.overAllScore - a.overAllScore);
            setCompetitionData(sortedData);
          }
          else{
            const sortedData = res.sort((a, b) => b.overAllScore - a.overAllScore);
            setCompetitionData(sortedData);
          }
        }
        setSearchFocus(false);setEmailFocus(false); setCommentFocus(false)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleChangeData = (props) => (event) => {
    // console.log(props, event.target.value)
    setValues({
      ...values,
      [props]: event.target.value
    });
  };

  const handleChangeSeriesData = (props) => (event) => {
    let seriesVal = values.seriesScore; 
    seriesVal.find(v => v.name === props).score = parseInt(event.target.value);
    setValues({
      ...values,
      seriesVal
    });
  };

  const clearFilter = () =>{
    setFilterValues({
      gender: '',
      gameType: '',
      teamType: '',
      matchType: '',
      category: '',
      shooterCategory: ''
    })
    setFilterPop(null);
    getAllCompData(alignmentScore);
  }

  const handleAdd = () => {
    // console.log(selectedData)
    axios
      .public-DraftStyleDefault-unorderedListItem(`/api/compMatchMapper/update`, {
        competitionId: competitionId,
        userName: values.name,
        overAllScore: values.overAllScore,
        noOf10: values.noOf10,
        noOf10x: values.noOf10x,
        penalty: values.penalty,
        rem: values.rem,
        // percentage: values.percentage,
      })
      .then((response) => {
        setisSubmitting(false)
        setOpen(false);
        setDataUpdate(false);
        getCompetitions();
        getAllCompData(alignmentScore);
        getUserDetail();
        setSelectedData({});
        setValues({
          name: '',
          overAllScore: 0,
          noOf10x: 0,
          noOf10: 0,
          percentage: 0,
          penalty: 0,
          rem: "C",
        })
      })
      .catch((error) => {
        setisSubmitting(false)
        toast.error(error.response.data);
      });
  }

  const handleUpdate = () => {  
    // console.log(selectedData)
      axios
        .put(`/api/compMatchMapper/update`, {
          userName: values.name,
          overAllScore: values.overAllScore,
          overAllScoreDecimal: values.overAllScoreDecimal,
          noOf10: values.noOf10,
          noOf10x: values.noOf10x,
          seriesScore:values.seriesScore,
          penalty: values.penalty,
          rem: values.rem,
          // percentage: values.percentage,
          _id: selectedData._id
        })
        .then((response) => {
          setisSubmitting(false)
          setOpen(false);
          setDataUpdate(false);
          // getCompetitions();
          getAllCompData(alignmentScore);
          getUserDetail();
          setSelectedData({});
          setValues({
            name: '',
            overAllScore: 0,
            overAllScoreDecimal: 0,
            noOf10x: 0,
            noOf10: 0,
            percentage: 0,
            seriesScore:[],
            penalty: 0,
            rem: "C",
          })
        })
        .catch((error) => {
          setisSubmitting(false)
          toast.error(error.response.data);
        });
  }

  const handleClick = (event) => {
    setFilterPop(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterPop(null);
  };

  const handleRadio = (e) =>{
    setFilterValues({...filterValues,
      [e.target.name]: e.target.value
    })
  }

  const handleRadioAlignment = (e) =>{
    
    setAlignmentScore(e.target.value);
    getAllCompData(e.target.value)
  }

  const handleChangeToggle = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  
  const handleChangeToggleScore = (event, newAlignment) => {
    setAlignmentScore(newAlignment);
    getAllCompData(newAlignment)
  };

  const filterOpen = Boolean(filterPop);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const AlwaysScrollToBottom = () => {
    window.scroll({
          top: Math.max(document.body.scrollHeight, document.body.offsetHeight, document.body.getBoundingClientRect().height, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight),
          left: 0, 
          behavior: 'smooth',
        });
  };
  const AlwaysScrollToTop = () => {
    window.scroll({
          top:0,
          left: 0, 
          behavior: 'smooth',
        });
  };

  const handleFeedbackData = (props) => (event)=>{
    // console.log(props,typeof event.target.value)
    if(props == 'comment'){
      setSearchFocus(false);setCommentFocus(true);setEmailFocus(false);
    }else {
      setSearchFocus(false);setEmailFocus(true); setCommentFocus(false)
    }
    setFeedback({
      ...feedback,
      [props]: event.target.value
    });
  }
  const handleProtestData = (props) => (event)=>{
    // console.log(props,typeof event.target.value)
    if(props == 'comment'){
      setSearchFocus(false);setCommentFocus(true);setEmailFocus(false);
    }else {
      setSearchFocus(false);setEmailFocus(true); setCommentFocus(false)
    }
    setProtest({
      ...protest,
      [props]: event.target.value
    });
  }

  const handleUpdateFeedback = () => {  
    if(feedback.comment == ""){
      toast.error("Fill comment before submitting!!");
    }else{
      axios
        .put(`/api/competition/updateFeedback`, {
          feedback: {"feedback":[{rating:rating,comment:feedback.comment,email:feedback.commentEmail}]},
          _id: competitionId
        })
        .then((response) => {
          setisSubmitting(false)
          setfeedBackModel(false)
          setProtestModel(false)
          getCompetitionDetail()
          setRating(5);
          setFeedback({comment:"",commentEmail:""});
          setSearchFocus(false);
          setCommentFocus(false);
          setEmailFocus(false);
        })
        .catch((error) => {
          setisSubmitting(false)
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

  const handleUpdateProtest = () => {  
    // console.log(protest)
    if(protest.comment == "" || protest.shooterName == ''){
      toast.error("Fill the details before submitting!!");
    }else{
      axios
        .put(`/api/competition/updateProtest`, {
          protest: {"protest":[{comment:protest.comment,shooterName:protest.shooterName,timeStamp:new Date(Date.now())}]},
          _id: competitionId
        })
        .then((response) => {
          setisSubmitting(false)
          setProtestModel(false)
          setProtestModel(false)
          getCompetitionDetail()
          setProtest({comment:"",shooterName:""});
          setSearchFocus(false);
          setCommentFocus(false);
          setEmailFocus(false);
        })
        .catch((error) => {
          setisSubmitting(false)
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

  const card = (data) => {    
    return (
          <div>
          {/* <Scrollbar style={{ height: "61vh", width: "100%", paddingRight: "1rem" }} >  */}
          {data.length >0 ? data.map((item,index) => {
            return (
              <Box sx={{mt:2}}>
              <Card sx={{cursor:"pointer" }} onClick={() =>{
                if(userRole.includes('coach')) {
                  setDataUpdate(true);
                  setSelectedData(item);
                  setValues({
                    name: item.userName,
                    overAllScore: item.overAllScore,
                    overAllScoreDecimal: item.overAllScoreDecimal,
                    noOf10x: item.noOf10x,
                    noOf10: item.noOf10,
                    percentage: item.percentage,
                    seriesScore:item.seriesScore,
                    penalty:item.penalty,
                    rem:item.rem
                  })``
                }
              }}> 
              <CardContent>
                <Stack direction={{md:"row",sm:"row",xs:'row'}}>
                <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
                <Grid item xs={1} sm={1} md={1} >
                <Typography variant="body1" align="center" sx={{fontSize:"0.675rem"}}>
                  {index+1}
                </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3} >
                <Typography variant="body1" align="center" sx={{wordBreak:{sm:"break-all",xs:"break-all",md:"normal"},fontSize:"0.675rem"}}>
                  {item.userName} 
                </Typography>
                <Typography variant="caption" align="center"  display="block" >
                  {item.academy} 
                </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} >
                <Typography variant="body1" align="center" sx={{fontSize:"0.675rem"}}>
                  {item.customEventsId!=undefined?item.customEventsId.join(", "):""}
                </Typography>
                <Typography variant="caption" align="center" display="block" sx={{fontSize:"0.675rem"}}>
                  Events
                </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3} >
                <Typography variant="body1" align="center" sx={{fontSize:"0.675rem"}}>
                  {item.overAllScore} ({item.overAllScoreDecimal != undefined ? item.overAllScoreDecimal:item.overAllScore})
                </Typography>
                <Typography variant="caption" align="center" display="block" sx={{fontSize:"0.675rem"}}>
                 {item.seriesScore != undefined ? item.seriesScore.map((item1)=>{return item1.score !=undefined?item1.score:""}).join(" "): ""}
                </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} >
                <Typography variant="body1" align="center" sx={{fontSize:"0.675rem"}}>
                  {item.penalty != undefined ? item.penalty:0}
                </Typography>
                <Typography variant="caption" align="center"  display="block" sx={{wordBreak:{sm:"break-all",xs:"break-all",md:"normal"},fontSize:"0.675rem"}}>
                 Penalty
                </Typography>                
                </Grid>
                <Grid item xs={1} sm={1} md={1} >
                <Typography variant="body1" align="center" sx={{fontSize:"0.675rem"}}>
                  {item.noOf10x}
                </Typography>
                <Typography variant="caption" align="center" display="block" sx={{fontSize:"0.675rem"}}>
                 # of 10x
                </Typography>                
                </Grid>
                <Grid item xs={1} sm={1} md={1} >
                <Typography variant="body1" align="center" sx={{fontSize:"0.675rem"}}>
                  {item.rem != undefined ?item.rem:'C'}
                </Typography>
                <Typography variant="caption" align="center" display="block" sx={{fontSize:"0.675rem"}}>
                 REM
                </Typography>                
                </Grid>
                </Grid>
                </Stack>
              </CardContent>
            </Card>
          </Box>
            )
          }):<p>No Competition Board</p>}
          {/* </Scrollbar> */}
          </div>
    )}

    const table = (data) => {    
      return (
            <div>
            {/* <Scrollbar smoothScrolling={true} style={{ height: window.location.href.indexOf("public") > -1?"55vh":"60vh", width: "100%", paddingRight: "1rem"}} >  */}
            {data.length >0 ? 
            <div>
            <TableContainer component={Paper}  sx={{}}>
              <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
                <TableHead stickyHeader>
                  <TableRow >
                    <TableCell align="left" sx={{fontSize:"0.675rem"}}>
                    {filterValues.category !="" || filterValues.gameType !="" || filterValues.gender !="" || filterValues.matchType !="" || filterValues.teamType !="" || filterValues.shooterCategory !=""?"Rank":"SNo"}</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}}>Name</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} size="small">Academy</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} size="small">Events</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} >1</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} >2</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} >3</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} >4</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} >5</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}} >6</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}}>Penalty</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}}>Score</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}}>Decimal</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}}>10x</TableCell>
                    <TableCell align="left" sx={{fontSize:"0.675rem"}}>REM</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row,index) => {
                    return (
                    <TableRow tabIndex={-1} key={row._id} sx={{ "&:hover": { cursor: "pointer" } }}
                      onClick={() => {if(userRole.includes('coach')) {
                        setDataUpdate(true);
                        setSelectedData(row);
                        setValues({
                          name: row.userName,
                          overAllScore: row.overAllScore,
                          overAllScoreDecimal: row.overAllScoreDecimal,
                          noOf10x: row.noOf10x,
                          noOf10: row.noOf10,
                          percentage: row.percentage,
                          seriesScore:row.seriesScore,
                          penalty:row.penalty,
                          rem:row.rem
                        })
                      }}}>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}}>{filterValues.category !=""?index+1:data.length-index}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}}>{row.userName}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} size="small">{row.academy}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} size="small">{row.customEventsId!=undefined?row.customEventsId.join(", "):""}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.seriesScore != undefined ? row.seriesScore[0] !=undefined && row.seriesScore[0].score !=undefined?row.seriesScore[0].score:"": ""}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.seriesScore != undefined ? row.seriesScore[1] !=undefined && row.seriesScore[1].score !=undefined?row.seriesScore[1].score:"": ""}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.seriesScore != undefined ? row.seriesScore[2] !=undefined && row.seriesScore[2].score !=undefined?row.seriesScore[2].score:"": ""}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.seriesScore != undefined ? row.seriesScore[3] !=undefined && row.seriesScore[3].score !=undefined?row.seriesScore[3].score:"": ""}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.seriesScore != undefined ? row.seriesScore[4] !=undefined && row.seriesScore[4] !=undefined?row.seriesScore[4].score:"": ""}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.seriesScore != undefined ? row.seriesScore[5] !=undefined && row.seriesScore[5].score !=undefined?row.seriesScore[5].score:"": ""}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}}>{row.penalty != undefined ?row.penalty:0}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}}>{row.overAllScore}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}}>{row.overAllScoreDecimal != undefined ? row.overAllScoreDecimal:row.overAllScore}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}}>{row.noOf10x}</TableCell>
                      <TableCell align="left" sx={{fontSize:"0.675rem"}}>{row.rem != undefined ?row.rem:"C"}</TableCell>
                    </TableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          :<p>No Competition Board</p>}
          {/* </Scrollbar> */}
          </div>
      )}

  const input = () =>{
    
    return (
      <Scrollbar style={{ height: "61vh", width: "100%", paddingRight: "1rem" }} > 
    <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
      
      <TextField
        id = "outlined-required"
        defaultValue = {values.name}
        value={values.name}
        varient="standard"
        label="Name"
        type = 'text'
        sx={{mt:2}}
        disabled={dataUpdate === true ?true:false}
        onChange={handleChangeData('name')}
      />
      <TextField
        required
        id = "outlined-required"
        label = "Over All Score"
        defaultValue = {values.overAllScore}
        value={values.overAllScore}
        varient="standard"
        type = 'number'
        sx={{mt:2}}
        onChange={handleChangeData('overAllScore')}
      />
      <TextField
        required
        id = "outlined-required"
        label = "Over All Score Decimal"
        defaultValue = {values.overAllScoreDecimal == 0 || values.overAllScoreDecimal ==undefined ?values.overAllScore:values.overAllScoreDecimal}
        value={values.overAllScoreDecimal == 0 || values.overAllScoreDecimal ==undefined ?values.overAllScore:values.overAllScoreDecimal}
        varient="standard"
        type = 'number'
        sx={{mt:2}}
        onChange={handleChangeData('overAllScoreDecimal')}
      />
      <TextField
        required
        id = "outlined-required"
        label = "No of 10x"
        defaultValue = {values.noOf10x}
        value={values.noOf10x}
        varient="standard"
        type = 'number'
        sx={{mt:2}}
        onChange={handleChangeData('noOf10x')}
      />
      <TextField
        required
        id = "outlined-required"
        label = "No of 10"
        defaultValue = {values.noOf10}
        value={values.noOf10}
        varient="standard"
        type = 'number'
        sx={{mt:2}}
        onChange={handleChangeData('noOf10')}
      />
      {
        values.seriesScore != undefined && values.seriesScore.map((item,index)=>{
          return (
            item.score !=undefined && item.score != ""?
            <TextField
            required
            id = "outlined-required"
            label = {item.name}
            defaultValue = {item.score}
            value={item.score}
            varient="standard"
            type = 'number'
            sx={{mt:2}}
            onChange={handleChangeSeriesData(item.name)}
          /> :null
          )
        })
      }

      <TextField
        required
        id = "outlined-required"
        label = "Penalty"
        defaultValue = {values.penalty}
        value={values.penalty}
        varient="standard"
        type = 'number'
        sx={{mt:2}}
        onChange={handleChangeData('penalty')}
      />
      <TextField
        required
        id = "outlined-required"
        label = "REM"
        defaultValue = {values.rem}
        value={values.rem}
        varient="standard"
        type = 'string'
        sx={{mt:2}}
        onChange={handleChangeData('rem')}
      />
      {/* <TextField
        required
        id = "outlined-required"
        label = "Percentage"
        defaultValue = {values.percentage}
        value={values.percentage}
        varient="standard"
        type = 'number'
        sx={{mt:2}}
        onChange={handleChangeData('percentage')}
      /> */}
    </Stack>
    </Scrollbar>
    )}

    const filterIput = () => {
      
      return (<div>
        <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Game Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gameType"
              value={filterValues.gameType}
              onChange={handleRadio}
            >
              {
                particularCompData.gameType != undefined && particularCompData.gameType.map((item) => {
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>
        </Box>
        <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Category</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="category"
              value={filterValues.category}
              onChange={handleRadio}
            >
              {
                particularCompData.category != undefined && particularCompData.category.map((item) => {
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>
        </Box>
         <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Team Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="teamType"
              value={filterValues.teamType}
              onChange={handleRadio}
            >
              {
                particularCompData.teamType != undefined && particularCompData.teamType.map((item) => {
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>
        </Box>
        <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Match Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="matchType"
              value={filterValues.matchType}
              onChange={handleRadio}
            >
              {
                particularCompData.matchType != undefined && particularCompData.matchType.map((item) => {
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>
        </Box>

         <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gender"
              value={filterValues.gender}
              onChange={handleRadio}
            >
              {
                particularCompData.gender != undefined && particularCompData.gender.map((item)=>{
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>               
        </Box>

        <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Shooter Category</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="shooterCategory"
              value={filterValues.shooterCategory}
              onChange={handleRadio}
            >
              {
                particularCompData.shooterCategory != undefined && particularCompData.shooterCategory.map((item)=>{
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>               
        </Box>

        <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Shooter status</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="score"
              value={alignmentScore}
              onChange={handleRadioAlignment}
            >
                 <FormControlLabel value="zero" control={<Radio />} label="All"/>
                 <FormControlLabel value="non-zero" control={<Radio />} label="Completed"/>
            </RadioGroup>
          </FormControl>               
        </Box>
        </div>
      )
    }

  function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const display =()=>{
    return(
      <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>  
      <Stack direction="row" justifyContent="space-between">
      <Typography sx={{ }} variant="h4" align="center">
        Competition Info 
      </Typography>
      <Button sx={{color:"black"}} onClick={()=>{setcompetitionInfo(false)}} startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />} />
      </Stack>

      <Table>
        <TableBody>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Name</Typography></TableCell>
            <TableCell align="left">{particularCompData.name}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Competition From</Typography></TableCell>
            <TableCell align="left">{particularCompData.competitionFrom !=undefined ?particularCompData.competitionFrom.split("T")[0]:"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Competition To</Typography></TableCell>
            <TableCell align="left">{particularCompData.competitionTo !=undefined ?particularCompData.competitionTo.split("T")[0] :"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Registration Starts</Typography></TableCell>
            <TableCell align="left">{particularCompData.registrationStarts != undefined ?particularCompData.registrationStarts.split("T")[0] :"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Registration Ends</Typography></TableCell>
            <TableCell align="left">{particularCompData.registrationEnds != undefined ?particularCompData.registrationEnds.split("T")[0] :"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Triple Fees Date</Typography></TableCell>
            <TableCell align="left">{particularCompData.cccRegistration != undefined ?particularCompData.cccRegistration.split("T")[0] :"-"}</TableCell>
          </TableRow>
          {console.log("particularCompData ",particularCompData)}
          {/* <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Category</Typography></TableCell>
            <TableCell align="left">{particularCompData.category.join(', ')}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Game Type </Typography></TableCell>
            <TableCell align="left">{particularCompData.gameType.join(', ')}</TableCell>
          </TableRow>      
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Gender</Typography></TableCell>
            <TableCell align="left">{particularCompData.gender.join(', ')}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Competition Level </Typography></TableCell>
            <TableCell align="left">{particularCompData.level}</TableCell>
          </TableRow>      
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Match Type</Typography></TableCell>
            <TableCell align="left">{particularCompData.matchType.join(', ')}</TableCell>
          </TableRow>  
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Team type</Typography></TableCell>
            <TableCell align="left">{particularCompData.teamType.join(', ')}</TableCell>
          </TableRow>   */}
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Location</Typography></TableCell>
            <TableCell align="left">{particularCompData.location}</TableCell>
          </TableRow>  
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Status</Typography></TableCell>
            <TableCell align="left">{particularCompData.status}</TableCell>
          </TableRow>  
        </TableBody>
      </Table>  
      </Stack>
    )}

  const addIndexPage = (doc) => {
    // doc.addImage(pdfheader, "PNG", doc.internal.pageSize.width/2 - 60, 250);
    doc.addImage("/static/pdfheader.png", "PNG", 0, 0, doc.internal.pageSize.width, 120);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(23);
    doc.setTextColor(1, 49, 53);
    let text = particularCompData.name;
    doc.addImage(
      "/static/ishoot.png",
      "PNG",
      doc.internal.pageSize.width / 2 - 40,
      300, 60, 60
    );
    const maxWidth = 320; // Maximum width of the text block
    const lineHeight = 10; // Line height for the wrapped text
    const textLines = doc.splitTextToSize(text, maxWidth);
    const textHeight = textLines.length * lineHeight;
  
    const textStartY = (doc.internal.pageSize.getHeight() - textHeight) / 2; // Center align vertically
  
  
    doc.text(
      textLines, doc.internal.pageSize.getWidth() / 2, textStartY, {
        align: 'center',
      }
    );
    doc.setFontSize(14)
    doc.text(
      filterValues.category !="" && filterValues.gameType !="" && filterValues.gender !="" && filterValues.matchType !="" && filterValues.teamType !=""? `${filterValues.gender} ${filterValues.category} ${filterValues.gameType} ${filterValues.matchType} ${filterValues.teamType} Board`: "", doc.internal.pageSize.getWidth() / 2, textStartY+65, {
        align: 'center',
      }
    );

    let today = new Date();
    let date = new Date();
    let from = date.setDate(date.getDate() - values?.date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const yyyyFrom = date.getFullYear();
    let mmFrom = date.getMonth() + 1; // Months start at 0!
    let ddFrom = date.getDate();

    if (ddFrom < 10) ddFrom = "0" + ddFrom;
    if (mmFrom < 10) mmFrom = "0" + mmFrom;


    today = dd + "/" + mm + "/" + yyyy;
    from = ddFrom + "/" + mmFrom + "/" + yyyyFrom;

    doc.setFont("helvetica");
    doc.setFontSize(14);
  };

  const addHeaders = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.setTextColor(1, 49, 53);
    for (var i = 2; i <= pageCount; i++) {
      doc.setPage(i);

      doc.addImage("/static/ishoot.png", "PNG", 18, 14, 30, 30);
      doc.text(
        filterValues.category !="" && filterValues.gameType !="" && filterValues.gender !="" && filterValues.matchType !="" && filterValues.teamType !=""? `${filterValues.gender} ${filterValues.category} ${filterValues.gameType} ${filterValues.matchType} ${filterValues.teamType} Board`: particularCompData.name,
        doc.internal.pageSize.width / 2 - 120,
        35
      );
    }
  };

  const addFooters = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont("helvetica", "italic");
    doc.setFontSize(9);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      doc.setFillColor(1, 49, 53);
      doc.rect(0, 800, doc.internal.pageSize.width, 120, "F");
      doc.setTextColor(255, 255, 255);
      doc.text(
        "For more info, Pls reach out to support@intellishoot.com",
        10,
        823
      );
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        doc.internal.pageSize.width - 60,
        823
      );
    }
  };
    const generateReport = (e) => {
      setreportLoader(true);
      const pdf = new jsPDF("5", "pt");
      pdf.setFont("Roboto");
      addIndexPage(pdf);
      pdf.addPage();
      let arr = [];
      competitionData.map((row, index) => {
        let record = [];
        record.push(index+1);
        record.push(row.userName);
        record.push(row.academy);
        record.push(row.customEventsId != undefined ? row.customEventsId.join(", ") : "");
        record.push(row.seriesScore != undefined
          ? row.seriesScore[0] != undefined && row.seriesScore[0].score != undefined
            ? row.seriesScore[0].score
            : ""
          : "");
        record.push(row.seriesScore != undefined
          ? row.seriesScore[1] != undefined && row.seriesScore[1].score != undefined
            ? row.seriesScore[1].score
            : ""
          : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[2] != undefined && row.seriesScore[2].score != undefined
              ? row.seriesScore[2].score
              : ""
            : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[3] != undefined && row.seriesScore[3].score != undefined
              ? row.seriesScore[3].score
              : ""
            : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[4] != undefined && row.seriesScore[4] != undefined
              ? row.seriesScore[4].score
              : ""
            : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[5] != undefined && row.seriesScore[5].score != undefined
              ? row.seriesScore[5].score
              : ""
            : "");
            record.push(row.penalty != undefined ? row.penalty : 0)
            record.push(row.overAllScore);
            record.push(row.overAllScoreDecimal != undefined
              ? row.overAllScoreDecimal
              : row.overAllScore);
            record.push(row.noOf10x);
            record.push(row.rem != undefined ? row.rem : "C");
            arr.push(record)
      })
      pdf.setFontSize(6);
      const tableStyles = {
        fontSize: 6, // Adjust the font size as per your requirements
      };
      pdf.autoTable({
        head: [["SNo","Name","Academy","Events","1","2","3","4","5","6","Penalty","Score","Decimal","10x","REM"]],
        body: arr,
        margin: { top: 100 },
        columnStyles: { 0: { columnWidth: 35 }, 1: { columnWidth: 80}, 2: { columnWidth: 80}, 3: { columnWidth: 30}, 14: { columnWidth: 40}},
        styles: tableStyles
      })
      // pdf.autoTable(["SNo","Name","Academy","Events","1","2","3","4","5","6","Penalty","Score","Decimal","10x","REM"], arr, { startY: 480 });
      pdf.setFontSize(12);
      addFooters(pdf);
      addHeaders(pdf);
      pdf.save("results.pdf");
      
    }


  const convertToCSV = (data) => {
    const headers = ["SNo","Name","Academy","Events","1","2","3","4","5","6","Penalty","Score","Decimal","10x","REM"].join(',');
    const rows = data.map((row, index) => {
      // Object.values(obj).join(',')
      let record = [];
        record.push(index+1);
        record.push(row.userName);
        record.push(row.academy);
        record.push(row.customEventsId != undefined ? row.customEventsId.join(" ") : "");
        record.push(row.seriesScore != undefined
          ? row.seriesScore[0] != undefined && row.seriesScore[0].score != undefined
            ? row.seriesScore[0].score
            : ""
          : "");
        record.push(row.seriesScore != undefined
          ? row.seriesScore[1] != undefined && row.seriesScore[1].score != undefined
            ? row.seriesScore[1].score
            : ""
          : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[2] != undefined && row.seriesScore[2].score != undefined
              ? row.seriesScore[2].score
              : ""
            : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[3] != undefined && row.seriesScore[3].score != undefined
              ? row.seriesScore[3].score
              : ""
            : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[4] != undefined && row.seriesScore[4] != undefined
              ? row.seriesScore[4].score
              : ""
            : "");
          record.push(row.seriesScore != undefined
            ? row.seriesScore[5] != undefined && row.seriesScore[5].score != undefined
              ? row.seriesScore[5].score
              : ""
            : "");
            record.push(row.penalty != undefined ? row.penalty : 0)
            record.push(row.overAllScore);
            record.push(row.overAllScoreDecimal != undefined
              ? row.overAllScoreDecimal
              : row.overAllScore);
            record.push(row.noOf10x);
            record.push(row.rem != undefined ? row.rem : "C");
            return record.join(",");
    });
    return `${headers}\n${rows.join('\n')}`;
  }
  const handleDownloadCsv = () => {
    console.log("Downloading csv data");
    const csvData = convertToCSV(competitionData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    let fileName = filterValues.category !="" && filterValues.gameType !="" && filterValues.gender !="" && filterValues.matchType !="" && filterValues.teamType !=""? `${filterValues.gender} ${filterValues.category} ${filterValues.gameType} ${filterValues.matchType} ${filterValues.teamType} Board`: particularCompData.name;
    link.download = `${fileName.replace(" ", "_")}.csv`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownloadConsCsv = () => {
    setconsreportLoader(true);
    var config = {
      method: 'get',
      url: `/api/compMatchMapper/consolidate/report/${competitionId}`,
    };
    axios(config)
    .then(function (response) {
      console.log(response);
      console.log(response.data);
      setconsreportLoader(false);
      window.open(`/${response.data.filename}`, "_blank")
    })
    .catch(function (error) {
      setconsreportLoader(false);
      console.log(error);
    });
    

  }

  const tallyTable =(data) =>{
   return(<TableContainer component={Paper}  sx={{overflowX: 'auto',mt:3}}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
            <TableHead>
            <TableRow>
            <TableCell align="center" sx={{fontSize:"0.975rem"}} colSpan={6}>
            {data.cateName}
            </TableCell>
            </TableRow>
              <TableRow tabIndex={-1} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Rank</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Academy</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Gold</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Silver</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Bronze</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Points</TableCell>
                </TableRow>
            </TableHead>
            {data.data.map((row,index)=>{
              return (               
                <TableRow tabIndex={-1} key={index} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{index+1}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{data.name[index]}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{data.gold[index]}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{data.silver[index]}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{data.bronze[index]}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row}</TableCell>
                </TableRow>
              )
            })}
            </Table>
            </TableContainer>
   )
  }

  return (
    <Page title="Competition Board">
      <Toast />
      <Container>        
        <Stack direction={{md:"row",xs:'column',sm:'column'}} alignItems="center" justifyContent="space-between" mb={1}>
          <Stack direction={{md:"column",xs:'column',sm:'column'}} alignItems="center" justifyContent="space-between" mb={1}>
          {/* <Typography variant="h3" >
          <span>{particularCompData.name}</span>
          </Typography> 
          <Typography variant="h3" gutterBottom>
          <span>{particularCompData.displayName}</span>
          </Typography> */}
          {window.location.href.indexOf("public") > -1?
            <Typography variant="h6" gutterBottom sx={{color:""}}>
              <a href="https://intellishoot.com/" target="_blank">Click here to track your scores @intellishoot</a>
            </Typography>:null}
          {filterValues.category !="" || filterValues.gameType !="" || filterValues.gender !="" || filterValues.matchType !="" || filterValues.teamType !=""?
          <Typography variant="h6" gutterBottom>
            <span>{filterValues.gender+" "}</span>
            <span>{filterValues.category}</span>
            <span>{" "+filterValues.gameType+" "}</span>
            <span>{filterValues.matchType+" "+filterValues.teamType+" Board"}</span>
          </Typography> :null
          }
          </Stack>
          
          <Stack direction={{md:"row",xs:'row',sm:'row'}} alignItems="center" justifyContent="flex-end" mb={1}>
          <div> 
          <Stack direction="row">
          {window.location.href.indexOf("public") > -1?null:                    
          <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
              aria-label="Platform"
            >
              <ToggleButton value="table">Table</ToggleButton>
              <ToggleButton value="card">Card</ToggleButton>
            </ToggleButtonGroup> }
            <IconButton aria-label="print" >
            <Iconify
                icon = "flat-color-icons:print"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={32}
                height={28}
                onClick={() => {window.print()}}
            /></IconButton>
            <IconButton aria-label="download" >  
            <Iconify
                icon = "material-symbols:download"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={28}
                height={28}
                onClick={(e) => generateReport(e)}
            /></IconButton>
            <IconButton aria-label="excel" >  
            {!consreportLoader ? <Iconify
                icon = "mdi:microsoft-excel"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={28}
                height={28}
                onClick={(e) => handleDownloadConsCsv()}
            />:<Iconify icon = "eos-icons:loading" style={{ height: "30px"}} sx={{width:"100%"}} />}
            </IconButton>
            {window.location.href.indexOf("public") > -1?<Stack direction="row">
            <Button variant ="outlined" onClick={handleClick}>Match Filter</Button>
            <Button variant ="outlined" sx={{marginLeft:'1%'}} onClick={()=>{setfeedBackModel(true)}}>Feedback</Button>
            <Button variant ="outlined" sx={{marginLeft:'1%'}} onClick={()=>{setProtestModel(true)}}>Protest</Button>
            </Stack>
            :
            <Stack direction="row">
            <IconButton aria-label="delete" onClick={handleClick}>
              <Iconify
                icon = "cil:filter"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={28}
                height={28}
              />
            </IconButton>
            <IconButton aria-label="feedback" onClick={()=>{setfeedBackModel(true)}}>
              <Iconify
                icon = "fluent:person-feedback-16-regular"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={28}
                height={28}
              />
            </IconButton>
            <IconButton aria-label="feedback" onClick={()=>{setProtestModel(true)}}>
              <Iconify
                icon = "material-symbols:person-raised-hand-outline"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={28}
                height={28}
              />
            </IconButton>
            </Stack>}
            
             
            <Popover
              open={filterOpen}
              anchorEl={filterPop}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {filterIput()} 
              < Button variant = "contained"
              sx = {{marginLeft: '20px',marginBottom: '20px'}}
              onClick = {
                () => {
                  Search(newData.value);
                  setFilterPop(null);
                }
              } >
                Apply
              </Button>   
              < Button variant = "contained"
              sx = {{marginLeft: '20px',marginBottom: '20px'}}
              onClick = {
                () => {
                  clearFilter()
                }
              } >
                Clear
              </Button>    
            </Popover>
            </Stack>          
          </div>
          <div>
            {userRole.includes('coach') ?<div>
            <Iconify
                icon = "entypo:upload"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={32}
                height={28}
                onClick={() => {setOpen(true)}}
            />
            </div>:null}            
          </div>
          </Stack>
        </Stack> 
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>                           
            <Tabs
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            // sx={{ borderRight: 1, borderColor: 'divider',width:{xs:250,sm:100,md:100} }}
            >
              {/* <Tab wrapped label="Info" {...a11yProps(0)} /> */}
              <Tab wrapped label="Dashboard" {...a11yProps(0)} />
              <Tab wrapped label="Medals Tally" {...a11yProps(1)} />
              <Tab wrapped label="Schedule" {...a11yProps(2)} />
              <Tab wrapped label="Final Ranking" {...a11yProps(3)} />
              <Tab wrapped label="Detail wise" {...a11yProps(4)} />
              <Tab wrapped label="Finals" {...a11yProps(5)} />
              <Tab wrapped label="Live score card" {...a11yProps(6)} />
              {userRole.includes('superAdmin') || (userDetails.email !=undefined && userDetails.email == particularCompData.createdBy)?
              <Tab wrapped label="Feedback" {...a11yProps(7)} />:null}
              {userRole.includes('superAdmin')?<Tab wrapped label="Match Group" {...a11yProps(8)} />:null}
              {userRole.includes('superAdmin') || (userDetails.email !=undefined && userDetails.email == particularCompData.createdBy)?
              <Tab wrapped label="Protest" {...a11yProps(9)} />:null}
            </Tabs> 
          </Box>
          {/* <TabPanel value={valueTab} index={0}>
            {display()}
          </TabPanel> */}

          <TabPanel value={valueTab} index={0}>   
           {topAcademy.data.length>0?<div>
            <TableContainer component={Paper}  sx={{overflowX: 'auto'}}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
            <TableHead>
              <TableRow tabIndex={-1} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Rank</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Academy</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Shooters</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >1st</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >2nd</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >3rd</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Points</TableCell>
                </TableRow>
            </TableHead>
            {sortTableData.map((row,index)=>{
              return (
                <TableRow tabIndex={-1} key={index} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{index+1}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.academy}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.count}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.gold}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.silver}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.bronze}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.points}</TableCell>
                </TableRow>
              )
            })}
            </Table>
            </TableContainer>
            <Typography variant="caption" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              {/* * Gold - {ACADEMY_POINT_SEQUENCE[0]} point , Silver - {ACADEMY_POINT_SEQUENCE[1]} points & Bronze - {ACADEMY_POINT_SEQUENCE[2]} points */}
              *The above scores are based on the shooter have a completed their details , as new shooters complete the positions will be updated
            </Typography>

            {/* <BarChart Details={topAcademy} chartTitle="OverAll Rank" graphName='Points'/>
            {academyCateWise.length>0?
            academyCateWise.map((item,index)=>{
              return <BarChart Details={item} chartTitle={item.cateName.replaceAll("_"," ")} graphName='Points'/>
            })
            :null} */}
            </div>:
            <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              No Data
            </Typography>}
          </TabPanel>

          <TabPanel value={valueTab} index={1}>   
           {compeletedMatch.data.length>0?<div>
            <TableContainer component={Paper}  sx={{overflowX: 'auto'}}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
            <TableHead>
              <TableRow tabIndex={-1} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Rank</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Academy</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Shooters</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Gold</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Silver</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Bronze</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Points</TableCell>
                </TableRow>
            </TableHead>
            {compeletedMatchTable.map((row,index)=>{
              return (
                <TableRow tabIndex={-1} key={index} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{index+1}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.academy}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.count}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.gold}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.silver}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.bronze}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.points}</TableCell>
                </TableRow>
              )
            })}
            </Table>
            </TableContainer>
            <Typography variant="caption" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              * Gold - {ACADEMY_POINT_SEQUENCE[0]} point , Silver - {ACADEMY_POINT_SEQUENCE[1]} points & Bronze - {ACADEMY_POINT_SEQUENCE[2]} points
              {/* *The above scores are based on the shooter have a completed their details , as new shooters complete the positions will be updated */}
            </Typography>
            
            {/* <BarChart Details={compeletedMatch} chartTitle="OverAll Rank" graphName='Points'/> */}
            {/* {compeletedMatchCateWise.length>0?
            compeletedMatchCateWise.map((item,index)=>{
              return <BarChart Details={item} chartTitle={item.cateName.replaceAll("_"," ")} graphName='Points'/>
            })
            :null} */}
           
            {compeletedMatchCateWise.length>0?
            compeletedMatchCateWise.map((item,index)=>{
              // return <BarChart Details={item} chartTitle={item.cateName.replaceAll("_"," ")} graphName='Points'/>
              return tallyTable(item)
            })
            :null}
            </div>:
            <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              No Data
            </Typography>}
          </TabPanel>     

          <TabPanel value={valueTab} index={2}>
            {particularCompExcel[4]!= undefined && particularCompExcel[4].data.length>0?          
              <TableContainer component={Paper}  sx={{}}>
              <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
              {particularCompExcel[4].data.map((row,index)=>{
                return (
                  index == 0 ?
                  <TableHead>
                    <TableRow tabIndex={-1} key={index} >
                    {row.map((item1,index1)=>{
                      return <TableCell align="left" sx={{fontSize:"0.675rem"}} >{item1}</TableCell>
                    })}
                  </TableRow>
                  </TableHead>
                  :
                  <TableRow tabIndex={-1} key={index} >
                    {row.map((item1,index1)=>{
                      return <TableCell align="left" sx={{fontSize:"0.675rem"}} >{item1}</TableCell>
                    })}
                  </TableRow>
                )
              })}
              </Table>
              </TableContainer>
              :
              <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              Please contact competition admin for summary details
            </Typography> }
          </TabPanel>

          <TabPanel value={valueTab} index={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: {xs:350,sm:500,md:700},ml:{xs:2,sm:7,md:8} }}
            >
            {/*<InputBase
            fullWidth={true}
            sx={{ ml:2, flex: 1 }}
            value={newData}
            autoFocus={searchFocus}
            placeholder="Search by name"
            onChange = {
              (e) => { //console.log('onChange ',e);
                // Search(e);
              }
            }
          />
           <Iconify icon="ant-design:search-outlined"/> */}
          <TextField
             id="filled-multiline-static"
             label="search by name"
              value={newData.value}
              fullWidth
              size="small"
              autoFocus={searchFocus}
              onChange={onsearch()}
              // onClick={()=>{setSearchFocus(true);setCommentFocus(false);setEmailFocus(false)}}
            />
          <Button onClick = {(e) => { Search(newData);} }>Search</Button>
          </Paper>
          {/* <ToggleButtonGroup
              color="primary"
              value={alignmentScore}
              exclusive
              onChange={handleChangeToggleScore}
              aria-label="Platform"
            >
              <ToggleButton value="zero">All</ToggleButton>
              <ToggleButton value="non-zero">Completed</ToggleButton>
            </ToggleButtonGroup> */}
          <Iconify
                icon = "iconoir:fast-arrow-bottom"
                sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                width={32}
                height={28}
                onClick={() => {AlwaysScrollToBottom()}}
            />
          </Stack>
            
            {competitionData.length === 0 ? 
              <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'12%'},marginLeft:{sm:'20%',md:'29%'}}}>
                <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No data</Typography> 
                <img src="/static/illustrations/empty_bin.png" alt="No Details"  style={{width:'300px'}}/>
              </Box>
              :
                alignment === 'table'? table(competitionData):card(competitionData)         
            }  
                  
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <div></div>
              <Iconify
                  icon = "iconoir:fast-arrow-top"
                  sx={{ '&:hover': { cursor: 'pointer', color: 'blue' }}}
                  width={32}
                  height={28}
                  onClick={() => {AlwaysScrollToTop()}}
              />
            </Stack>           
          </TabPanel>

          <TabPanel value={valueTab} index={4}>
          {particularCompExcel[2]!= undefined && particularCompExcel[2].data.length>0?          
            <TableContainer component={Paper}  sx={{overflowX: 'auto'}}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
            {particularCompExcel[2].data.map((row,index)=>{
              return (
                index == 0 ?
                <TableHead>
                  <TableRow tabIndex={-1} key={index} >
                  {row.map((item1,index1)=>{
                    return <TableCell align="left" sx={{fontSize:"0.675rem"}} >{item1}</TableCell>
                  })}
                </TableRow>
                </TableHead>
                :
                <TableRow tabIndex={-1} key={index} >
                  {row.map((item1,index1)=>{
                    return <TableCell align="left" sx={{fontSize:"0.675rem"}} >{item1}</TableCell>
                  })}
                </TableRow>
              )
            })}
            </Table>
            </TableContainer>
            :
            <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
             Please contact competition admin for schedule details
           </Typography> }
          </TabPanel>

          <TabPanel value={valueTab} index={5}>
            {particularCompExcel[3]!= undefined && particularCompExcel[3].data.length>0?          
              <TableContainer component={Paper}  sx={{}}>
              <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
              {particularCompExcel[3].data.map((row,index)=>{
                return (
                  index == 0 ?
                  <TableHead>
                    <TableRow tabIndex={-1} key={index} >
                    {row.map((item1,index1)=>{
                      return <TableCell align="left" sx={{fontSize:"0.675rem"}} >{item1}</TableCell>
                    })}
                  </TableRow>
                  </TableHead>
                  :
                  <TableRow tabIndex={-1} key={index} >
                    {row.map((item1,index1)=>{
                      return <TableCell align="left" sx={{fontSize:"0.675rem"}} >{item1}</TableCell>
                    })}
                  </TableRow>
                )
              })}
              </Table>
              </TableContainer>
              :
              <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              Please contact competition admin for winners details
            </Typography> }
          </TabPanel>          

          <TabPanel value={valueTab} index={6}>
            <LiveScoreCard/>
          </TabPanel>

          {userRole.includes('superAdmin') || (userDetails.email !=undefined && userDetails.email == particularCompData.createdBy)?
          <TabPanel value={valueTab} index={7}>           
            {
              particularCompData.feedback !=undefined && particularCompData.feedback.length>0 ?
              <TableContainer component={Paper}  sx={{}}>
              <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow >
                    <TableCell align="left" >S.No</TableCell>
                    <TableCell align="left" >Rating</TableCell>
                    <TableCell align="left" >Comment</TableCell>
                    <TableCell align="left" >Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {particularCompData.feedback.map((row,index) => {
                    return (
                    <TableRow tabIndex={-1} key={row._id} >
                      <TableCell align="left" >{index+1}</TableCell>
                      <TableCell align="left" >{row.rating}</TableCell>
                      <TableCell align="left"  size="small">{row.comment}</TableCell>
                      <TableCell align="left"  size="small">{row.email == ""?'-':row.email}</TableCell>
                      
                    </TableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </TableContainer>
              :<Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
                No Feedback
              </Typography> 
            }
          </TabPanel> :null}             

          <TabPanel value={valueTab} index={8}>   
           {matchGroup.data.length>0?<div>
            <TableContainer component={Paper}  sx={{overflowX: 'auto'}}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
            <TableHead>
              <TableRow tabIndex={-1} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Rank</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Academy</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Shooters</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >1st</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >2nd</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >3rd</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >Points</TableCell>
                </TableRow>
            </TableHead>
            {matchGroupTable.map((row,index)=>{
              return (
                <TableRow tabIndex={-1} key={index} >
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{index+1}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.academy}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.count}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.gold}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.silver}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.bronze}</TableCell>
                  <TableCell align="left" sx={{fontSize:"0.675rem"}} >{row.points}</TableCell>
                </TableRow>
              )
            })}
            </Table>
            </TableContainer>
            <Typography variant="caption" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              {/* * Gold - {ACADEMY_POINT_SEQUENCE[0]} point , Silver - {ACADEMY_POINT_SEQUENCE[1]} points & Bronze - {ACADEMY_POINT_SEQUENCE[2]} points */}
              *The above scores are based on the shooter have a completed their details , as new shooters complete the positions will be updated
            </Typography>

            <BarChart Details={matchGroup} chartTitle="OverAll Rank" graphName='Points'/>
            {matchGroupCateWise.length>0?
            matchGroupCateWise.map((item,index)=>{
              return <BarChart Details={item} chartTitle={item.cateName.replaceAll("_"," ")} graphName='Points'/>
            })
            :null}</div>:
            <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
              No Data
            </Typography>}
          </TabPanel>   

          {userRole.includes('superAdmin') || (userDetails.email !=undefined && userDetails.email == particularCompData.createdBy)?
          <TabPanel value={valueTab} index={9}>           
            {
              particularCompData.protest !=undefined && particularCompData.protest.length>0 ?
              <TableContainer component={Paper}  sx={{}}>
              <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow >
                    <TableCell align="left" >S.No</TableCell>
                    <TableCell align="left" >Shooter Name</TableCell>
                    <TableCell align="left" >Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {particularCompData.protest.map((row,index) => {
                    return (
                    <TableRow tabIndex={-1} key={row._id} >
                      <TableCell align="left" >{index+1}</TableCell>
                      <TableCell align="left"  size="small">{row.shooterName == ""?'-':row.shooterName}</TableCell>
                      <TableCell align="left"  size="small">{row.comment}</TableCell>                      
                    </TableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </TableContainer>
              :<Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >
                No protest data
              </Typography> 
            }
          </TabPanel> :null}  

          <Modal
              open={feedBackModel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >         
            <Box
              component="form"
              sx = {{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width:"90%",
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                  }}
              noValidate
              autoComplete="off"
            >
              <Typography id="modal-modal-title" variant="h4"  mb={1}>
                Feedback
              </Typography>
              <Box sx={{'& > legend': { mt: 2 }}}>
                <Typography id="modal-modal-title" variant="h6"  mb={1}>
                  Rating
                </Typography>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </Box>
                <Typography id="modal-modal-title" variant="h6"  mt={1} mb={1}>
                  Comments *
                </Typography>
                <TextField
                required
                 id="filled-multiline-static"
                 label="comment"
                 multiline
                 minRows={4}
                 value={feedback.comment}
                 defaultValue={feedback.comment}
                 fullWidth
                 autoFocus={commentFocus}
                 onChange={handleFeedbackData('comment')}
                //  onClick={()=>{setSearchFocus(false);setEmailFocus(false); setCommentFocus(true)}}
                />
                <Typography id="modal-modal-title" variant="h6" mt={1} mb={1}>
                  Email (optional)
                </Typography>
                <div key="12">
                <TextField                  
                  id = "outlined-required"
                  value={feedback.commentEmail}
                  defaultValue={feedback.commentEmail}
                  varient="standard"
                  label="Email"
                  type = 'text'
                  fullWidth
                  autoFocus={emailFocus}
                  onChange={handleFeedbackData('commentEmail')}
                  // onClick={()=>{setSearchFocus(false);setCommentFocus(false);setEmailFocus(true);}}
                />
                </div>
                <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                  <Button variant="outlined" onClick={handleUpdateFeedback} >Save</Button>
                  <Button variant="outlined" color="error" onClick={()=>{setfeedBackModel(false);setRating(2);setFeedback({comment:"",commentEmail:""});setSearchFocus(true);setCommentFocus(false);setEmailFocus(false)}} sx={{marginLeft:"1%"}}>Cancel</Button>
                </Stack>  
              
              </Box>
            </Modal>
            <Modal
              open={protestModel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >         
            <Box
              component="form"
              sx = {{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width:"90%",
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                  }}
              noValidate
              autoComplete="off"
            >
              <Typography id="modal-modal-title" variant="h4"  mb={1}>
                Submit your protest
              </Typography>
                <Typography id="modal-modal-title" variant="h6" mt={1} mb={1}>
                  Shooter Name*
                </Typography>
                <div key="12">
                <TextField                  
                  id = "outlined-required"
                  value={protest.shooterName}
                  defaultValue={protest.shooterName}
                  varient="standard"
                  label="Name *"
                  type = 'text'
                  fullWidth
                  autoFocus={emailFocus}
                  onChange={handleProtestData('shooterName')}
                  // onClick={()=>{setSearchFocus(false);setCommentFocus(false);setEmailFocus(true);}}
                />
                <Typography id="modal-modal-title" variant="h6"  mt={1} mb={1}>
                  Comments *
                </Typography>
                <TextField
                required
                 id="filled-multiline-static"
                 label="comment"
                 multiline
                 minRows={4}
                 value={protest.comment}
                 defaultValue={protest.comment}
                 fullWidth
                 autoFocus={commentFocus}
                 onChange={handleProtestData('comment')}
                //  onClick={()=>{setSearchFocus(false);setEmailFocus(false); setCommentFocus(true)}}
                />                
                </div>
                <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                  <Button variant="outlined" onClick={handleUpdateProtest} >Save</Button>
                  <Button variant="outlined" color="error" onClick={()=>{setProtestModel(false);setRating(2);setFeedback({comment:"",shooterName:""});setSearchFocus(true);setCommentFocus(false);setEmailFocus(false)}} sx={{marginLeft:"1%"}}>Cancel</Button>
                </Stack>  
              
              </Box>
            </Modal>
          <Modal
              open={open}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              // sx={{width:{md:'50%'}}}
              >         
            <Box
                component="form"
                sx = {{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                noValidate
                autoComplete="off"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                  Upload the file
              </Typography>
                  <Typography variant="body1" mb={2} style={{cursor : "pointer"}} onClick={() => {
                    window.location.href = `/${competitionId}.xlsx`;
                  }}>
                    Download sample template 
                    {/* <a href='./competition_files/sample.xlsx' target="_blank">click here</a> */}
                  </Typography>
                  <input
                    accept = "*.xlsx"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    onChange={onChangeHandlerForZip}
                    type="file"
                    name="excel-file"
                  />
                    <label htmlFor="raised-button-file">                              
                    <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                        Upload
                    </Button>
                    <Typography variant="body1" mt={1} >
                      {selectedFile ? selectedFile.name : <>Choose excel file</>}
                    </Typography>
                    </label> 
                  <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                    <Button variant="outlined" onClick={handleUpload}>Submit</Button>
                    <Button variant="outlined" color="error" onClick={handleClose} >Cancel</Button>
                  </Stack>  
            </Box>
          </Modal>
          <Modal
            open={dataUpdate}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >         
            <Box
              component="form"
              sx = {{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                  }}
              noValidate
              autoComplete="off"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                {addData === true ?<span> Add User</span>:<span>Update Details</span>} 
              </Typography>  
              {input()}
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                < Button variant = "outlined"
                    onClick = {addData === true ? handleAdd :handleUpdate} > Submit </Button>
                <Button variant="outlined" color="error" onClick={()=>{setDataUpdate(false)}} >Cancel</Button>
              </Stack>  
            </Box>
          </Modal>
            {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )}
      </Container>
    </Page>
  );
}

 
export default competitionDashboard;