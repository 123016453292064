import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {userDetails} from '../constants'

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  user: PropTypes.array,
};

export default function Logo({ disabledLink = false, sx, user }) {
  useEffect(() => {
   getUser();
  }, []);

  const [academy, setAcademy] = React.useState({logo:"/static/ishoot.svg"});
  
  const theme = useTheme();
  const getUser = () => {
    axios
      .post(`/api/user/getParticularUserByEmail`, {
        "emailID": userDetails.email
      })
      .then((response) => {
        const userData = response.data.data;
        // console.log(userData)
        // setUser(userData);
        imageCheck(userData[0].academyId);
      })
      .catch((error) => {
        console.log(error)
        // toast.error(error.response.data);
      });
  }
 
  const imageCheck =(id)=>{
       axios.get(`/logoImage/${id}.png`, {responseType: 'blob'})
        .then(res => {
            var imageUrl = URL.createObjectURL(res.data);
            setAcademy({...academy,logo:imageUrl})
        }).
        catch(e=>{
          // console.log("error ")
          setAcademy({logo:`/static/ishoot.svg`})
        })  
  }

  // OR
  // console.log(user)
  const logo = <Box component="img" src={academy.logo} alt="Intellishoot" sx={{ width: 100, height: 100, ...sx }} user={user} />

  // const logo = <Box component="img" src="/static/ishoot.svg" sx={{ width: 100, height: 100, ...sx }} user={user} />
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink 
    to={user !==undefined && user[0].role !== undefined && (user[0].role.includes("coach") || user[0].role.includes("superAdmin") || user[0].role.includes("academy")) ?
    "/dashboard/academy":"/dashboard/app"}
  >{logo}</RouterLink>;
}
