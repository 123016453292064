import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect, } from 'react';
import { useParams } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box,
  Grid,Card,Avatar,
  Table,TableCell,TableBody,TableContainer,TableHead,TableRow ,Paper 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page';
import Markdown from 'markdown-to-jsx';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import LoginLogoLayout from '../layouts/LoginLogoLayout';
import "./style.css"
import useResponsive from '../hooks/useResponsive';
import {userDetails} from '../constants'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { EditorState, convertToRaw, convertFromHTML,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

const AcademyHome = () => {

  const { uniqueName } = useParams();
  const [user, setUser] = React.useState([{image:[],uniqueName:""}]);
  const [academyDetails, setAcademyDetails] = React.useState([]);
  const [userView, setUserView] = React.useState(false);
  const [updateAbout, setUpdateAbout] = React.useState(false);
  const [achievement, setAchievement] = React.useState(false);
  const [userVisibility, setUserVisiblity] = React.useState(false);
  const [logo, setlogo] = useState(false);
  const [image, setImage] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateAchievement, setEditorStateAchievement] = useState(EditorState.createEmpty());
  const [selectedFile, setselectedFile] = useState("");
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState([]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [profileImage, setProfile] = React.useState(0);
  const smUp = useResponsive('up', 'sm');

    const handleStepChange = (step) => {
      setActiveStep(step);
    };
  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

  useEffect(() => {
    // getUser();
    getUserUnique();
    imageCheck(uniqueName)
  }, []);

  const getUserUnique = () => {
    
    if (userDetails.email === undefined || userDetails.email === null) {
      axios
        .post(`/api/user/getParticularUserByEmail`, {
          // "emailID": userDetails.email
          "uniqueName": uniqueName,
          "profileVisibility":"public"
        })
        .then((response) => {
          if(response.data.data.length >0){
            getUser();
          }else{
            setUserVisiblity(true)
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
      }
      else{
         getUser();
      }
  }

  const getUser = () => {    
    axios
      .post(`/api/user/getParticularUserByEmail`, {
        // "emailID":userDetails.email
        "uniqueName": uniqueName
      })
      .then((response) => {
      
         if (response.data.data.length > 0) {          
          const userData = response.data.data;
            if(userData[0].about != undefined){
              setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(userData[0].about)
                )
              ));
            }
            if (userData[0].achievement != undefined) {
              setEditorStateAchievement(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(userData[0].achievement)
                )
              ));
            }
            if (userData[0].emailID === userDetails.email) {
              setUserView(true)
            }
            userData[0].image.length > 0 && userData[0].image.map((item, index) => {
               axios.post(`/userImage`,{path:item.imagePath}, {
                   responseType: 'blob'
                 })
                 .then(res => {
                   var imageUrl = URL.createObjectURL(res.data);
                   item.imagePathNew=imageUrl
                   if(index+1 === userData[0].image.length){
                     setUser(userData);
                   }
                 })
                 .catch(e => {
                 // console.log("error ",e.response.status)
               })
            })
            if(userData[0].image.length == 0){
              setUser(userData);
            }
          }
          else {
            setUserVisiblity(true)
          }
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const imageCheck =(id)=>{
    axios.get(`/profileImage/${id}.png`, {responseType: 'blob'})
    .then(res => {
        var imageUrl = URL.createObjectURL(res.data);
        setProfile({...profileImage,logo:imageUrl})
    }).
    catch(e=>{
      // console.log("error ",e.response.status)
      
      if (user[0].emailID === userDetails.email) {
        setProfile({logo:localStorage.getItem('dp')})
      }
    })  
  }

   const onEditorStateChange = (editorState) => {
     // console.log(editorState)
     setEditorState(editorState);
   }

   const onEditorAchievementChange = (editorState) => {
     // console.log(editorState)
     setEditorStateAchievement(editorState);
   }

   const update = (data) =>{
    axios
      .put(`/api/user/update`, data)
      .then((response) => {
        getUser();
        setUpdateAbout(false)
        setAchievement(false)
      })
      .catch((error) => {
        //   console.log(error)
        toast.error(error.response.data);
      })
   }

   const handleAbout = () =>{    
    let data = {
      _id: user[0]._id,
      about: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }
    update(data)
   }

   const handleAchievement = () => {
     let data = {
       _id: user[0]._id,
       achievement: draftToHtml(convertToRaw(editorStateAchievement.getCurrentContent()))
     }
     update(data)
   }

   const onChangeHandlerForZip = (e) => {
     try {
       let extension = e.target.files[0].name.split(".")[1];
       if (extension.toLowerCase() !== "png") {
         toast.error("Please upload the image in .png format");
       } else if (e.target.files[0].size >= 1 * 1024 * 1024) {
         toast.error("Image size should be less than 1 MB");
       } else {
         setselectedFile(e.target.files[0]);
       }
     } catch {
       console.log("No File selected");
     }
   };

   const handleUpload = () => {
    if (selectedFile.length == 0){
      toast.error("select the file before submit");
    }else{
      setisSubmitting(true);
      const data1 = new FormData();
      data1.append("id", uniqueName);
      data1.append("file", selectedFile);
      // console.log(user[0].academyId)
      axios
        .post(`/api/user/uploadFile`, data1, {})
        .then((res) => {
          
          if (res.status === 200) {
            getUserUnique();
            imageCheck(uniqueName)
            setlogo(false);
            setisSubmitting(false)
            setselectedFile('')
            toast.success(res.data.message);
          } else {
            toast.error("Error in uploading the image");
          }
        })
        .catch((e) => {
          // console.log(e.response);
          toast.error(e.response.data.message);
        });
      }
   };

   const onChangeHandlerForImage = (e) => {
     try {
       let extension = e.target.files[0].name.split(".")[1];
        if (extension.toLowerCase() !== "png" && extension.toLowerCase() !== "jpeg" && extension.toLowerCase() !== "jpg") {
          toast.error("Please upload the image in .png/.jpeg/.jpg format");
        }
        else if(e.target.files[0].size >= 1 * 1024 * 1024) {
         toast.error("Image size should be less than 1 MB");
       } else {
         setselectedFile(e.target.files[0]);
       }
     } catch {
       console.log("No File selected");
     }
   };

   const handleUploadImage = () => {

     if (selectedFile.length == 0){
      toast.error("select the file before submit");
     }else{
        setisSubmitting(true);
        const data1 = new FormData();
        data1.append("id", uniqueName);
        data1.append("name", selectedFile.name);
        data1.append("_id", user[0]._id);
        data1.append("file", selectedFile);
        // console.log(user[0].academyId)
        console.log(selectedFile.name)
        axios
          .post(`/api/user/uploadImage`, data1, {})
          .then((res) => {
            
            if (res.status === 200) {
              getUserUnique();
              imageCheck(uniqueName)
              setImage(false);
              setisSubmitting(false)
              setselectedFile('')
              toast.success(res.data.message);
            } else {
              toast.error("Error in uploading the image");
            }
          })
          .catch((e) => {
            // console.log(e.response);
            toast.error(e.response.data.message);
          });
      }
   };

  const handleDelete = (data) => {
    
    let dataValue = {
      id: data._id,
      path: data.imagePath
    }
    axios
      .post(`/api/user/deleteObject`, dataValue)
      .then((response) => {
        getUserUnique();
        setDeleteImage(false);
        setSelectedItem({})
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  return (
    <Page title="User Profile">
      < Toast />
      {userVisibility === false ?<div>
         <Grid container spacing={2} style={{paddingTop:"1%"}}>
          <Grid item xs={2} md={3} sm={3}>
            {/* <Stack direction="row" justifyContent="center">
             <Box component="img" src={`/static/ishoot.svg`} alt="Intellishoot" sx={{ width: 100, height: 100 }}  />
             </Stack> */}
          </Grid>
          <Grid item xs={8} md={6} sm={7} onClick={()=>{window.location.assign('/dashboard/app')}}>
            <Stack direction="column" alignItem="center" justifyContent="center">
            <Typography variant="h3" gutterBottom style={{textAlign:"center"}}><div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div></Typography>
            <Typography variant="subtitle1" gutterBottom style={{textAlign:"center"}}>Pro-level Shooter Performance Management & Analytics</Typography>
          </Stack>
          </Grid> 
          <Grid item xs={2} md={3} sm={0}>
            <Stack direction="row" justifyContent={{md:"flex-end",xs:"center",sm:"center"}} spacing={1}>
             
            </Stack>
          </Grid>
        </Grid>
      <Container>
          <Grid container spacing={2} mb={2} sx={{paddingTop:"1%"}} >
            <Grid item xs={12} sm={12} md={4} justifyContent="space-evenly" alignItems="center" sx={{display:"flex"}}>
              {userView === true ?
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <Iconify icon = "material-symbols:upload-rounded" sx={{cursor: "pointer" ,width: 50, height: 50,backgroundColor:"white", borderRadius: "50%/50%", "&:hover": { color:"blue" }  }}  onClick={()=>{setlogo(true)}}/>
                }
              >
                <Avatar alt="No Profile" src= {profileImage.logo} sx={{ width: 230, height: 230 }} />
              </Badge>
              :  <Avatar alt="No Profile" src= {profileImage.logo} sx={{ width: 230, height: 230 }} />
              }
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
            <Card sx={{maxHeight:"35vh"}}>
              <Stack direction='row'ml={1} mt={1} >
              <Typography variant="h6" gutterBottom >{user[0].uniqueName.toUpperCase()}
              {userView === true ?
                <Iconify icon = "material-symbols:edit" sx={{cursor: "pointer",  "&:hover": { color:"blue" }}} ml={2} onClick={()=>{setUpdateAbout(true)}}/>
               :null}
               </Typography>
               </Stack>
              <Scrollbar style={{ height: "28vh", paddingRight: "4rem" }}>
               {user.length >0 && user[0].about !==undefined && user[0].about !=='' ?
               <Markdown style={{marginLeft:"2%"}}>{user[0].about}</Markdown>
              :<p style={{marginLeft:'1%'}}>No Details</p>}
              </Scrollbar>
            </Card>
            </Grid>
          </Grid>

        <Grid container spacing={2} style={{paddingTop:"1%"}}>    
        
        <Grid item xs={12} sm={12} md={4}>
          <Card sx={{maxHeight:"59vh",paddingLeft:'2%'}}>
            <Typography variant="h6" gutterBottom ml={1} mt={2}>Achievements
              {userView === true ?
                <Iconify icon = "material-symbols:edit" sx={{cursor: "pointer",  "&:hover": { color:"blue" }}} ml={2} onClick={()=>{setAchievement(true)}}/>
               :null}</Typography>
              <Scrollbar style={{ height: "50vh", paddingRight: "4rem" }}>
              {user.length >0 && user[0].achievement !==undefined && user[0].achievement !=="" ?
                <Markdown  style={{paddingLeft:"2%"}} >{user[0].achievement}</Markdown>
              :<p style={{marginLeft:'1%'}}>No Details</p>}
              </Scrollbar>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={8} justifyContent="space-evenly" alignItems="center" sx={{display:"flex"}} >
          <Card sx={{height:"59vh",width: {md:760,xs:360,sm:720},}}>            
            <Stack direction="row" sx={{display:"inline-block"}}>
              <Typography variant="h6" gutterBottom ml={1}>Gallery
              {userView === true ? <Stack direction="row" sx={{display:"inline-block"}} >
                {user[0].image.length >2 ? null: <Iconify icon = "material-symbols:upload-rounded" sx={{cursor: "pointer" ,width: 25, height: 25,  "&:hover": { color:"blue" } }} ml={1} onClick={()=>{setImage(true)}}/>}
                <Iconify icon = "ic:baseline-delete" sx={{cursor: "pointer" ,width: 25, height: 25, "&:hover": { color:"blue" } }} ml={1} onClick={()=>{setDeleteImage(true)}}/>
                </Stack> :null}
              </Typography>
            </Stack>
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
            {user.length > 0 && user[0].image !== undefined && user[0].image.length > 0 ?
              <AutoPlaySwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {user[0].image.map((step, index) => (
                  <div key={step.label}>  
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: {md:350,xs:457,sm:605},
                          display: 'flex',
                          // maxWidth: {md:500,xs:510,sm:800},
                          overflow: 'hidden',
                          width: {md:760,xs:360,sm:720},
                        }}
                        src={`${step.imagePathNew}`}
                        alt="No Image"
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              :<Scrollbar style={{ height: "59vh", paddingRight: "4rem" }}>
                <p style={{marginLeft:"3%"}}>No Images</p>
              </Scrollbar>}
            </Box>
          </Card>
        </Grid>
        </Grid>
        
        <Modal
          open={updateAbout}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
          <Typography varient="body1" mt={1}>About</Typography>
          <Editor 
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
          />
           <Stack direction="row"  mt={{xs:15,sm:8,md:3}}>
          <Button variant = "outlined" onClick = {() => {handleAbout()}} >
            save
          </Button>
          <Button variant = "outlined" color = "error" onClick = {() => {setUpdateAbout(false)}} >
            close
          </Button>
          </Stack>
          </Box>
        </Modal>
        <Modal
          open={achievement}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
          <Typography varient="body1" mt={1}>Achievements</Typography>
          <Editor 
              editorState={editorStateAchievement}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorAchievementChange}
          />
           <Stack direction="row" mt={{xs:15,sm:8,md:3}}>
          <Button variant = "outlined" onClick = {() => {handleAchievement()}} >
            save
          </Button>
          <Button variant = "outlined" color = "error" onClick = {() => {setAchievement(false)}} >
            close
          </Button>
          </Stack>
          </Box>
        </Modal>
        <Modal
          open={logo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >         
        <Box
          component="form"
          sx = {{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
        }}
          noValidate
          autoComplete="off"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
              Upload new image
          </Typography>
              <input
                accept = "image/png"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="image"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFile ? selectedFile.name : <>select image</>}
                </Typography>
                </label> 
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                <Button variant="outlined" onClick={handleUpload}>Submit</Button>
                <Button variant="outlined" color="error" onClick={()=>{setlogo(false);setselectedFile("")}}>Cancel</Button>
              </Stack>  
              </Box>
            </Modal> 
        <Modal
          open={image}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >         
        <Box
          component="form"
          sx = {{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
              Upload new image
          </Typography>
              <input
                accept = "image/png, image/jpeg, image/jpg"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForImage}
                type="file"
                name="image"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFile ? selectedFile.name : <>select image</>}
                </Typography>
                </label> 
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                <Button variant="outlined" onClick={handleUploadImage}>Submit</Button>
                <Button variant="outlined" color="error" onClick={()=>{setImage(false);setselectedFile("")}}>Cancel</Button>
              </Stack>  
              </Box>
            </Modal>
        <Modal
          open={deleteImage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >         
        <Box
          component="form"
          sx = {{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
              Delete Image
          </Typography>
          <Typography variant="h6" component="h2" mb={2}>
            { user.length >0 && user[0].image.length >0 && user[0].image.map((item,index)=>{
              return <Stack direction="row"><p style={{marginLeft:'1%',width:"92%",lineBreak:"anywhere"}}>{index+1}. {item.imagePath}</p>
                      <Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                        onClick={()=>{handleDelete(item);setSelectedItem(item)}}                            
                      />
                    </Stack>
            })}
          </Typography>
          <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
            {/* <Button variant="outlined" onClick={handleUploadImage}>Submit</Button> */}
            <Button variant="outlined" color="error" onClick={()=>{setDeleteImage(false)}}>Cancel</Button>
          </Stack>  
          </Box>
        </Modal>
            
        {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )}
      </Container>
      </div>:

        <RootStyle>
         <LoginLogoLayout/>
          {!smUp && (<div>
          <Typography variant="h2" gutterBottom sx={{ ml:{md:21,xs:13,sm:10},mt:{md:9,sm:5,xs:3} }}>
              <div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div>
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ ml:{md:16,xs:3,sm:10},textAlign:"center" }}>
              Pro-level Shooter Performance Management & Analytics
            </Typography></div>
            )}

        <Container>
          <ContentStyle>
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{  }}>
              User Profile Not Available !!!
           </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
      }
    </Page>
  );
}
export default AcademyHome;