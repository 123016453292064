import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import { useState, useEffect } from 'react';
import html2canvas from "html2canvas";
import Scrollbar from "react-scrollbar";
import jsPDF from "jspdf";
import {formatTime} from './../utils/formatTime';
// material
import {
  Stack,
    Container,
    Typography, 
    Grid, TextField, MenuItem,
    Card,
    Box, CardHeader, Button,
  Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';

// components
import Page from '../components/Page';
import Toast from "../components/Toast";
import {Range,DateFilter,userDetails} from '../constants'; 
import {
    UserMixedChart,
    AppWidgetSummary,
    UserDashboardTable,
    DonutSummary
} from '../sections/@dashboard/app';
import Iconify from '../components/Iconify';



const UserDashboard = () => {

  const [user, setUser] = React.useState([]);
  const [match, setMatch] = React.useState([]);
  const [matchCount, setMatchCount] = React.useState([]);
  const [directionCount, setDirectionCount] = React.useState([]);
  const [highestScore, setHighestScore] = React.useState([{max:0,noOf10:0}]);
  const [tableHeader, setTableHeader] = React.useState(["60", "40", "30", "20", "10", "5"]);
  const [overAllScoreMedian, setOverAllScoreMedian] = React.useState(0)
  const [overAllPercentMedian, setOverAllPercentMedian] = React.useState(0)
  const [reportLoader, setreportLoader] = React.useState(false)
  const [bookingDetails, setBookingDetails] = React.useState([{lane:[],weapon:[]}]);  
  const [values, setValues] = React.useState({
      date: 7,
      noOfShots: 4,
      mixedChartMinValue: 275
  });
  useEffect(() => {
    getUser();
    getMatches(values);
    getDirectionDetails();
    getHighestScoreByuser();
  }, []);

  const getBookings = (userId) => {   
    axios
      .post(`/api/booking/getBookingDetailsByUser`, {
        "userId": userId,
        "noOFDaysFilter":values.date
      })
      .then((response) => {
        let value= [{lane:[],weapon:[]}]
        const details = response.data.data;
        if(details.length > 0) {
          details.map((item)=>{
            if (item.type.toLowerCase() == "lane"){
              value[0].lane.push(item)
            }else{
              value[0].weapon.push(item)
            }
          })
          setBookingDetails(value);
        }
      })
      .catch((error) => {
        console.log(error)
        // toast.error(error.response.data);
      });
  }

  const getUser = () => {
    axios
      .post(`/api/user/getParticularUserByEmail`,{"emailID":userDetails.email})
      .then((response) => {
        setUser(response.data.data);
        if (response.data.data[0].studentLevel === 'ISSF') {
          let val = {...values,
            noOfShots: 6,
            mixedChartMinValue: 350}
          setValues({
            ...values,
            noOfShots: 6,
            mixedChartMinValue: 350
          })
          getMatches(val)
        }

        getBookings(response?.data?.data[0]?._id);

      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getMatches = (values) => {
    //   console.log(values.noOfShots)
    axios
      .post(`/api/matches/getParticularMatchesOfUser/timeRange`, {
          emailID: userDetails.email,
          noOFDaysFilter: values.date,
          noOfSeries: parseFloat(values.noOfShots)
      })
      .then((response) => {
        const MatchData = response.data.data[0].matches;
    
        setMatchCount(response.data.data[0])
        setMatch(MatchData);
        const overAllScoreArray=[];
        MatchData.map((item)=>{
            overAllScoreArray.push(item.overAllScore)
            return overAllScoreArray
        })
        const overallMedian = medianCalculation(overAllScoreArray);
        setOverAllScoreMedian(overallMedian)
        const overAllPercentageArray = [];
        MatchData.map((item) => {
            overAllPercentageArray.push(item.percentage)
            return overAllPercentageArray
        })
        const overallpercentage = medianCalculation(overAllPercentageArray);
        setOverAllPercentMedian(Math.round(overallpercentage))

      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response);
      });
  }

  const medianCalculation = (values) =>{
    //   console.log(values)
    values.sort((a, b) => {
        return a - b;
    });
    const lowMiddle = Math.floor((values.length - 1) / 2);
    const highMiddle = Math.ceil((values.length - 1) / 2);
    const median = (values[lowMiddle] + values[highMiddle]) / 2;
    // console.log(median);    
    return median
  }
  const handleChange = (props) => (event) => {
      
      const val = values
      let chartMin = values.mixedChartMinValue;
      if(props === 'date'){
        val.date = event.target.value
      } else if (props === 'noOfShots') {
          val.noOfShots = event.target.value
          if (event.target.value === 0.5) {
            chartMin = 20
          }else if (event.target.value === 1) {
            chartMin = 50
          } else if (event.target.value === 2) {
            chartMin = 75
          } else if (event.target.value === 3) {
            chartMin = 150
          } else if (event.target.value === 4) {
            chartMin = 275
          } else if (event.target.value === 6) {
            chartMin = 350
          }
      }
      setValues({
        ...values,
        [props]: event.target.value,
        mixedChartMinValue: chartMin
      });
     
      getMatches(val)
  };

  const getDirectionDetails = () => {
    //   console.log(values.noOfShots)
    axios
      .post(`/api/shot/DirectionCountByUser`, {
        emailID: userDetails.email,
        noOFDaysFilter: values.date,
      })
      .then((response) => {
        const value = response.data.data;

        const data = [];
        const sampleData = [];
        sampleData.push({name: "A",data: []}, {name: "B",data: []},{name: "C",data: []}, {name: "D",data: []})
        value.map((item, index) => {
          const obj = {
            name: item._id,
            data: [item.countOf10, item.countOf9, item.countOf8, item.countOf7, item.countLessThan6],
          };
          data.push(obj);
          return data;
        });
        let matchdirection = []
        sampleData.map((item, index) => {
          const match = data.find((e) => e.name === item.name)
          matchdirection.push({
            name: item.name,
            data: match?.data ?? []
          })
        })
        if (matchdirection.length>0){
          setDirectionCount(matchdirection)
        }
        else{
          setDirectionCount(sampleData)
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response);
      });
  }

  const getHighestScoreByuser = () => {
    //   console.log(values.noOfShots)
    axios
      .post(`/api/shot/HighestScoreByUser`, {
        emailID: userDetails.email,
        noOFDaysFilter: values.date,
      })
      .then((response) => {
        const value = response.data.data;    
        // console.log(response.data.data)
        if (response.data.data.length>0){
          setHighestScore(value)
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response);
      });
  }

  const addIndexPage = (doc) => {
    // doc.addImage(pdfheader, "PNG", doc.internal.pageSize.width/2 - 60, 250);
    doc.addImage("/static/pdfheader.png", "PNG", 0, 0, doc.internal.pageSize.width, 120);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(23);
    doc.setTextColor(1, 49, 53);
    doc.addImage(
      "/static/ishoot.png",
      "PNG",
      doc.internal.pageSize.width / 2 - 60,
      250, 60, 60
    );
    doc.text(
      "Shooting Performance Analysis",
      doc.internal.pageSize.width / 2 - 160,
      350
    );

    let today = new Date();
    let date = new Date();
    let from = date.setDate(date.getDate() - values?.date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const yyyyFrom = date.getFullYear();
    let mmFrom = date.getMonth() + 1; // Months start at 0!
    let ddFrom = date.getDate();

    if (ddFrom < 10) ddFrom = "0" + ddFrom;
    if (mmFrom < 10) mmFrom = "0" + mmFrom;


    today = dd + "/" + mm + "/" + yyyy;
    from = ddFrom + "/" + mmFrom + "/" + yyyyFrom;

    doc.setFont("helvetica");
    doc.setFontSize(14);
    doc.text(
      "User :" + localStorage.getItem("username"),
      doc.internal.pageSize.width / 2 - 160,
      380
    );
    doc.text(`Timeframe : ${from} and ${today}`, doc.internal.pageSize.width / 2 - 160, 400);
  };

  const addHeaders = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.setTextColor(1, 49, 53);
    for (var i = 2; i <= pageCount; i++) {
      doc.setPage(i);

      doc.addImage("/static/ishoot.png", "PNG", 18, 14, 30, 30);

      doc.text(
        "intelliShoot - Your Shooting Performance Analysis",
        doc.internal.pageSize.width / 2 - 120,
        35
      );
    }
  };

  const addFooters = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont("helvetica", "italic");
    doc.setFontSize(9);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      doc.setFillColor(1, 49, 53);
      doc.rect(0, 800, doc.internal.pageSize.width, 120, "F");
      doc.setTextColor(255, 255, 255);
      doc.text(
        "For more info, Pls reach out to support@intellishoot.com",
        10,
        823
      );
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        doc.internal.pageSize.width - 60,
        823
      );
    }
  };

  const generateReport = (e) => {
    setreportLoader(true);
    let input = window.document.getElementsByClassName("noOfMatchWidget")[0];
    let input2ndPage = window.document.getElementsByClassName("tableWidget")[0];
    
    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF("5", "pt");
      pdf.setFont("Roboto");
      addIndexPage(pdf);
      pdf.addPage();

      pdf.setFontSize(12);
      pdf.addImage(img, "JPEG", 20, 55, 530, 700, "alias1", "SLOW");
      pdf.addPage();
      
      html2canvas(input2ndPage).then((canvas) => {
        const img2 = canvas.toDataURL("image/png");
        pdf.addImage(img2, "JPEG", 20, 55, 530, 700, "alias2", "SLOW");
        addFooters(pdf);
        addHeaders(pdf);
        pdf.save("performance_report.pdf");
        setreportLoader(false);
      }).catch((error) => {
        console.log(err)
      })
    

    })
  }
  const card = (links) => {    
    return (
      <div>
          <Stack direction="row"  justifyContent="space-between" mb={2}>
             <Box sx={{ width: '100%' }}>         
                <Scrollbar>
                <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                  <TableHead>
                  <TableRow>
                  <TableCell align="left">{links[0]?.type==="Lane"?"Lane":"Weapon"}</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Time</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {links.map((item,index)=>{
                    return (
                      <TableRow tabIndex={-1}>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">{item.fromTime.split('T')[0]}</TableCell>
                        <TableCell align="left">
                          {formatTime(item.fromTime.split('T')[1]).split(" ")[0]} - {formatTime(item.toTime.split('T')[1])}</TableCell>
                        <TableCell align="left">{item.status.toUpperCase()=== "APPROVED"?
                          // <Iconify icon = "subway:tick" sx={{width:"100%",color:'green'}} />
                          <div style={{color:"green"}}> {item.status.toUpperCase()}</div>
                          :
                            item.status.toUpperCase() === "REQUESTED" ?
                             user[0].role.includes("coach") || user[0].role.includes("superAdmin") || user[0].role.includes("academy") ?
                              <Button variant="contained" onClick={() => {setApprove(true);setSelectedItem(item)}}>
                                {item.status.toUpperCase()}
                              </Button>
                              :<div style={{color:"darkorange"}}>{item.status.toUpperCase()}</div>
                            :
                            item.status.toUpperCase() === "AVAILABLE" ?
                             user[0].role.includes("coach") || user[0].role.includes("superAdmin") || user[0].role.includes("academy") ?
                              <Button variant="contained" onClick={() => {setStatusChange(true);setSelectedItem(item)}}>
                                {item.status.toUpperCase()}
                              </Button>
                              :<div style={{color:"darkblue"}}>{item.status.toUpperCase()}</div>
                            :<div style={{color:"red"}}>{item.status.toUpperCase()}</div>
                         }
                        </TableCell>
                        <TableCell align="left">{item.comment === undefined ?"":item.comment}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[3, 5, 10, 25]}
                colSpan={3}
                count={links.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              </Scrollbar>
             </Box>
          </Stack>
        </div>
    )}

  const NoData = () => {
    return (
      <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'7%'},marginLeft:{sm:'20%',md:'29%'}}}>
        <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No Data</Typography> 
        <img src="/static/illustrations/empty_bin.png" alt="No Details" style={{width:'300px'}} />
      </Box>
    )}
  return (
    <Page title="UserDashboard">
      < Toast />
      <Container maxWidth="xl">
        <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={7}>
          <Typography variant="h4" gutterBottom>
            Your Shooting Performance Analysis
          </Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={2}>
          <TextField
                id = "outlined-select-course"
                select
                label = "Date"
                value={values.date}
                onChange={handleChange('date')}
                variant = "standard"
                style={{align:"right"}}
              >
                {
                  DateFilter.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
          <Grid item xs={5} sm={5} md={2}>
              <TextField
                id = "outlined-select-course"
                select
                fullWidth
                label = "No Of Shot"
                value={values.noOfShots}
                onChange={handleChange('noOfShots')}
                variant = "standard"
                style={{width:'50%'}}
              >
                {
                  Range.map((option) => (
                  <MenuItem key={parseFloat(option.value)} value={parseFloat(option.value)}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
              {!reportLoader ? <Iconify icon = "akar-icons:download" style={{cursor: "pointer", height: "30px", marginTop: "15px"}} sx={{width:"100%"}} onClick={(e) => generateReport(e)}/> :
              <Iconify icon = "eos-icons:loading" style={{ height: "30px", marginTop: "15px"}} sx={{width:"100%"}} />
              }
                

              </Grid>
 
        </Grid>

        <div className="noOfMatchWidget">
          <Grid container spacing={2}>
            <Grid item xs={6} sm={2} md={2}>
                <AppWidgetSummary title="No of Match" score="" total={match.length} icon={'ant-design:android-filled'} />
            </Grid>

            <Grid item xs={6} sm={2} md={2}>              
                <AppWidgetSummary title="Personal Best" score="" total={Math.max(...match.map(o => o.overAllScore))!==-Infinity?Math.max(...match.map(o => o.overAllScore)):0} color="info" icon={'ant-design:apple-filled'} />
            </Grid>

            <Grid item xs={6} sm={2} md={2}>
                <AppWidgetSummary title="Highest 10x" score="" total={Math.max(...match.map(o => o.noOf10x))!==-Infinity?Math.max(...match.map(o => o.noOf10x)):0} color="info" icon={'ant-design:windows-filled'} />
            </Grid>
            {/* {console.log(Math.max(...match.filter( value => !isNaN(value.noOf10)).map(o => o.noOf10)),match.filter( value => !isNaN(value.noOf10)))} */}
            <Grid item xs={6} sm={2} md={2}>
                {/* <AppWidgetSummary title = "Highest 10s" total = {((Math.max(...match.map(o => o.noOf10)) !== -Infinity) && (!isNaN(Math.max(...match.map(o => o.noOf10))))) ? Math.max(...match.map(o => o.noOf10)): 0 } color = "info" icon = {'ant-design:windows-filled'}/> */}
                <AppWidgetSummary title = "Highest 10s" score="" total = {Math.max(...match.filter( value => !isNaN(value.noOf10)).map(o => o.noOf10)) !== -Infinity && Math.max(...match.map(o => o.noOf10))!==-Infinity? Math.max(...match.filter( value => !isNaN(value.noOf10)).map(o => o.noOf10)): 0 } color = "info" icon = {'ant-design:windows-filled'}/>
            </Grid>

            {/* <Grid item xs={6} sm={2} md={2}>
                <AppWidgetSummary title="No of 10x" score="" total={(match.map(o => o.noOf10x)).reduce((a, b) => a + b, 0)} color="info" icon={'ant-design:windows-filled'} />
            </Grid> */}
            
            {/* <Grid item xs={6} sm={2} md={2}>              
                <AppWidgetSummary title="Highest 10" score="" total={highestScore[0].max >0?highestScore[0].max:0} color="info" icon={'ant-design:apple-filled'} />
            </Grid> */}

            {/* <Grid item xs={6} sm={2} md={2}>              
                <AppWidgetSummary title="# of 10" score="" total={highestScore[0].noOf10 >0?highestScore[0].noOf10:0} color="info" icon={'ant-design:apple-filled'} />
            </Grid> */}

            <Grid item xs={6} sm={2} md={2}>
                <AppWidgetSummary title="Median score" score="" total={overAllScoreMedian > 0 ? overAllScoreMedian :0} color="info" icon={'ant-design:bug-filled'} />
            </Grid>
            <Grid item xs={6} sm={2} md={2}>
                <AppWidgetSummary title="Median %" score="" total={`${overAllPercentMedian > 0?overAllPercentMedian:0}%`} icon={'ant-design:windows-filled'} />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
                <UserMixedChart Details={match} chartMin={values.mixedChartMinValue} seriesName="Over all Score"/>
            </Grid>
            
            {/* <Grid item xs={12} md={12} lg={12}> 
              <UserDashboardTable title="Data Summary" header={tableHeader} data={matchCount} />
            </Grid> */}

            <DonutSummary directionCount={directionCount} header="Direction Summary" />
            
        </Grid>
        </div>
        <div className='tableWidget' style={{marginTop: "2%"}}>
          <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <UserDashboardTable title='Match Summary' header={tableHeader} data={matchCount} />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Card>
                    <CardHeader title="Lane Booking" />
                    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                    { bookingDetails[0].lane.length !== 0 ?card(bookingDetails[0].lane):NoData()}
                    </Box>
                </Card>
              </Grid> 

              <Grid item xs={12} md={12} lg={12}>
                <Card>
                    <CardHeader title="Weapon Booking" />
                    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                    { bookingDetails[0].weapon.length !== 0 ?card(bookingDetails[0].weapon):NoData()}
                    </Box>
                </Card>
              </Grid>
            </Grid>
            </div>
      </Container>
    </Page>
  );
}
export default UserDashboard;