import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box,
  Card, CardContent, Grid,
  Accordion, AccordionSummary, AccordionDetails, TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {userDetails,Type,teamType} from '../constants'
import { AppWidgetSummary} from '../sections/@dashboard/app';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const MyAchievement = () => {

  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [deleteData, setDelete] = React.useState(false);
  const [user,setUser] = React.useState([]);
  const [achievements, setAchievements] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState({});
  const [expanded, setExpanded] = React.useState('gold');
  const [gold, setGold] = React.useState([]);
  const [silver, setSilver] = React.useState([]);
  const [bronze, setBronze] = React.useState([]);
  const [certificate, setCertificate] = React.useState([]);
  const [isSubmitting, setisSubmitting] = React.useState(true);
  const [values, setValues] = React.useState({
    medalName: {label:''},
    teamType: {label:''},
    matchType: {label:''},
    matchType1: {label:''},
    notes: '',
    date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
  });
  const [selectedFile, setselectedFile] = useState("");

  const medal =[{
    label:"Gold"
  }, {
    label: "Silver"
  }, {
    label: "Bronze"
  }, {
    label: "Certificate"
  }]


  useEffect(() => {
    getAchievements();
  }, []);

  const getAchievements = () => {
    axios
      .post(`/api/achievement/achievementsByUser`, {
        emailID: userDetails.email
      })
      .then((response) => {
        const gold=[]
        const silver=[]
        const bronze = [];
        const certificate = [];
        const achievement = response.data.data[0].achievements;
        
        setAchievements(achievement);
        achievement.map((item)=>{
         
          if(item.medalName.toUpperCase() === "GOLD"){
            gold.push(item)
          }
          else if (item.medalName.toUpperCase() === "SILVER") {
            silver.push(item)
          }
          else if(item.medalName.toUpperCase() === "BRONZE"){
            bronze.push(item)
          }
          else if (item.medalName.toUpperCase() === "CERTIFICATE") {
            certificate.push(item)
          }
        })
        
        let cert = []
        if(certificate.length > 0){
          certificate.map((item,index)=>{
            axios.post(`/api/achievement/image`,{id:`${item._id}.png`}, {responseType: 'blob'})
            .then(res => {
                var imageUrl1 = URL.createObjectURL(res.data);
                item.imageUrl = imageUrl1
            }).
            catch(e=>{
              console.log("error ")
            })  
            cert.push(item)
            setCertificate(cert);
          })
        }
          
          sortFunction(gold)
          setGold(gold);
          sortFunction(silver)
          setSilver(silver);
          sortFunction(bronze)
          setBronze(bronze);
          sortFunction(cert)
          setCertificate(cert);
          setUser(response.data.data[0])

          gold.length > 0 ? setExpanded('gold') : silver.length > 0 ? setExpanded('silver') : bronze.length > 0 ? setExpanded('bronze') : null
        })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const sortFunction = (data) => {
    data.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    })
  }

  const handleChange = (props) => (event) => {
    // console.log(event.target.value)
    setValues({
      ...values,
      [props]: event.target.value
    });
  };

  const handleAchievement =() =>{  
    setisSubmitting(true)
    if (values.medalName === '' || values.teamType === '' || values.date === '' || values.matchType === '') {
      toast.error("Enter all the fields before save");
    }else{
      if (values.medalName.toLowerCase() === "certificate" && selectedFile.length === 0) {
        toast.error("selecte the certificate before save");
      } else {
      axios
        .post(`/api/achievement/add`, {
          medalName: values.medalName.toLowerCase(),  
          userId: user._id,
          teamType: values.teamType,
          date: values.date,
          matchType: values.matchType,
          notes: values.notes,
          imageUrl: `/certificate/`
        })
        .then((response) => {
          if (values.medalName.toLowerCase() === "certificate"){
            handleUpload(response.data.data._id)
          }else{
            setisSubmitting(false)
            setOpen(false);
            getAchievements();
            setValues({
              medalName: {label:''},
              teamType: {label:''},
              matchType: {label:''},
              matchType1: {label:''},
              notes: '',
              date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
            })

          }
        })
        .catch((error) => {
         setisSubmitting(false)
          toast.error(error.response.data);
        });
      }
    }
  }

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
  }

  const handleUpdate = () => {  
      axios
        .put(`/api/achievement/update`, {
          medalName: values.medalName.toLowerCase(),
          userId: selectedData.userId,
          teamType: values.teamType,
          date: values.date,
          matchType: values.matchType,
          notes: values.notes,
          _id: selectedData._id
        })
        .then((response) => {
          setisSubmitting(false)
          setOpen(false);
          setUpdate(false);
          getAchievements();
          setSelectedData({});
          setValues({
            medalName: {label:''},
            teamType: {label:''},
            matchType: {label:''},
            matchType1: {label:''},
            notes: '',
            date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
          })
        })
        .catch((error) => {
          setisSubmitting(false)
          toast.error(error.response.data);
        });
  }

  const handleDelete = () => {
    let data = { _id: selectedData._id}
    axios
      .delete(`/api/achievement/delete`, {data})
      .then((response) => {
        if (selectedData.medalName === 'certificate'){
          axios
          .post(`/api/achievement/removeFile`, {id:selectedData._id})
          .then((res) => {
              toast.success(res.data.message);
              getAchievements();
              setDelete(false);
              setSelectedData({});
              setValues({
                  medalName: {label:''},
                  teamType: {label:''},
                  matchType: {label:''},
                  matchType1: {label:''},
                  notes: '',
                  date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
                })
          })
          .catch((e) => {
            // console.log(e.response);
            toast.error(e.response.data.message);
          });
        }else{
        getAchievements();
        setDelete(false);
        setSelectedData({});
        setValues({
            medalName: {label:''},
            teamType: {label:''},
            matchType: {label:''},
            matchType1: {label:''},
            notes: '',
            date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
          })
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

   const onChangeHandlerForZip = (e) => {
     try {
       let extension = e.target.files[0].name.split(".")[1];
       if (extension.toLowerCase() !== "png") {
         toast.error("Please upload the image in .png format");
       } 
       else if (e.target.files[0].size >= 6 * 1024 * 1024) {
         toast.error("Image size should be less than 6 MB");
       } 
       else {
         setselectedFile(e.target.files[0]);
       }
     } catch {
       console.log("No File selected");
     }
   };

   const handleUpload = (id) => {
     setisSubmitting(true);
     const data1 = new FormData();
     data1.append("id", id);
     data1.append("file", selectedFile);
     console.log("*** id in upload ",id)
     axios
       .post(`/api/achievement/uploadFile`, data1, {})
       .then((res) => {
         if (res.status === 200) {
           setisSubmitting(false)
            setOpen(false);
            getAchievements();
            setValues({
              medalName: {label:''},
              teamType: {label:''},
              matchType: {label:''},
              matchType1: {label:''},
              notes: '',
              date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
            })
            setselectedFile('')
           toast.success(res.data.message);
         } else {
           setisSubmitting(false)
           toast.error("Error in uploading the image");
         }
       })
       .catch((e) => {
         // console.log(e.response);
         toast.error(e.response.data.message);
       });
   };

  const card = (medalType) => {
    
      return (
        <Accordion expanded={expanded === medalType[0].medalName} onChange={handleChangeAccordion(medalType[0].medalName)}>
            <AccordionSummary
              expandIcon={<Iconify icon = "flat-color-icons:expand" sx={{width:"100%"}} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <Typography>{medalType[0].medalName.toUpperCase()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container>
            {medalType.length >0 ? medalType.map((item,index) => {
              return (
                <Box sx={{mt:2}}>
                <Grid item key={index+1} sx={{ml:2}}>
                  {medalType[0].medalName.toLowerCase() === 'certificate'?
                  <Card sx={{ minWidth: 270,maxWidth: 270,maxHeight: 200,minHeight: 200 }}><CardContent>
                    <Stack direction='row'>
                  <Typography variant="h5" sx={{ mb: 1.5 }} component="div">
                    {item.matchType} 
                  </Typography>
                    <Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue"} }} ml={1} width={20} height={20} 
                    onClick = {
                      () => {
                        setDelete(true);
                        setSelectedData(item);
                      }
                    }
                  />
                  </Stack>
                  <img src={item.imageUrl !=undefined ?item.imageUrl:null} alt="No Cert" style={{cursor: "pointer"}} onClick={()=>{window.open(`${item.imageUrl}`, '_blank')}} />
                  </CardContent></Card>
                  :
                <Card sx={{ minWidth: 270,maxWidth: 270,maxHeight: 260,minHeight: 260 }}>
                <CardContent>
                  <Stack direction="row">
                  <Typography variant="h5" sx={{ mb: 1.5 }} component="div">
                    {item.medalName.toUpperCase()} 
                  </Typography>
                  
                  <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} width={55} height={29} 
                      onClick = {
                        () => {
                          
                          setValues({
                              medalName: medal[medal.map(val => val.label.toLowerCase()).indexOf(item.medalName)],
                              teamType: teamType[teamType.map(val => val.label).indexOf(item.teamType)],
                              matchType: Type[Type.map(val => val.value).indexOf(item.matchType)].value,
                              matchType1: Type[Type.map(val => val.value).indexOf(item.matchType)],
                              notes: item.notes,
                              date: item.date.split('.')[0]
                            });
                            setUpdate(true);
                            setSelectedData(item)
                        }
                      }
                  />
                  <Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} width={55} height={29} 
                    onClick = {
                      () => {
                        setDelete(true);
                        setSelectedData(item);
                      }
                    }
                  />
                  {item.medalName.toUpperCase() === 'GOLD' ?
                    <Iconify icon = "twemoji:1st-place-medal" sx={{width:"30%",height:"38%",ml:10}} />
                    : item.medalName.toUpperCase() === 'SILVER' ?
                    <Iconify icon = "twemoji:2nd-place-medal" sx={{width:"30%",height:"38%",ml:10}} />
                    :
                    <Iconify icon = "twemoji:3rd-place-medal" sx={{width:"30%",height:"38%",ml:10}} />
                  }
                  </Stack>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Match Type : {item.matchType}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Team Type : {item.teamType}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    On : {item.date.split('T')[0]}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {item.notes}
                  </Typography>
                </CardContent>
              </Card>
              }
            </Grid>
            </Box>
              )
            }):<p>No {medalType[0].medalName} medal</p>}
            </Grid>
            </AccordionDetails>
            </Accordion>
      )}

    const input = () =>{
      
      return (
      <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
        <Autocomplete
          id = "disable-clearable"
          disableClearable
          options={medal}
          defaultValue={values.medalName}
          getOptionLabel={(option) => {return option.label}}
          inputValue={values.medalName}
          onInputChange={(event, newInputValue) => {
                setValues({
                ...values,
                ['medalName']: newInputValue
              });
          }}
          renderInput={(params) => <TextField {...params} label="Medal" sx={{mt:2}}/>}
        />   
        <Autocomplete
          id = "disable-clearable"
          disableClearable
          options={teamType}
          defaultValue={values.teamType}
          getOptionLabel={(option) => {return option.label}}
          inputValue={values.teamType}
          onInputChange={(event, newInputValue) => {
                setValues({
                ...values,
                ['teamType']: newInputValue
              });
          }}
          renderInput={(params) => <TextField {...params} label="Team Type" sx={{mt:2}}/>}
        />   
        <Autocomplete
          id = "disable-clearable"
          disableClearable
          options={Type}
          defaultValue={values.matchType1}
          getOptionLabel={(option) => {return option.label}}
          inputValue={values.matchType1}
          onInputChange={(event, newInputValue) => {
                setValues({
                ...values,
                ['matchType1']: newInputValue
              });
          }}
          onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['matchType']: newValue.value
            });
          }}
          renderInput={(params) => <TextField {...params} label="Match Type" sx={{mt:2}}/>}
        />  
        <TextField
          id="datetime-local"
          label="Achievement On"
          type="datetime-local"
          value={values.date}   
          InputProps = {
            {
              inputProps: {
                max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
              }
            }
          }
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('date')}
          // helperText = "Select the match date & time"
        /> 
        <TextField
          id = "filled-multiline-flexible"
          multiline
          maxRows={4}
          defaultValue = {values.notes}
          value={values.notes}
          varient="standard"
          label="Notes"
          type = 'text'
          sx={{mt:2}}
          // helperText = "Enter User Name"
          onChange={handleChange('notes')}
          />
          {
            values.medalName !='' && values.medalName.label !='' && values.medalName.toLowerCase() === "certificate" ? < Stack >
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} mt={2}>
              Upload Certificate
           </Typography>
              <input
                accept = "image/png"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="logo"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFile ? selectedFile.name : <>select certificate less than 1MB</>}
                </Typography>
                </label> </Stack>
            :null
          }
      </Stack>
      )}

  return (
    <Page title="MyAchievement">
      <Toast />
      <Container>
        {/* {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )} */}
        {open === true || update === true ? null:
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            My Achievements
          </Typography> 
          <Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            Medal
          </Button>  
        </Stack> 
        }      

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={5}>            
          {achievements.length === 0 && open === false && update === false ? 
          <Typography variant="h3" gutterBottom component="div">
            No Achievements
          </Typography> 
          :null}        
        </Stack>
      {open === true ?
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Achievement
          </Typography>
          {input()}
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
            <Button variant="outlined" onClick={handleAchievement}>save</Button>
            <Button variant="outlined" color="error" onClick={() => {setOpen(false);
                setValues({
                  medalName: {label:''},
                  teamType: {label:''},
                  matchType: {label:''},
                  matchType1: {label:''},
                  notes: '',
                  date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
                })}}>
            Cancel</Button>
          </Stack>
          </Stack> :
          update === true ?
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Achievement
          </Typography>
          {input()}
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
            <Button variant="outlined" onClick={handleUpdate}>Update</Button>
            <Button variant="outlined" color="error" onClick={() => {setUpdate(false);            
                setValues({
                  medalName: {label:''},
                  teamType: {label:''},
                  matchType: {label:''},
                  matchType1: {label:''},
                  notes: '',
                  date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
                })}}>
            Cancel</Button>
          </Stack>
          </Stack>
          :<div>
            {achievements.length === 0 ? null: 
            <Grid container spacing={2} mb={2} mt={-7}>
            <Grid item xs={6} sm={3} md={3} >
                <AppWidgetSummary title="Gold" total={gold.length} score="" icon={'ant-design:android-filled'} />
            </Grid>
            <Grid item xs={6} sm={3} md={3} >
                <AppWidgetSummary title="Silver" total={silver.length} score="" color="info" icon={'ant-design:windows-filled'} />
            </Grid>
            <Grid item xs={6} sm={3} md={3} >              
                <AppWidgetSummary title="Bronze" total={bronze.length} score="" icon={'ant-design:apple-filled'} />
            </Grid>
            <Grid item xs={6} sm={3} md={3} >              
                <AppWidgetSummary title="Certificate" total={certificate.length} score="" icon={'ant-design:apple-filled'} />
            </Grid>      
          </Grid>}
          <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }} > 
          
            {certificate.length > 0?card(certificate):null}    
            {gold.length > 0?card(gold):null}    
            {silver.length > 0?card(silver):null}    
            {bronze.length > 0?card(bronze):null}    
            
          </Scrollbar>
          </div>
        } 
       <Modal
          open={deleteData}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5" gutterBottom component="div">
              Do you need to delete the Achievement?
            </Typography>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
              <Button variant="outlined" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant = "outlined" color = "error" onClick = {() => {setDelete(false);
                  setValues({
                medalName: {label:''},
                teamType: {label:''},
                matchType: {label:''},
                matchType1: {label:''},
                notes: '',
                date: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
              })}} >
                No
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}

 
export default MyAchievement;