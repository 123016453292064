import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import axios from "axios";
import toast from "react-hot-toast";
import PropTypes from 'prop-types';
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box,Paper,
  MenuItem,
  Card, CardContent, CardHeader, Grid,
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  Tab, TextField, Table,TableBody,TableCell, TableRow,TableContainer,TableHead
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Markdown from 'markdown-to-jsx';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {userDetails} from '../constants'
import CompetitionUserRequest from './CompetitionUserRequest';
import CompetitionDashboardResult from './CompetitionDashboardResult';
import {countryPincode} from '../CountryPincode'

const competitionDashboard = () => {

  const { competitionId } = useParams();
  const [registerUser, setRegisterUser] = React.useState(false);
  const [bulkUserUpload, setBulkUserUpload] = React.useState(false);
  const [competitionInfo, setcompetitionInfo] = React.useState(false);
  const [result, setResult] = React.useState(false);
  const [user, setUser] = React.useState([{academyId:""}]);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [userRole, setUserRole] = React.useState(['competition']);
  const [Course, setCourse] = React.useState([]);
  const [competitionData, setCompetitionData] = React.useState([]);
  const [particularCompData, setParticularCompData] = React.useState({description:`<p>check</p>`});
  const [selectedCategory, setSelectedCategory] = React.useState({
    value:[]
  })
  const [selectedCategoryItem, setSelectedCategoryItem] = React.useState({value:[]})
  const [selectedShooterCategoryItem, setSelectedShooterCategoryItem] = React.useState({value:[]})
  const [filterValues, setFilterValues] = React.useState({
    gender: '',
    gameType: '',
    teamType: 'Individual',
    matchType: '',
    category: ''
  });
  const [registerDetail, setRegisterDetail] = React.useState([]);
  const [allRegisterDetail, setAllRegisterDetail] = React.useState([]);
  const [regModel, setRegModel] = React.useState(false);
  const [matchCategory, setMatchCategory] = React.useState([]);
  const [totalFees, setTotalFees] = React.useState(0);
  const [fees, setFees] = React.useState(false);
  const [academy, setAcademy] = React.useState([]);
  const [values, setValues] = React.useState({
      userName: '',
      emailId: '',
      academyName: '',
      gender: '',
      contactNo:'',
      stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code,
      extAcademyName: '',
      DOB:'',
  });
  const [value, setValue] = React.useState({
    userId:'',
    tnxId:'',
    tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`,
    comment:""
  });  
  const [selectedFile, setselectedFile] = useState("");
  const [selectedFileExcel, setselectedFileExcel] = useState("");
  const gender = [{
    value: "male",
    label: "Male"
  }, {
    value: "female",
    label: "Female"
  }, {
    value: "other",
    label: "Other"
  }]
  // const shooterCategory = [{
  //   value: "General",
  //   label: "General"
  // }, {
  //   value: "Para",
  //   label: "Para"
  // }, {
  //   value: "Service",
  //   label: "Service"
  // }]

  const shooterCategory = ['General','Para','Service']

  useEffect(() => {
    getCompetitions();
    getUserDetail();
    getCourse();
    getCompetitionDetail();
    getAllRegistration();
    getAcademy();
  }, []);

  const getUserDetail = () => {
    if (userDetails.email === undefined || userDetails.email === null){
      setUserRole(['competition'])
    }else{
      axios
        .post(`/api/user/getParticularUserByEmail`, {
          "emailID": userDetails.email
        })
        .then((response) => {
          const userData = response.data.data;
          setUserRole(userData[0].role);
          setUser(userData)
          handleRegisterDetail(userData)
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

  const getCompetitionDetail = () =>{
     axios
       .post(`/api/competition/getParticularCompetition`, {
         _id: competitionId,
       })
       .then((response) => {
        //  console.log(response.data.data)
         const res = response.data.data
          setParticularCompData(res[0]);
          getMatchCatagory(res[0])
       })
       .catch((error) => {
         toast.error(error.response.data);
       });
  }

  const getCompetitions = () => {
    // console.log(filterValues)
    axios
      .post(`/api/compMatchMapper/getParticularcompetition`, {
        competitionId: competitionId,
        filterValues: filterValues
      })
      .then((response) => {
        // console.log("**",response.data.data)
        const res = response.data.data
       if (response.data.data.length === 0) {
         setCompetitionData([])
       } else {
         setCompetitionData(res[0].competition);
         getMatchCatagory(res[0].competition)
       }
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const getMatchCatagory = (competition) =>{
    let compArray = [];
    axios
      .post(`/api/competition/getMatchCatagory`, {
        competitionData:competition
      })
      .then((response) => {
        response.data.data.map((item,index)=>{
          let compVal = {
            value:`${item.gameType} ${item.gender} ${item.category} ${item.teamType} ${item.matchType} ${item.shooterCategory}`,
            id: item.id,
            teamType:item.teamType
          }
          compArray.push(compVal)
        })    
        setMatchCategory(compArray)    
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const getCourse = () => {
    axios
      .get(`/api/course/getAll`)
      .then((response) => {
        const courseData = response.data.data;
        const value = [];
        courseData.map((item, index) => {
          const value1 = {
            value: `${item.type}-${item.range}`,
            label: `${item.displayName} ${item.range}m`,
          }
          value.push(value1)
        })
        setCourse(value);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleClose = () => {setRegisterUser(false);setFees(false);setSelectedCategoryItem({value:[]});setSelectedShooterCategoryItem({value:[]});setSelectedCategory({value:[]});
    setValues({userName: '',emailId: '',academyName: '',gender: '',contactNo:'',stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code});
    setFilterValues({gender: '',gameType: '', teamType: 'Individual',matchType: '',category: ''})};

  const handleRegisterDetail = (user) =>{
    axios
    .post(`/api/compUserMapper/getParticularUserDetail`, {
      userId: user[0]._id,
      competitionId: competitionId
    })
    .then((response) => {
      if(response.data.data.length > 0){
        setRegisterDetail(response.data.data)
      }else{ }
    })
    .catch((error) => {
      setisSubmitting(false)
      toast.error(error.response.data);
    });
  }

  const handleRadio = (e) =>{
    setFilterValues({...filterValues,
      [e.target.name]: e.target.value
    })
  }

  const getAllRegistration = () =>{
    console.log("*** ",competitionId)
    // let register = [];
    axios
    .post(`/api/compUserMapper/getByCompId`, {
      competitionId: competitionId
    })
    .then((response) => {
      console.log("********** ",response.data.data)
      if(response.data.data.length > 0){
        // register.append(response.data.data)
        setAllRegisterDetail(response.data.data)
      }else{ }
    })
    .catch((error) => {
      setisSubmitting(false)
      toast.error(error.response.data);
    });
    // axios
    // .post(`/api/compPublicUserMapper/getByCompId`, {
    //   competitionId: competitionId
    // })
    // .then((response) => {
    //   console.log("********** ",response.data.data)
    //   if(response.data.data.length > 0){
    //     register.append(response.data.data)
    //     setAllRegisterDetail(response.data.data)
    //   }else{ }
    // })
    // .catch((error) => {
    //   setisSubmitting(false)
    //   toast.error(error.response.data);
    // });
    // console.log("****re**** ",register)
    // setAllRegisterDetail(register)
  }

  const getAcademy = () => {
    axios
      .get(`/api/academy/getAll`)
      .then((response) => {
        const academyData = response.data.data;
        academyData.sort((a, b) => a.name.localeCompare(b.name))
        setAcademy(academyData);
      })

      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }  

  const handleChange =(props)=> (event) => {
      setValues({
        ...values,
        [props]: event.target.value
      });
 };

 const handleMatch= () =>{
  // console.log("******* ",matchCategory,filterValues,selectedCategoryItem,selectedShooterCategoryItem)
  let arr1 = [];
   selectedCategoryItem.value.map((item)=>{
    let a1 =`${filterValues.gameType} ${filterValues.gender} ${item} ${filterValues.teamType} ${filterValues.matchType} ${selectedShooterCategoryItem.value}`
    matchCategory.filter(a=>{if(a.value==a1){arr1.push(a)}return arr1})
  })
  
  let newarr = {value:arr1}
  setSelectedCategory({value:arr1})
  handleFees(newarr)
 }

  const handleFees = (newarr) =>{
    // console.log(newarr)
    // console.log("date now ",new Date().toISOString())
    // console.log("date triple ",particularCompData.cccRegistration)
    // console.log("fees ",particularCompData.fees.individual, particularCompData.fees)
    if(newarr.value.length >0){
      let feesTotal=0
      newarr.value.map((item,index)=>{
        if(item.teamType.toLowerCase() == "individual"){
          if(new Date().toISOString()>particularCompData.cccRegistration){
            feesTotal=feesTotal+(particularCompData.fees.individual*3)
          }else{
            feesTotal=feesTotal+particularCompData.fees.individual
          }
        }else if(item.teamType.toLowerCase() == "team"){
          if(new Date().toISOString()>particularCompData.cccRegistration){
            feesTotal=feesTotal+(particularCompData.fees.team*3)
          }else{
            feesTotal=feesTotal+particularCompData.fees.team
          }
        }else if(item.teamType.toLowerCase() == "mixed"){
          if(new Date().toISOString()>particularCompData.cccRegistration){
            feesTotal=feesTotal+(particularCompData.fees.mixed*3)
          }else{
            feesTotal=feesTotal+particularCompData.fees.mixed
          }
        }
        
        if(newarr.value.length == index+1){
          // console.log(feesTotal)
          setTotalFees(feesTotal)
        }
      })
      setFees(true)
    }
    else{
      toast.error("select atleast one catagory")
    }
  }

  const onChangeHandlerForZip = (e) => {
    try {
      let extension = e.target.files[0].name.split(".")[1];
      if (extension.toLowerCase() !== "png") {
        toast.error("Please upload the image in .png format");
      } else if (e.target.files[0].size >= 1 * 1024 * 1024) {
        toast.error("Image size should be less than 1 MB");
      } else {
        setselectedFile(e.target.files[0]);
      }
    } catch {
      console.log("No File selected");
    }
  };

  const onChangeHandlerForXlsx = (e) => {
    try {
      let extension = e.target.files[0].name.split(".")[1];
      if (extension.toLowerCase() !== "xlsx") {
        toast.error("Please upload in .xlsx format");
      } else if (e.target.files[0].size >= 5 * 1024 * 1024) {
        toast.error("File size should be less than 5 MB");
      } else {
        setselectedFileExcel(e.target.files[0]);
      }
    } catch {
      console.log("No File selected");
    }
  };

  const handleUploadExcel = (data) => {
    if(selectedFileExcel) {
      setisSubmitting(true);
      const data1 = new FormData();
      data1.append("competitionId", competitionId);
      data1.append("userId", values.userName);
      data1.append("file", selectedFileExcel);
      // console.log(user[0].academyId)
      axios
        .post(`/api/userAcademyRequest/uploadFileExcel`, data1, {
          responseType: 'arraybuffer', // Set response type to arraybuffer
        })
        .then(async (res) => {
          if (res.status === 200) {
            setisSubmitting(false)
            setBulkUserUpload(false)
            setselectedFileExcel('')
            const blob = new Blob([res.data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.json');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            toast.success("Bulk user uploaded successfully");
          } else {
            toast.error("Error in uploading the Excel");
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
        });
    } else {
      toast.error("Upload a Excel file");
    }
  };
  const handleUpload = (data) => {
    setisSubmitting(true);
    const data1 = new FormData();
    data1.append("requestId", data);
    data1.append("file", selectedFile);
    // console.log(user[0].academyId)
    axios
      .post(`/api/userAcademyRequest/uploadFile`, data1, {})
      .then((res) => {
        if (res.status === 200) {
          setisSubmitting(false)
          setselectedFile('')
          setAllRegisterDetail([]);
          getAllRegistration();
          setValue({
            ...value,
            tnxId: "",
            userId: '',
            tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
          })
          toast.success(res.data.message);
        } else {
          toast.error("Error in uploading the image");
        }
      })
      .catch((e) => {
        console.log(e.response);
        toast.error(e.response.data.message);
      });
  };

  const handleRegister = () =>{
    // console.log("******* ",selectedCategory,values)
    let data ={}
    let api = ''
    // console.log("selectedFile ",selectedFile , selectedFile!="")
    if(selectedFile && value.tnxId !="") {
      if(userDetails.email === undefined || userDetails.email === null ){
        api = `/api/compPublicUserMapper/add`
        data = {
          userName: values.userName,
          emailId: values.emailId,
          gender: filterValues.gender,
          contactNo: values.contactNo,
          academyId:values.academyName,
          extAcademyName: values.extAcademyName,
          DOB: values.DOB,
          // approvalLevel:{"level0":"pending","level1":"notStarted"} ,
          competitionId: competitionId,
          matchCategorySelected: selectedCategory.value,
          // status:"Registered",
          status:"Academy Approved",
          totalFees : totalFees,
          requestBy: values.userName,
          status: "Academy Approved",
          tnxDate: value.tnxDate,
          tnxId: value.tnxId,
          approvalLevel: {"level0":"approved","level1":"pending"}
        }
      }else{
        api = `/api/compUserMapper/add`
        data = {
          userId: user[0]._id,
          academyId:user[0].academyId,
          // approvalLevel:{"level0":"pending","level1":"notStarted"} ,
          competitionId: competitionId,
          matchCategorySelected: selectedCategory.value,
          // status:"Registered",
          status:"Academy Approved",
          totalFees : totalFees,
          requestBy: user[0]._id,
          status: "Academy Approved",
          tnxDate: value.tnxDate,
          tnxId: value.tnxId,
          approvalLevel: {"level0":"approved","level1":"pending"}
        }
      }

      axios
        .post(api, data)
        .then((response) => {
          // console.log("respo ",response)
          handleUpload(response.data.data._id)
          setisSubmitting(false);
          setFees(false);
          setRegisterUser(false);
          setTotalFees(0);
          setValues({
            userName: '',
            emailId: '',
            academyName: '',
            gender: '',
            contactNo:'',
            stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code
        });
        setFilterValues({ gender: '',
        gameType: '',
        teamType: 'Individual',
        matchType: '',
        category: ''})
        setSelectedCategory({value:[]});
        setSelectedShooterCategoryItem({value:[]})
        setSelectedCategoryItem({value:[]})
          getCompetitions();
          getUserDetail();
          toast.success(`Your registration is success. Your academy need to approve for competition.`);
        })
        .catch((error) => {
          setisSubmitting(false)
          console.log("err", error)
          toast.error(error.response.data);
        });
    }
    else{
      toast.error("Fill all the fields and upload the screenshot");
    }
  }

  const handleChangeInput = (props) => (event) => {
    setValue({...value, [props]:event.target.value});
};

  const display =()=>{
    return(
      <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>  
      <Stack direction="row" justifyContent="space-between">
      <Typography sx={{ }} variant="h4" align="center">
        Competition Info 
      </Typography>
      <Button sx={{color:"black"}} onClick={()=>{setcompetitionInfo(false)}} startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />} />
      </Stack>

      <Table>
        <TableBody>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Name</Typography></TableCell>
            <TableCell align="left">{particularCompData.name}</TableCell>
          </TableRow>
          {window.location.href.indexOf("public") > -1?null:
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Public Url Link</Typography></TableCell>
            <TableCell align="left"><a href={"https://intellishoot.com/public/competition/"+particularCompData._id} target="_blank">{"https://intellishoot.com/public/competition/"+particularCompData._id}</a></TableCell>
          </TableRow>}
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Description</Typography></TableCell>
            <TableCell align="left">{particularCompData.description != undefined ? <Markdown>{particularCompData.description}</Markdown> : ''}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Email</Typography></TableCell>
            <TableCell align="left">{particularCompData.email != undefined ? particularCompData.email : ''}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Contact No</Typography></TableCell>
            <TableCell align="left">{particularCompData.contactNo != undefined ? particularCompData.contactNo : ''}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Competition From</Typography></TableCell>
            <TableCell align="left">{particularCompData.competitionFrom !=undefined ?particularCompData.competitionFrom.split("T")[0]:"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Competition To</Typography></TableCell>
            <TableCell align="left">{particularCompData.competitionTo !=undefined ?particularCompData.competitionTo.split("T")[0] :"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Registration Starts</Typography></TableCell>
            <TableCell align="left">{particularCompData.registrationStarts != undefined ?particularCompData.registrationStarts.split("T")[0] :"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Registration Ends</Typography></TableCell>
            <TableCell align="left">{particularCompData.registrationEnds != undefined ?particularCompData.registrationEnds.split("T")[0] :"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Triple Fees Date</Typography></TableCell>
            <TableCell align="left">{particularCompData.cccRegistration != undefined ?particularCompData.cccRegistration.split("T")[0] :"-"}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Category</Typography></TableCell>
            <TableCell align="left">{particularCompData.category.join(', ')}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Game Type </Typography></TableCell>
            <TableCell align="left">{particularCompData.gameType.join(', ')}</TableCell>
          </TableRow>      
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Gender</Typography></TableCell>
            <TableCell align="left">{particularCompData.gender.join(', ')}</TableCell>
          </TableRow>
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Competition Level </Typography></TableCell>
            <TableCell align="left">{particularCompData.level}</TableCell>
          </TableRow>      
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Match Type</Typography></TableCell>
            <TableCell align="left">{particularCompData.matchType.join(', ')}</TableCell>
          </TableRow>  
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Team type</Typography></TableCell>
            <TableCell align="left">{particularCompData.teamType.join(', ')}</TableCell>
          </TableRow>  
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Location</Typography></TableCell>
            <TableCell align="left">{particularCompData.location}</TableCell>
          </TableRow>  
          <TableRow tabIndex={-1}>
            <TableCell align="left"><Typography variant="subtitle1" gutterBottom>Status</Typography></TableCell>
            <TableCell align="left">{particularCompData.status}</TableCell>
          </TableRow>  
        </TableBody>
      </Table>  
      </Stack>
    )}

  const regDetail =()=>{
    return(
      <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>  
      <Stack direction="row" justifyContent="space-between">
      <Typography sx={{ }} variant="body1" align="center">
         Registration Info 
      </Typography>
      <Button sx={{color:"black"}} onClick={()=>{setRegModel(false)}} startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />} />
      </Stack>
      <CompetitionUserRequest />
      </Stack>
  )}

  const resultDetail =()=>{
    return(
      <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>  
      <Stack direction="row" justifyContent="space-between">
      <Typography sx={{ }} variant="h4" align="center">
        Result
      </Typography>
      <Button sx={{color:"black"}} onClick={()=>{setResult(false)}} startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />} />
      </Stack>
      <CompetitionDashboardResult />
      </Stack>
  )}

  return (
    <Page title="Competition Board">
      <Toast />
      <Container> 
      <Typography sx={{ }} variant="h3" align="center">
        {particularCompData.name != undefined ?particularCompData.name.toUpperCase():null}  
      </Typography>  
      {competitionInfo != true && regModel != true && result !=true ?<div>
      <Stack direction={{md:"row",sm:"row",xs:'column'}} mb={5}>  
        <Box sx={{mt:2,width:{md:"30%",xs:"100%",sm:"35%"}}}>
        <Card sx={{ height: { md: '22vh', sm: '16vh', xs: '18vh' } }}>
                <CardHeader
                    title="Competition Info"
                    />
                  <CardContent>
                    <Stack direction={{ md: 'column', sm: 'column', xs: 'column' }}>
                      <Button
                        sx={{ cursor: 'pointer', mt: 0 }}
                        onClick={() => {
                          setcompetitionInfo(true);
                        }}
                        startIcon={<Iconify icon="mdi:information-variant" />}
                      >
                        Info
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
        </Box>

        <Box sx={{mt:2,width:{md:"30%",xs:"100%",sm:"35%"},ml:{md:2,sm:2,xs:0}}}>
        <Card sx={{ height: { md: '22vh', sm: '16vh', xs: '18vh' } }}> 
        <CardHeader
                    title="Registration"
                    action={userRole.includes("coach") ? <div>
                      <Iconify icon="mdi:microsoft-excel" onClick={()=>{
                        window.open(`/CompetitionEntry.xlsx`, "_blank")
                      }}/>
                      <Iconify icon="system-uicons:file-upload" style={{cursor: "pointer"}} onClick={()=>{
                        setBulkUserUpload(true)
                      }}/>
                      </div>: null}
                  />
        <CardContent>
          <Stack direction={{md:"column",sm:"column",xs:'column'}}>
          
          {/* {userRole.includes("student") ? registerDetail.length > 0 ? 
          <Typography sx={{mt:1 }} variant="caption" align="center" color={registerDetail[0].status.toUpperCase() == 'DECLINED'?"red":"green"} >
             {registerDetail[0].status.toUpperCase()}             
          </Typography>
          : particularCompData.registrationEnds !=undefined && new Date().toLocaleDateString() > new Date(particularCompData.registrationEnds).toLocaleDateString() ?
           <Typography sx={{mt:1 }} variant="caption" align="center" color={"blue"} >
            Closed
            </Typography>
          :
          ((new Date().toLocaleDateString() >= new Date(particularCompData.registrationStarts).toLocaleDateString()) && (new Date().toLocaleDateString() < new Date(particularCompData.registrationEnds).toLocaleDateString())) ?
          <Button sx={{cursor:"pointer",mt:0}} onClick={()=>{setRegisterUser(true)}} startIcon={<Iconify icon="material-symbols:person-add-outline-rounded" />}>
            Register</Button> :
            <Typography sx={{mt:1 }} variant="caption" align="center" color={"blue"} >
            Registration yet to start
            </Typography>
            :null} */}
          {/* {console.log(new Date() , userRole,new Date(particularCompData.registrationStarts), new Date() , new Date(particularCompData.competitionTo))} */}
          {userRole.includes("student") || userRole.includes("competition") ? registerDetail.length > 0 ? 
            <Typography sx={{mt:1 }} variant="caption" align="center" color={registerDetail[0].status.toUpperCase() == 'DECLINED'?"red":"green"} >
              {registerDetail[0].status.toUpperCase()}             
            </Typography>
            :
            ((new Date() >= new Date(particularCompData.registrationStarts)) && (new Date() < new Date(particularCompData.competitionTo))) ?
            <Button sx={{cursor:"pointer",mt:0}} onClick={()=>{setRegisterUser(true)}} startIcon={<Iconify icon="material-symbols:person-add-outline-rounded" />}>
              Register</Button> :
              ((new Date() < new Date(particularCompData.competitionTo)))?
              <Typography sx={{mt:1 }} variant="caption" align="center" color={"blue"} >
                Competition over
              </Typography>
              :
              <Typography sx={{mt:1 }} variant="caption" align="center" color={"blue"} >
              Registration yet to start
              </Typography>
          :null}

          {userRole.includes("coach") ?
            <Button sx={{cursor:"pointer",mt:0}} onClick={()=>{setRegModel(true)}} startIcon={<Iconify icon="mdi:information-variant" />}>
            Info</Button>
          : null
          }
          </Stack>
        </CardContent>
        </Card>
      </Box>

      <Box sx={{mt:2,width:{md:"30%",xs:"100%",sm:"35%"},ml:{md:2,sm:2,xs:0}}}>
        <Card sx={{ height: { md: '22vh', sm: '16vh', xs: '18vh' } }}>
                <CardHeader
                    title="Result"
                    />
        <CardContent>
          <Stack direction={{md:"column",sm:"column",xs:'column'}}>
          <Button sx={{mt:0,cursor:"pointer"}} onClick={()=>{setResult(true)}} startIcon={<Iconify icon="ph:list-dashes" />}>
            </Button>            
          <Typography sx={{ }} variant="h6" align="center">
          <a href={"https://intellishoot.com/"+particularCompData._id+"/livescore"} target="_blank">Live Score</a>  
          </Typography>  
          </Stack>
        </CardContent>
        </Card>
      </Box>
      </Stack>
       {/* {window.location.href.indexOf("public") > -1? */}
       <Markdown >
       {particularCompData.description != '' && particularCompData.description != undefined ?particularCompData.description:'<p></p>'}  
     </Markdown>
     </div>

      :null}

     
      
      {competitionInfo == true && regModel !=true && result !=true ?
      display() :null }

      {competitionInfo != true && regModel ==true && result !=true ?
      regDetail() :null}

      {competitionInfo != true && regModel !=true && result ==true ?
      resultDetail() :null}

      <Modal
          open={bulkUserUpload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >         
          <Box
            component="form"
            sx = {{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    width:'90%',
                }}
            noValidate
            autoComplete="off"
          >
            <div>
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} mt={2}>
              Upload Excel for bulk upload
          </Typography>
              <input
                accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForXlsx}
                type="file"
                name="logo"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFileExcel ? selectedFileExcel.name : <>Select Excel</>}
                </Typography>
                </label>

              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                <Button variant="outlined" onClick={handleUploadExcel}>Yes</Button>
                <Button variant="outlined" color="error" sx={{ml:2}} onClick={()=>{
                  setBulkUserUpload(false)
                }} >No</Button>
              </Stack> 
              </div>
      </Box>
      </Modal>
      

      <Modal
          open={registerUser}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >         
          <Box
            component="form"
            sx = {{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    width:'90%',
                }}
            noValidate
            autoComplete="off"
          >
            
            {registerDetail.length > 0 ?
              <Stack direction="column" >
              <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                Your competition registration is waiting for your academy approval. So please contact your academy.
              </Typography>
              <Button variant="outlined" color="error" onClick={handleClose} >Close</Button>
              </Stack>
            :
            fees == true ? <div>
            <Scrollbar style={{ height: "62vh", width: "98.5%", paddingRight: "1rem" }} >
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
              Categories selected 
            </Typography> 
            <Typography id="modal-modal-title" variant="caption" component="h2" mb={2}>
                {selectedCategory.value.map((item)=>{return item.value +", " })}
            </Typography> 
            <Typography id="modal-modal-title" variant="h4" component="h2" mb={2}>
                Total amount to be paid rs.{totalFees}
            </Typography> 
            <Markdown mb={2}>
              {particularCompData.paymentMethod != '' && particularCompData.paymentMethod != undefined ?particularCompData.paymentMethod:'<p></p>'}  
            </Markdown>             
            {/* {console.log(selectedCategory.value)} */}
            <Typography id="modal-modal-title" variant="h6" component="h2" mt={2} >
                Enter the Transaction Detail's
            </Typography> 
            <TextField
            id = "filled-flexible"
            defaultValue = {value.tnxId}
            value={value.tnxId}
            varient="standard"
            label = "Transaction Id"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('tnxId')}
            />
            <TextField
              id="datetime-local"
              label="Transaction Date"
              type="date"
              value={value.tnxDate}  
              sx={{mt: { md: 2, xs: 2,sm:2 }}}
              onChange={handleChangeInput('tnxDate')}
              InputProps = {
                {
                  inputProps: {
                    max: `${(new Date(new Date() - (new Date()).getTimezoneOffset()*60000)).toISOString().split('T')[0]}`
                  }
                }
              }
            /> 
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} mt={2}>
              Upload Transaction screenshot
          </Typography>
              <input
                accept = "image/png"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="logo"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFile ? selectedFile.name : <>select screenshot</>}
                </Typography>
                </label>
                </Scrollbar>
            <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
              <Button variant="outlined" onClick={handleRegister}>Yes</Button>
              <Button variant="outlined" color="error" sx={{ml:2}} onClick={()=>{setFees(false);setSelectedCategoryItem(selectedCategoryItem);setSelectedShooterCategoryItem(selectedShooterCategoryItem);setSelectedCategory(selectedCategory)
                     setFilterValues(filterValues)}} >No</Button> 
            </Stack>         
            </div>:<div>
            <Scrollbar style={{ height: "55vh", width: "98.5%", paddingRight: "1rem" }} >
            {userDetails.email === undefined || userDetails.email === null ? <div>
            <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
            <TextField
              required
              id = "outlined-required"
              defaultValue = {values.userName}
              value={values.userName}
              varient="standard"
              label="Shooter Full Name"
              type = 'text'
              onChange={handleChange('userName')}
              />
              <TextField
              required
              id = "outlined-required"
              defaultValue = {values.emailId}
              value = {values.emailId}
              label="Email Id"
              varient="standard"
              type='text'
              onChange={handleChange('emailId')}
              sx={{mt:2}}
              />
              <Stack direction="row">
                <TextField
                  id = "outlined-select-course"
                  select
                  label = "code"
                  value={values.stdCode !=""?values.stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code}
                  onChange={handleChange('stdCode')}
                  sx={{mt:2,width:"25%"}}
                >
                {
                countryPincode.map((option) => (
                <MenuItem key={option.iso2} value={option.code}>
                    +{option.code}
                </MenuItem>
                ))}
              </TextField>
              <TextField
              required
              id = "outlined-required"
              defaultValue = {values.contactNo}
              value = {values.contactNo}
              label="Contact No"
              varient="standard"
              type='number'
              onChange={handleChange('contactNo')}
              sx={{mt:2,ml:1,width:{md:'75%',xs:'79%',sm:'82%'}}}
              />
            </Stack>
            <TextField
                id = "outlined-select-course"
                select
                label = "Academy"
                value={values.academyName}
                onChange={handleChange('academyName')}
                // helperText="Select your Academy"
                sx={{mt:2}}
              >
                {
                academy.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                    {option.displayName}
                </MenuItem>
                ))}
            </TextField>
            <TextField
              required
              id = "outlined-required"
              defaultValue = {values.DOB}
              value = {values.DOB}
              label="Date of Birth"
              varient="standard"
              type='date'
              inputProps={{
                // min: "2020-08-10",
                max: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
              }}
              onChange={handleChange('DOB')}
              sx={{mt:2,ml:1,width:{md:'99%',xs:'99%',sm:'99%'}}}
              />
            {values.academyName == '65f1a6f5cbef7f543e2bda92' ?
              <TextField
                required
                id="outlined-required"
                defaultValue={values.extAcademyName}
                value={values.extAcademyName}
                varient="standard"
                label="Academy name"
                type="text"
                onChange={handleChange('extAcademyName')}
                sx={{ mt: 2 }}
              /> 
                : null}
            {/* <TextField
                id = "outlined-select-course"
                select
                label = "Gender"
                value={values.gender}
                onChange={handleChange('gender')}
                // helperText="Select your Student Level"
                sx={{mt:2}}
              >
                {
                gender.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
                ))}
            </TextField> */}
            </Stack>
            </div>
            :null}
            <Typography id="modal-modal-title" variant="h6" component="h2" mt={1}>
                Select the matches and confirmation that you want to play
            </Typography>
            <div>
        <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Game Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gameType"
              value={filterValues.gameType}
              onChange={handleRadio}
            >
              {
                particularCompData.gameType != undefined && particularCompData.gameType.map((item) => {
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>
        </Box>    
        <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Match Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="matchType"
              value={filterValues.matchType}
              onChange={handleRadio}
            >
              {
                particularCompData.matchType != undefined && particularCompData.matchType.map((item) => {
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>
        </Box>

         <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gender"
              value={filterValues.gender}
              onChange={handleRadio}
            >
              {
                particularCompData.gender != undefined && particularCompData.gender.map((item)=>{
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>               
        </Box>
        {/* <Box m={2}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Team Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="teamType"
              value={filterValues.teamType}
              onChange={handleRadio}
            >
              {
                particularCompData.teamType != undefined && particularCompData.teamType.map((item) => {
                  return (
                    <FormControlLabel value={item} control={<Radio />} label={item}/>
                  )
                })
              }
            </RadioGroup>
          </FormControl>
        </Box> */}
        <FormLabel id="demo-row-radio-buttons-group-label">Match Type</FormLabel>
        <Autocomplete
          id = "disable-clearable"
          multiple
          disableClearable
          disableCloseOnSelect
          defaultValue={selectedCategoryItem.value}
          options={particularCompData.category}
          getOptionLabel={(option) => {return option}}
          onInputChange={(event, newInputValue) => {     
          }}
          onChange={(event, newValue) => {      
            setSelectedCategoryItem({
              ...selectedCategoryItem,
               value:newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="Match type" sx={{mt:2, mb:2}}/>}
        />
        {/* {console.log("*********** ",particularCompData)} */}
        <FormLabel id="demo-row-radio-buttons-group-label">Shooter Category</FormLabel>
        <Autocomplete
          id = "disable-clearable"
          // multiple
          disableClearable
          disableCloseOnSelect
          defaultValue={selectedShooterCategoryItem.value}
          options={particularCompData.shooterCategory}
          getOptionLabel={(option) => {return option}}
          onInputChange={(event, newInputValue) => {     
          }}
          onChange={(event, newValue) => {      
            setSelectedShooterCategoryItem({
              ...selectedShooterCategoryItem,
               value:newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="Shooter Category" sx={{mt:2, mb:2}}/>}
        />
        </div>
             {/* <Autocomplete
                id = "disable-clearable"
                multiple
                disableClearable
                disableCloseOnSelect
                defaultValue={selectedCategory.value}
                options={matchCategory}
                getOptionLabel={(option) => {return option.value}}
                onInputChange={(event, newInputValue) => {     
                }}
                onChange={(event, newValue) => {      
                  setSelectedCategory({
                    ...selectedCategory,
                    ['value']: newValue
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Match type" sx={{mt:2}}/>}
              />    */}
              </Scrollbar>
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                <Button variant="outlined" onClick={handleMatch}>Yes</Button>
                <Button variant="outlined" color="error" sx={{ml:2}} onClick={handleClose} >No</Button>
              </Stack> 
              </div>
            }
          </Box>
      </Modal>
        {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )}
      </Container>
    </Page>
  );
}

 
export default competitionDashboard;