// @mui
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {
    Card,
    Box,CardHeader
} from '@mui/material';

MatchAreaChart.propTypes = {
    Details: PropTypes.string,
    seriesName: PropTypes.string
};

export default function MatchAreaChart({
    Details,
    seriesName,name
}) {
    const series = [{
        name: seriesName,
        data: name === 'series' ? Details.map(o => o.overAllScore) : Details.map(o => o.score)
    }]
    const options = {
        chart: {
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'string',
            categories: Details.map(o => o.name.replace("_", " "))
        },
    }
    return (
        <Card>
            {name === 'series' ? <CardHeader title="Series Over All Score" /> 
            :<CardHeader title="Shot Details" />}
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart options={options} series={series} type="area" height={350} />
            </Box>
        </Card>
    );
}
