import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box,
  Grid,Card,
  Table,TableCell,TableBody,TableContainer,TableHead,TableRow ,Paper 
} from '@mui/material';
// components
import Page from '../components/Page';
import Markdown from 'markdown-to-jsx';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {userDetails} from '../constants'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AcademyHome = () => {

  const [user, setUser] = React.useState([]);
  const [academyDetails, setAcademyDetails] = React.useState([]);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step) => {
      setActiveStep(step);
    };

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
     axios
       .post(`/api/user/getParticularUserByEmail`, {
         "emailID":userDetails.email
       })
       .then((response) => {
         const userData = response.data.data;
         getDetails(userData[0].academyId)
         setUser(userData);
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }

  const getDetails = (id) => {
    axios
      .post(`/api/academy/getParticularAcademy`, {
        "_id": id
      })
      .then((response) => {
        const details = response.data.data;
        setAcademyDetails(details);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }  

  return (
    <Page title="AcademyHome">
      < Toast />
      <Container>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={12} md={6}>
            <Card sx={{maxHeight:"35vh"}}>
              <Typography variant="h6" gutterBottom ml={1}>Announcement</Typography>
              <Scrollbar style={{ height: "30vh", paddingRight: "4rem" }}>
                {academyDetails.length >0 && academyDetails[0].announcement !==undefined && academyDetails[0].announcement.length >0 ?
                academyDetails[0].announcement.map((item,index)=>{
                  return <Markdown style={{marginLeft:"2%"}}>{item.note}</Markdown>
                }) : <p style={{marginLeft:'1%'}}>No Announcements</p>}
              </Scrollbar>
            </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <Card sx={{maxHeight:"35vh"}}>
               <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
              {academyDetails.length > 0 && academyDetails[0].image !== undefined && academyDetails[0].image.length > 0 ?
                <AutoPlaySwipeableViews
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {academyDetails[0].image.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Box
                          component="img"
                          sx={{
                            height: {md:230,xs:255,sm:255},
                            display: 'block',
                            maxWidth: 500,
                            overflow: 'hidden',
                            width: '100%',
                          }}
                          src={`${step.imagePath}?auto=format&fit=crop&w=400&h=250&q=60`}
                          alt="No Image"
                        />
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
                :<Scrollbar style={{ height: "35vh", paddingRight: "4rem" }}><Typography variant="h6" gutterBottom ml={1}>Gallery</Typography><p style={{marginLeft:"3%"}}>No Images</p></Scrollbar>}
              </Box>
            </Card>
            </Grid>
          </Grid>

        <Grid style={{ width:'100%' }} xs={12} sm={12} md={12}>
            <Card>
              <Typography variant="h6" gutterBottom ml={1}>About</Typography>
              <Scrollbar style={{ height: "25vh", paddingRight: "4rem" }}>
               {academyDetails.length >0 && academyDetails[0].about !==undefined ?
               <Markdown style={{marginLeft:"2%"}}>{academyDetails[0].about}</Markdown>
              :<p style={{marginLeft:'1%'}}>No Data</p>}
              </Scrollbar>
            </Card>
          </Grid>
      </Container>
    </Page>
  );
}
export default AcademyHome;