import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import { useState, useEffect } from 'react';
// material
import {
    Container,
    Typography, 
    Grid, TextField, MenuItem,Card,Stack,
    Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Toast from "../components/Toast";
import {Range,DateFilter,userDetails} from '../constants'; 
import Scrollbar from "react-scrollbar";

const AcademyDashboard = () => {

  const [user, setUser] = React.useState([]);
  const [match, setMatch] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [values, setValues] = React.useState({
    date: 30,
  });
  const [totalUser, setTotalUser] = React.useState([])
  const [academyUsers, setAcademyUsers] = React.useState([])
 
  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    axios
      .post(`/api/user/getParticularUserByEmail`,{"emailID":userDetails.email})
      .then((response) => {
        setUser(response.data.data)
        // response.data.data.contactNo == undefined || user.contactNo == ""?toast.error("Update your contact number in your profile to get the notification in telegram"):null
        getMatches(response.data.data[0]._id)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getMatches = (userId) => {
     axios
       .post(`/api/user/academyUserDetails`, {
         emailId: userDetails.email,
         userId:userId
       })
       .then((response) => {
         let data = response.data.data;
         setTotalUser(data.length)
         setAcademyUsers(data)

       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }

   const handleChange = (props) => (event) => {
     const val = values
     if (props === 'date') {
       val.date = event.target.value
     } 
     setValues({
       ...values,
       [props]: event.target.value
     });
     getMatches(val)
   };

   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(parseInt(event.target.value, 10));
     setPage(0);
   };

  return (
    <Page title="ShooterDashboard">
      <Toast />
      <Container maxWidth="xl">
        <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Academy performance analysis             
          </Typography> 
          <Typography variant="h6" gutterBottom>
                  Total User - {totalUser}
            </Typography>
          </Grid>
           {/* <Grid item xs={6} sm={6} md={2} >
          <TextField
                id = "outlined-select-course"
                select
                label = "Date"
                value={values.date}
                onChange={handleChange('date')}
                variant = "standard"
                style={{align:"right"}}
              >
                {
                  DateFilter.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </Grid> */}
        </Grid>
        <Grid>
        <Scrollbar>
        <Card sx={{}}>          
            <TableContainer component={Paper}  sx={{}}>
              <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                  <TableCell align="left">User ID</TableCell>
                  <TableCell align="left">User Name</TableCell>
                  <TableCell align="left">Course</TableCell>
                  <TableCell align="left">Level</TableCell>
                  <TableCell align="left">Matches</TableCell>
                  <TableCell align="left">Last Update</TableCell>
                  <TableCell align="left">Attendance</TableCell>
                  <TableCell align="left">Match Feedback</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {academyUsers.length>0 && academyUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                   <TableRow tabIndex={-1} sx={{cursor: "pointer"}} onClick={() => {
                      window.location.assign(`/dashboard/match/user/${row._id.user}`)
                    }}> 
                    <TableCell align="left">{row.userId}</TableCell>
                    <TableCell align="left">{row._id.displayName}</TableCell>
                    <TableCell align="left">AIR {row.courseType[0].toUpperCase().replace("_"," ")}</TableCell>
                    <TableCell align="left">{row.studentLevel[0].toUpperCase()}</TableCell>
                    <TableCell align="left">{row.totalMatch}</TableCell>
                    <TableCell align="left">{row.totalMatch>0 ?row.lastUpdate[0][0].split("T")[0]:"-"}</TableCell>
                    <TableCell align="left">{row.attendance}</TableCell>
                    <TableCell align="left">{row.commentCount[0]}</TableCell>
                    </TableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={7}
              count={academyUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}                      
            />
          </Card>
        </Scrollbar>
        </Grid>
      </Container>
    </Page>
  );
}
export default AcademyDashboard;