import * as React from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// material
import {Button, Typography, Container, Box} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import Logo from '../components/Logo';
import "./style.css"


const attendance = () => {

  const { rfidID } = useParams();
  const [time, setTime] = React.useState(`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[2]}`);
  const [dataUpdate, setDataUpdate] = React.useState(false)

  useEffect(() => {
    updateAttendance();
  }, []);

  const updateAttendance = () => {
    axios
      .post(`/api/attendance/add`, {
        "rfidID": rfidID,
        "timeIn": time,
        "timeOut": '',
        "type": 'web'
      })
      .then((response) => {
        // console.log(response.data)
        if (response.data.status === true){
          setDataUpdate(true)
        }
        
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const ContentStyle = styled('div')(({
    theme
  }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }));

  return (
    <Page title="Attendance Report">
      <Toast />
      <Container>        
          <Logo disabledLink={true} user={undefined}/>
          <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          {
            dataUpdate === false ?
              <div><Typography variant="h3" paragraph>
                Data update is inprogress !!!
              </Typography>

              <Box
                component="img"
                src = "/static/illustrations/illustration_register.png"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
              </div>
            :
              <div><Typography variant="h3" paragraph>
                Data Updated Successfully !!!
              </Typography>

              <Box
                component="img"
                src="/static/illustrations/completed.jpg"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Go to Home
              </Button>
              </div>
          }          
        </ContentStyle>
      </Container>
    </Page>
  );
}

 
export default attendance;