import * as React from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect,useRef } from 'react';
// material
import {
  Container,Stack,Grid,Button,Card,CardContent,Typography,Modal,Box,TextField,Autocomplete,
  Table,TableRow,TableCell
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import Logo from '../components/Logo';
import "./style.css"
import {url,userDetails} from '../constants'
import CircularProgress from '@mui/material/CircularProgress';


const LiveScoreCard = () => {

  const { competitionId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [model, setModel] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const [dropdownlist, setdropdownlist] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [particularCompData, setParticularCompData] = React.useState([]);
  const [userRole, setUserRole] = React.useState(['competition']);
  const [mapModel, setMapModel] = React.useState(false);
  const [mapping, setMapping] = React.useState({});
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

  useEffect(() => {
    getCompetitionExcel()
    getCompetitionDetail()
    getUserDetail()
}, []);

const getCompetitionExcel = () =>{
  axios
    .post(`/api/competition/getLiveScreenData`, {
      _id: competitionId,
    })
    .then((response) => {
      // console.log(response.data)
      let arr = []
      response.data.map((item,index)=>{
        arr.push(item.filename.split('.')[0])
        // console.log("**",selectedIndex)
        if(selectedIndex !=undefined && selectedIndex !='' && selectedIndex == item.filename){
          setSelectedItem(item)
        }
      })
      setdropdownlist(arr)
      readMapName(arr)
      setDetails(response.data)
    })
    .catch((error) => {
      // console.log(error)
      //  toast.error(error.response.data);
    });
}

const getCompetitionDetail = () =>{
  axios
    .post(`/api/competition/getParticularCompetition`, {
      _id: competitionId,
    })
    .then((response) => {
     //  console.log(response.data.data)
      const res = response.data.data
       setParticularCompData(res[0]);
    })
    .catch((error) => {
      toast.error(error.response.data);
    });
  }

useEffect(() => {
  // Only perform the refresh logic for the 7th tab (index 6)
    const intervalId = setInterval(() => {
      // Refresh logic for the 7th tab
      console.log('Refreshing tab 7 every 30 seconds');
      getCompetitionExcel()
    }, 30000); // 30 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount or tab change
}, [selectedIndex]);

const getUserDetail = () => {
  if (userDetails.email === undefined){
    setUserRole(['competition'])
  }else{
    axios
      .post(`/api/user/getParticularUserByEmail`, {
        "emailID": userDetails.email
      })
      .then((response) => {
        let userData = [];
        if(response.data.data.length >0){
          userData = response.data.data;
          setUserRole(userData[0].role);
        }
        
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }
}

const handleChangeData = (props) => (event) => {
  // console.log(props, event.target.value)
  setMapping({
    ...mapping,
    [props]: event.target.value
  });
};

const readMapName = (arr)=>{
  axios
  .post(`/api/competition/readMappingFile`, {
    id: competitionId
  })
  .then((response) => {
    // console.log(response.data.data,arr)  
    let data= response.data.data
    arr.forEach(key => {
      if (!(key in data)) {
        data[key] = "";
      }
    }); 
    // console.log("mapping data ",data)
    const sortedObject = Object.fromEntries(
      Object.entries(data)
        .sort(([keyA, valueA], [keyB, valueB]) => {
          const valueALower = (valueA || '' ).toLowerCase();
          const valueBLower = (valueB || '').toLowerCase();
    
          if (valueALower === '' && valueBLower !== '') {
            return 1;
          } else if (valueALower !== '' && valueBLower === '') {
            return -1;
          }
    
          return valueALower.localeCompare(valueBLower);
        })
    );
    // console.log(data," **** ",sortedObject)
    setMapping(data)
  })
  .catch((error) => {
    console.log(error)
    toast.error(error.response.data);
  });
}

const handleMapName = ()=>{
  // console.log("**** ",mapping)
  axios
  .post(`/api/competition/writeMappingFile`, {
    id: competitionId,
    data:mapping
  })
  .then((response) => {
    console.log(response.data.data)  
    setMapModel(false)      
  })
  .catch((error) => {
    console.log(error)
    toast.error(error.response.data);
  });
}

const deleteAllLiveScore = () =>{
  setisSubmitting(false)
  axios
    .post(`/api/competition/compLiveScreen/deleteAllCompLiveScreen`, {
      compId: competitionId,
    })
    .then((response) => {
      // console.log(response.data)
      setMapping({})
      setisSubmitting(false)
      setDeleteConfirmation(false)
      setdropdownlist([])
      setDetails([])
    })
    .catch((error) => {
      setisSubmitting(false)
      // console.log(error)
      //  toast.error(error.response.data);
    });
}

  return (
    <Page title="Livescorecard">
      <Toast />
      <Container>

      {window.location.href.indexOf("livescore") > -1?
        <Stack direction={{md:"column",xs:'column',sm:'column'}} alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h3" >
        <span>Live Score Tracking</span>
        </Typography> 
        </Stack>
        :null}

      {window.location.href.indexOf("livescore") > -1?
        <Stack direction={{md:"column",xs:'column',sm:'column'}} alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h5" >
        <span>{particularCompData.name}</span>
        </Typography> 
        <Typography variant="h6" gutterBottom>
        <span>{particularCompData.displayName}</span>
        </Typography>        
        
        </Stack>
        :null}

      {details.length >0 ? <Stack direction="row" useFlexGap flexWrap="wrap" mb={2}>
        <Autocomplete
          id = "disable-clearable"
          multiple
          disableClearable
          disableCloseOnSelect
          // defaultValue={dropdownlist}
          options={dropdownlist}
          getOptionLabel={(option) => {return option}}
          // inputValue={values.type1}
          onInputChange={(event, newInputValue) => {
               
          }}
          onChange={(event, newValue) => {       
            setSelectedValue(newValue);
          }}
          sx={{width:{md:'80%',xs:'65%',sm:'70%'}}}
          renderInput={(params) => <TextField {...params} label="Lane" sx={{mt:2}}/>}
        /> 
        {window.location.href.indexOf("livescore") == -1? <Iconify
            icon = "fluent-mdl2:open-in-new-tab"
            sx={{ '&:hover': { cursor: 'pointer', color: 'blue' },mt:3,ml:2 }}
            width={32}
            height={28}
            onClick={() => {window.open(`${url}/${competitionId}/livescore`, '_blank')}}
          /> :null}
          {userRole.includes('coach') ? <Iconify
            icon = "ph:article-medium-bold"
            sx={{ '&:hover': { cursor: 'pointer', color: 'blue' },mt:3,ml:2 }}
            width={32}
            height={28}
            onClick={() => {setMapModel(true)}}
          /> :null}
          {window.location.href.indexOf("livescore") == -1 && (userRole.includes('coach') || userRole.includes('superAdmin')) ? <Iconify
            icon = "tdesign:clear-formatting"
            sx={{ '&:hover': { cursor: 'pointer', color: 'blue' },mt:3,ml:2 }}
            width={32}
            height={28}
            onClick={() => {setDeleteConfirmation(true)}}
          /> :null}
        </Stack> :null}
      <Grid container rowSpacing={1} >
        
      {details.length >0 && details.map((item,index)=>{
        return(
          selectedValue.length >0 ?
          selectedValue.includes(item.filename.split(".")[0])?
            <Box sx={{mt:2}}>
            <Grid item key={index+1} sx={{mr:'5%'}}>
            <Typography variant="body2" sx={{ mb: 1 ,textAlign:"center"}} component="div">
            { item.filename != undefined ?mapping[item.filename.split(".")[0]]==""|| mapping[item.filename.split(".")[0]]==undefined?item.filename.split(".")[0]:mapping[item.filename.split(".")[0]]:null}
            </Typography>
            <Box component="img" src={`data:image/png;base64,${item.data}`} alt="Intellishoot" sx={{ width: 350, height: 300}} onClick={()=>{setSelectedItem(item);setSelectedIndex(item.filename);setModel(true)}}/>
            </Grid>
            </Box>:null
          :
            <Box sx={{mt:2}}>
            <Grid item key={index+1} sx={{mr:'5%'}}>
            <Typography variant="body2" sx={{ mb: 1 ,textAlign:"center"}} component="div">
            
            { item.filename != undefined ?mapping[item.filename.split(".")[0]]=="" || mapping[item.filename.split(".")[0]]==undefined?item.filename.split(".")[0]:mapping[item.filename.split(".")[0]]:null}
            </Typography>
            <Box component="img" src={`data:image/png;base64,${item.data}`} alt="Intellishoot" sx={{ width: 350, height: 300}} onClick={()=>{setSelectedItem(item);setSelectedIndex(item.filename);setModel(true)}}/>
            </Grid>
            </Box>
        )
      })}
      {details.length == 0 ?
        <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'12%'},marginLeft:{sm:'20%',md:'29%'}}}>
        <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No data</Typography> 
        <img src="/static/illustrations/empty_bin.png" alt="No Details"  style={{width:'300px'}}/>
      </Box>
      :null}   
      </Grid>
      <Modal
        open={model}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >         
        <Box
          component="form"
          sx = {{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,width:'90%'
              }}
          noValidate
          autoComplete="off"
        >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" sx={{ mb: 1 ,textAlign:"center"}} component="div">
          { selectedItem.filename != undefined ?mapping[selectedItem.filename.split(".")[0]]=="" || mapping[selectedItem.filename.split(".")[0]]==undefined?selectedItem.filename.split(".")[0]:mapping[selectedItem.filename.split(".")[0]]:null}
          </Typography>
          <Iconify
            icon = "mingcute:close-fill"
            sx={{ '&:hover': { cursor: 'pointer', color: 'red' } }}
            width={32}
            height={28}
            onClick={() => {setModel(false)}}
          />
        </Stack>
        <Box component="img" src={`data:image/png;base64,${selectedItem.data}`} alt="Intellishoot" sx={{ width:{md: 1250,xs:350,sm:600}, height: {md:500,xs:450,sm:550}}}/>
        
        </Box>
      </Modal>

      <Modal
        open={mapModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >         
        <Box
          component="form"
          sx = {{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,width:'70%'
              }}
          noValidate
          autoComplete="off"
        >
        <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'},mb:2}} >Lane-Display Name Mapping</Typography>  
        <Scrollbar style={{ height: "61vh", width: "100%", paddingRight: "1rem" }} > 
        <Table sx={{ }} size="small" aria-label="a dense table" >
        {Object.keys(mapping).map((item,index)=>{
          return (         
            // <Stack direction="row" alignItems="center" mb={2}>
               <TableRow >
               <TableCell align="left" >
                 <Typography variant="body1" sx={{marginLeft:{xs:'0%',sm:'1%',md:'15%'}}} >{item.toUpperCase()}</Typography> 
               </TableCell>
               <TableCell align="left" >            
                <TextField
                  required
                  id = "outlined-required"
                  value={mapping[item]}
                  varient="standard"
                  type = 'text'
                  sx={{ml:2,width:"80%"}}
                  onChange={handleChangeData(item)}
                /></TableCell>
              </TableRow>
            // </Stack> 
          )
         })}
         </Table>
         </Scrollbar>
         <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
          <Button variant="outlined" onClick={()=>{handleMapName()}} >Save</Button>
          <Button variant="outlined" color="error" onClick={()=>{setMapModel(false);}} sx={{marginLeft:"1%"}}>Cancel</Button>
        </Stack>  
        </Box>
      </Modal>
      <Modal
        open={deleteConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >         
        <Box
          component="form"
          sx = {{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,width:'70%'
              }}
          noValidate
          autoComplete="off"
        >
        <Typography variant="h4" sx={{mb:2}} >Do you want to delete all the Live Score details?</Typography>  
         <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
          <Button variant="outlined" onClick={()=>{deleteAllLiveScore()}} >Yes</Button>
          <Button variant="outlined" color="error" onClick={()=>{setDeleteConfirmation(false);}} sx={{marginLeft:"1%"}}>No</Button>
        </Stack>  
        </Box>
      </Modal>
      {isSubmitting && (
        <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        />
      )}
      </Container>
    </Page>
  );
}

 
export default LiveScoreCard;