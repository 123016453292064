import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import { EditorState, convertToRaw, convertFromHTML,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import CircularProgress from '@mui/material/CircularProgress';
// import htmlToDraft from 'html-to-draftjs';
import Markdown from 'markdown-to-jsx';
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box,Autocomplete,TextField,
  Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css"
import {userDetails} from '../constants'


const TrainingProgram = () => {

  const [user, setUser] = React.useState([{role:['student']}]);
  const [trainings, setTrainings] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({comment:[]});
  const [updateItem, setUpdateItem] = React.useState({comment:[]});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [commentModel, setCommentModel] = React.useState(false);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [comment, setComment] = React.useState(false);
  const [closeProgram, setCloseProgram] = React.useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [viewLevel, setViewLevel] = React.useState('');
  const [values, setValues] = React.useState({
        name:'',
        userId: '',
        userName: "",
        targetScore:0,
        target10:0,
        target10x:0,
        comment:'',
        achievedScore:0,
        achieved10:0,
        achieved10x:0,
        startTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
        endTime: `${new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().split(':')[1]}`
  })

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
     axios
       .post(`/api/user/getParticularUserByEmail`, {
         "emailID":userDetails.email
       })
       .then((response) => {
         const userData = response.data.data; 
         getTrainings(userData);
         setUser(userData);
         getUserList(userData)
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }
  const getTrainings = (userData) => {
    let para = {}
    let param1= ""
    if (userData[0].role.includes("coach") || userData[0].role.includes("superAdmin") || userData[0].role.includes("academy")) {
      para = {"academyId": userData[0].academyId}
      param1 = `getTrainingsByAcademyId`
    } else {
      para = {"userId": userData[0]._id}
      param1 = `getTrainings`
    }
    axios
      .post(`/api/training/${param1}`,para )
      .then((response) => {
        setTrainings(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getUserList = (userId) => {
    axios
      .post(`/api/user/academyUserDetails`, {
        emailId: userId[0].emailID,
        userId: userId[0]._id
      })
      .then((response) => {
        let data = response.data.data;
        let newArray = [];
        data.map((item, index) => {
          newArray.push(item._id);
          if (data.length === index + 1) {
            setUserList(newArray)
          }
        })
        setValues({
          ...values,
          "userName": newArray[0].displayName,
          "userId": newArray[0].id
        });
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }
  const onEditorStateChange = (editorState) => {
    // console.log(editorState)
    setEditorState(editorState);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeInput = (props) => (event) => {
    if (props === 'startTime') {
      setValues({
        ...values,
        [props]: event.target.value,
        "endTime": `${new Date(new Date(event.target.value).setDate(new Date().getDate() + 5)).toISOString().split('T')}`
      });
    } else {
      setValues({
        ...values,
        [props]: event.target.value
      });
    }
  };

  const handleProgram = () =>{
     setisSubmitting(true)
      if (values.name === '' && draftToHtml(convertToRaw(editorState.getCurrentContent())).length <= 8) {
        toast.error("Enter all the fields before save");
        setisSubmitting(false)
      }else{
        axios
          .post(`/api/training/add`, {
            name: values.name,
            details: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            startTime: `${values.startTime}:00.000Z`,
            endTime: `${values.endTime}:00.000Z`,
            academyId: user[0].academyId,
            userId: values.userId,
            targetScore: values.targetScore,
            target10: values.target10,
            target10x: values.target10x,
            status: "active"
          })
          .then((response) => {
            setisSubmitting(false)
            setOpen(false);
            getTrainings(user);
            setEditorState(EditorState.createEmpty())
            setValues({
              name: '',
              userName: user[0].displayName,
              status: 'active',
              targetScore:0,
              target10x:0,
              target10:0,
              startTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
              endTime: `${new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().split(':')[1]}`,
            })
          })
          .catch((error) => {
          setisSubmitting(false)
            toast.error(error.response);
          });
      }
    }

  const userValidate = (user) => {
    return (
      user[0].role.includes('coach') || user[0].role.includes('superAdmin') || user[0].role.includes('academy')?true:false
  )}

  const addComment = ()=>{
    if (values.commet === ""){
      toast.error("Add some comment before save");
    }else{
      axios
        .post(`/api/training/addComment`, {
          _id:selectedItem._id,
          userId: user[0]._id,
          note: values.comment,
        })
        .then((response) => {
          setCommentModel(false);
          setComment(false)
          getTrainings(user);
          setSelectedItem({comment:[]})
          setCloseProgram(false)
          setValues({
            comment: '',
            achievedScore: 0,
            achieved10x: 0,
            achieved10: 0
          })
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response);
        });
    }
  }
  const updateCloseProgram = () => {
    if (values.achievedScore === 0 || values.achieved10x === 0 || values.achieved10 === 0) {
      toast.error("Update all the values");
    } 
    else {
      axios
        .put(`/api/training/update`, {
          _id: selectedItem._id,
          achievedScore: values.achievedScore,
          achieved10x: values.achieved10x,
          achieved10: values.achieved10,
          status:"completed"
        })
        .then((response) => {
          if (values.comment !== "") {
            addComment()
          }else{
            setCommentModel(false);
            setComment(false)
            setSelectedItem({comment:[]})
            setCloseProgram(false)
            getTrainings(user);
            setValues({
              achievedScore: 0,
              achieved10x:0,
              achieved10:0
            })
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response);
        });
    }
  }

  const handleUpdate =()=>{
    setisSubmitting(true)
      axios
        .put(`/api/training/update`, {
          _id:selectedItem._id,
          name: values.name,
          details: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          startTime: `${values.startTime}`,
          endTime: `${values.endTime}`,
          targetScore: values.targetScore,
          target10: values.target10,
          target10x: values.target10x,
        })
        .then((response) => {
          setisSubmitting(false)
          setOpen(false);
          setUpdate(false);
          getTrainings(user);
          setEditorState(EditorState.createEmpty())
          setValues({
            name: '',
            userName: user[0].displayName,
            status: 'active',
            targetScore: 0,
            target10x: 0,
            target10: 0,
            startTime: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
            endTime: `${new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().split(':')[1]}`,
          })
        })
        .catch((error) => {
          setisSubmitting(false)
          toast.error(error.response);
        });
  }

  const score = ()=>{
    return(
    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={{md:2,xs:2,sm:2}}>
      <TextField
      required
      id = "outlined-required"
      label = "Score"
      defaultValue = {values.targetScore}
      varient="standard"
      type = 'number'
      sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
      onChange={handleChangeInput(closeProgram ===true?'achievedScore':'targetScore')}
      />
      <TextField
      required
      id = "outlined-required"
      label = "# of 10s"
      defaultValue = {values.target10}
      varient="standard"
      type = 'number'
      sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
      onChange={handleChangeInput(closeProgram ===true?'achieved10':'target10')}
      />
      <TextField
      id = "outlined-required"
      label = "# of 10x"
      defaultValue = {values.target10x}
      varient="standard"
      type='number'
      sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
      onChange={handleChangeInput(closeProgram ===true?'achieved10x':'target10x')}
      />
    </Stack>
  )}

  const input =()=>{
    return (
    <Stack>
      <TextField
        id = "outlined-required"
        label = "Program Name"
        defaultValue = {values.name}
        varient="standard"
        type='text'
        sx={{mt: { md: 2, xs: 2,sm: 2 }}}
        onChange={handleChangeInput('name')}
        />
      <TextField
        id="datetime-local"
        label="Start Time"
        type="datetime-local"
        value={values.startTime}  
        sx={{mt: { md: 2, xs: 2,sm:2 }}}
        onChange={handleChangeInput('startTime')}
      /> <TextField
        id="datetime-local"
        label="End Time"
        type="datetime-local"
        value={values.endTime}  
        sx={{mt: { md: 2, xs: 2,sm:2 }}}
        onChange={handleChangeInput('endTime')}
      /> 
      <Typography variant="body1" gutterBottom mt={1}>Target Values</Typography>  
      {score()}
      <Typography variant="body1" gutterBottom mt={1}>Details</Typography>  
      <Editor 
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />  
    </Stack>
    )}

  return (
    <Page title="Trainings">
      < Toast / >
      <Container>
        {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )}
         {open === true ?null:<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>        
          <Typography variant="h4" gutterBottom>
            Training Program
          </Typography>      
          {userValidate(user)?<Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Program
          </Button>  :null}
        </Stack> }

       {open === true ?
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>   
         {update === false? < Autocomplete
            id = "disable-clearable"
            disableClearable
            options={userList}
            defaultValue={userList[0]}
            getOptionLabel={(option) => {return option.displayName}}
            inputValue={values.viewLevel1}
            onInputChange={(event, newInputValue) => {
                  setViewLevel(newInputValue);
            }}
              onChange={(event, newValue) => {  
              setValues({
                ...values,
                ['userName']: newValue.displayName,
                ['userId']:newValue.id
              });
            }}
            renderInput={(params) => <TextField {...params} label="User" sx={{mt:2}}/>}
          /> :null}
          {input()}           
          <Stack alignItems="center" direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={{xs:15,sm:8,md:3}}>  
          <Button variant="outlined" onClick={() => {update === true?handleUpdate():handleProgram()}}>save</Button>
          <Button variant="outlined" color="error" onClick={() => {setOpen(false);setUpdate(false)}}>Cancel</Button>
          </Stack>
        </Stack>
        :
        trainings.length>0?
        <Stack direction="row"  justifyContent="space-between" mb={2}>
          <Box sx={{ width: '100%' }}>         
            <Scrollbar>
            <TableContainer component={Paper}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
              <TableRow>
              {userValidate(user)?<TableCell align="left">User Name</TableCell>:null}
              <TableCell align="left">Program Name</TableCell>
              <TableCell align="left">Start Time</TableCell>
              <TableCell align="left">End Time</TableCell>
              <TableCell align="left">Target Score</TableCell>
              <TableCell align="left">Target # of 10s</TableCell>
              <TableCell align="left">Target # of 10x</TableCell>
              <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainings.length >0 && trainings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
                return (
                  <TableRow tabIndex={-1} onClick={()=>{setCommentModel(true);setSelectedItem(item)}} sx={{ "&:hover": { cursor: "pointer",color:"blue" } }}>
                    {userValidate(user)?<TableCell align="left">{item.user[0].displayName}</TableCell>:null}
                    <TableCell align="left">{item.name}</TableCell>
                    <TableCell align="left">{item.startTime.split("T")[0]}</TableCell>
                    <TableCell align="left">{item.endTime.split("T")[0]}</TableCell>
                    <TableCell align="left">{item.targetScore}</TableCell>
                    <TableCell align="left">{item.target10}</TableCell>
                    <TableCell align="left">{item.target10x}</TableCell>
                    <TableCell align="left">{item.status.toUpperCase()}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[3, 5, 10, 25]}
            colSpan={3}
            count={trainings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </Scrollbar>
          </Box>
        </Stack>
        :
        <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'7%'},marginLeft:{sm:'20%',md:'29%'}}}>
          <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No Data</Typography> 
          <img src="/static/illustrations/empty_bin.png" alt="No Details" style={{width:'300px'}} />
         </Box>
        }
        <Modal open={commentModel}>         
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >  
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>        
          <Typography variant="h4" gutterBottom>
            Program Details
          </Typography>      
          {userValidate(user)? comment !== true && closeProgram !== true && selectedItem.status !=='completed'?
          <Stack direction="row" mb={2} spacing={{ xs: 1, sm: 1, md: 1 }}> 
            <Button variant="contained" onClick={() => {setComment(true)}}>
              Add comment
            </Button>
            <Button variant="contained" sx={{}} onClick={() => {setCloseProgram(true)}}>
              Close Program
            </Button>
          </Stack>
          :null:null}   
          </Stack> 
          {comment === true || closeProgram === true ?
          <Stack direction="column" justifyContent="space-between" mb={2}> 
            <TextField
              id = "outlined-required"
              label = "comment"
              defaultValue = {values.name}
              varient="standard"
              type='text'
              multiline
              sx={{mt: { md: 2, xs: 2,sm: 2 },width:'100%'}}
              onChange={handleChangeInput('comment')}
            />
            {closeProgram === true ?
            <div >
            <Typography variant="body1" gutterBottom mt={1}>Achieved Values</Typography>  
              {score()}
            </div>
            : null}
          </Stack>
          :<div>
          <Stack direction="row"> 
          <Typography variant="h5" gutterBottom>
            Summary
          </Typography>
         {selectedItem.status !== undefined && selectedItem.status.toLowerCase() === "completed"? null:
         <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
              onClick={()=>{setUpdate(true);setOpen(true);setCommentModel(false);
                setValues({...values,
                  name: selectedItem.name,
                  userName: selectedItem.displayName,
                  targetScore: selectedItem.targetScore,
                  target10: selectedItem.target10,
                  target10x: selectedItem.target10x,
                  startTime: selectedItem.startTime.split(".")[0],
                  endTime: selectedItem.endTime.split(".")[0]
                })
                setEditorState(EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(selectedItem.details)
                  )
                ));
                input()
               }}                            
            /> }
          </Stack>
          <Markdown style={{marginLeft:"2%"}}>{selectedItem.details}</Markdown>
          {selectedItem.status !== undefined && selectedItem.status.toLowerCase() === "completed"?<div>
             <Typography variant="h6" gutterBottom>Achieved Score: {selectedItem.achievedScore} </Typography> 
             <Typography variant="h6" gutterBottom>Achieved 10s: {selectedItem.achieved10} </Typography> 
             <Typography variant="h6" gutterBottom>Achieved 10x: {selectedItem.achieved10x} </Typography> 
             </div>
          :null}
           <Typography variant="h5" gutterBottom>
            Comments
          </Typography> 
          <Scrollbar style={{ height: "25vh", paddingRight: "4rem" }}>
            {selectedItem.comment.length>0?
              selectedItem.comment.map((item)=>{
              return (
                <Stack direction="column" justifyContent="space-between" > 
                <Stack direction="row"> 
                  <Typography variant="body1" gutterBottom>{item.created_at.split('T')[0]} {item.created_at.split('T')[1].split(".")[0]}</Typography> 
                  {/* <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} onClick={()=>{setUpdate(true);}} />  */}
                  </Stack>
                  <Typography variant="body2" gutterBottom>{item.note}</Typography> 
                </Stack>
              )
            })
            : <p>No Comments</p>
          }
          </Scrollbar>
          </div>
          }
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={2}>
            {userValidate(user)?<div>
            {comment === true?<Button variant="outlined" onClick={()=>{addComment()}}>Save</Button> :null}
            {closeProgram === true ?<Button variant="outlined" onClick={()=>{updateCloseProgram()}}>Save</Button> :null}
            </div>
            :null}
            <Button variant="outlined" color="error" onClick={() => {setCommentModel(false);setComment(false);setCloseProgram(false)}}>Close</Button>
          </Stack>  
          </Box>
          </Modal>
      </Container>
    </Page>
  );
}
export default TrainingProgram;