// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'home',
    path: '/dashboard/academyHome',
    icon: getIcon('ant-design:home-twotone'),
    role: ['student', 'coach','superAdmin']
  }, {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    role: ['student']
  }, 
  {
    title: 'dashboard',
    path: '/dashboard/academy',
    icon: getIcon('eva:pie-chart-2-fill'),
    role: ['superAdmin', 'coach']
  },
  {
    title: 'Match',
    path: '/dashboard/match',
    icon: getIcon('bx:target-lock'),
    role: ['student']
  }, {
    title: 'Live Score',
    path: '/dashboard/livescoreuser',
    icon: getIcon('uil:focus-target'),
    role: ['student','coach','superAdmin']
  }, {
    title: 'Leader Board',
    path: '/dashboard/LeaderBoard',
    icon: getIcon('mdi:monitor-dashboard'),
    role: ['student', 'coach',"superAdmin"]
  }, {
    title: 'Attendance',
    path: '/dashboard/attendance',
    icon: getIcon('tabler:report'),
    role: ['student', 'coach', "superAdmin"]
  }, 
  {
    title: 'Analytics',
    path: '/dashboard/analytics',
    icon: getIcon('carbon:analytics'),
    role: ['coach', "superAdmin","student"]
  },
  {
    title: 'My Achievements',
    path: '/dashboard/MyAchievements',
    icon: getIcon('emojione-monotone:military-medal'),
    role: ['student','coach']
  },
  {
    title: 'My Diary',
    path: '/dashboard/mydiary',
    icon: getIcon('material-symbols:auto-stories-rounded'),
    role: ['student', 'coach', "superAdmin"]
  },
  {
    title: 'Training program',
    path: '/dashboard/trainingProgram',
    icon: getIcon('eva:file-text-fill'),
    role: ['student', 'coach', "superAdmin"]
  }, 
  {
    title: 'Knowledge Base',
    path: '/dashboard/MyLinks',
    icon: getIcon('jam:bookmark-plus-f'),
    role: ['student', 'coach', "superAdmin"]
  }, {
    title: 'Booking',
    path: '/dashboard/booking',
    icon: getIcon('fluent:book-add-20-filled'),
    role: ['superAdmin', 'coach',"student"]
  },
  {
    title: 'Competitions',
    path: '/dashboard/competition',
    icon: getIcon('material-symbols:military-tech-rounded'),
    role: ['student','coach',"superAdmin"]
  },
  // {
  //   title: 'Academy Info',
  //   path: '/dashboard/academyDetails',
  //   icon: getIcon('fa:list-alt'),
  //   role: ['superAdmin', 'coach']
  // },
  {
    title: 'Asset Management',
    path: '/dashboard/assetManagement',
    icon: getIcon('fluent-mdl2:account-management'),
    role: ['superAdmin', 'coach']
  },
  {
    title: 'Academy Config',
    path: '/dashboard/academyConfig',
    icon: getIcon('icon-park-outline:setting'),
    role: ['superAdmin', 'coach']
  },
  {
    title: 'Admin Config',
    path: '/dashboard/adminConfig',
    icon: getIcon('material-symbols:settings-account-box-outline'),
    role: ['superAdmin']
  },
  {
    title: 'Sponsorship',
    path: '/dashboard/sponsorship',
    icon: getIcon('simple-icons:semanticscholar'),
    role: ['superAdmin','coach','student']
  },
];

export default navConfig;
