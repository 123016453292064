// @mui
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {
    Card,
    Box,CardHeader
} from '@mui/material';

BarChart.propTypes = {
    Details: PropTypes.array,
    chartTitle: PropTypes.string,
    graphName: PropTypes.string
};

export default function BarChart({
    Details,chartTitle,graphName
}) {
    const series = [{name:graphName,data:Details.data}]
    const options = {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              xaxis: {
                categories: Details.name,
                labels: {
                  show: true,
                  // rotate: -30,
                  rotateAlways: false,
                  trim:true,
                  style: {
                      colors: [],
                      fontSize: '11px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      cssClass: 'apexcharts-xaxis-label',
                  },
                }
              },
              tooltip: {
                
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 0
              }
            }
    return (
        <Card sx={{mt:4}}>
            <CardHeader title={chartTitle} />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart options={options} series={series} type="bar" height={350} />
            </Box>
         </Card>
    );
}
