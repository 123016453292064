import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from 'react';
// material
import {
    Table,
    Typography,
    Container,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Box, Grid, Card, Stack, Button, Modal, TextField, ButtonGroup,MenuItem,
    FormControlLabel, Checkbox, 
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import {formatTime} from './../utils/formatTime';
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import Scrollbar from '../components/Scrollbar';
import "./style.css"
import {userDetails,DateFilter} from '../constants'


const attendanceView = () => {

  const [open, setOpen] = React.useState(false);
  const [openScan, setOpenScan] = React.useState(false);
  const [time, setTime] = React.useState(`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[2]}`);
  const [timeOut, setTimeOut] = React.useState(`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`);
  const [attendanceData, setAttendanceData] = React.useState([])
  const [user, setUser] = React.useState([{displayName:"",role:["student"]}]);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rowsPerPageScan, setRowsPerPageScan] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [pageScan, setPageScan] = React.useState(0);
  const [rfidID, setRfidID] = React.useState('') 
  const [attendanceOfDay, setAttendanceOfDay] = React.useState([])
  const [totalUser, setTotalUser] = React.useState([])
  const [academyUsers, setAcademyUsers] = React.useState([]) 
  const [userSelected, setUserSelected] = React.useState(userDetails.email)
  const [dynamicDate, setDynamicDate] = React.useState('') 
  const [state, setState] = React.useState({
    "fitness/yoga": false,
    "shooting/technical": true,
  });
  const [category, setCategory] = React.useState(["shooting/technical"]); 
  const [selected, setSelected] = React.useState(false);
  const [outModal, setOutModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [date, setDate] = React.useState(7);

  useEffect(() => {
    // getAttendance();
    getUser(date);
    getAttendanceOfDay();
    const timer = setInterval(() => {
      setDynamicDate(new Date().toLocaleString());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getAttendance = (datevalue) => {
    axios
       .post(`/api/attendance/userLevelDetails`, {
         "emailID":userDetails.email,
         date: datevalue
       })
       .then((response) => {
         const userData = response.data.data;  
         setAttendanceData(userData[0].attendance);
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }
  const getUser = (datevalue) => {
    axios
      .post(`/api/user/getParticularUserByEmail`, {
        "emailID": userDetails.email
      })
      .then((response) => {
        const userData = response.data.data;
        
        if(userData[0].role.includes("superAdmin")){
          axios
            .post(`/api/attendance/adminLevelDetails`, {
                date: datevalue
            })
            .then((response) => {
              const userData = response.data.data;
              setAttendanceData(userData);
            })
            .catch((error) => {
              console.log(error)
              toast.error(error.response.data);
            });
        }
        else if (userData[0].role.includes("coach")){
          
          axios
            .post(`/api/attendance/coachLevelDetails`, {
              "academyID": userData[0].academyId,
                date: datevalue
            })
            .then((response) => {
              const userData = response.data.data;
              setAttendanceData(userData);
            })
            .catch((error) => {
              console.log(error)
              toast.error(error.response.data);
            });
        }
        else{
          getAttendance(datevalue)
        }
        getAcademyUserDetails(userData[0].academyId)
        setUser(userData);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const sortFunction = (data) => {
    data.sort((a, b) => a._id.displayName.localeCompare(b._id.displayName))
  }

  const getAcademyUserDetails = (academyId) => {
    axios
      .post(`/api/user/userByAcademyID`, {
        academyId: academyId
      })
      .then((response) => {
        let data = response.data.data;
        sortFunction(data)
        setTotalUser(data.length)
        setAcademyUsers(data)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getAttendanceOfDay = () =>{    
    axios
      .get(`/api/attendance/AttendanceOfDay`)
      .then((response) => {
        const attendanceOfDAy = response.data.data;
        setAttendanceOfDay(attendanceOfDAy);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };
  const handleChangePageScan = (event, newPage) => {
    setPageScan(newPage);
  };

  const handleChangeRowsPerPageScan = (event) => {
    setRowsPerPageScan(+event.target.value);
    setPageScan(0);
  };

  const handleChange = (props) => (event) => {
    if (props === "time") {
      setTime(event.target.value);
    } else if (props === "timeOut") {
      setTimeOut(event.target.value);
    }
  };

   const handleChangeFilter = (props) => (event) => {
    setDate(event.target.value);
    getUser(event.target.value);
   }

   const handleChangeCheckOut = (props) => (event) => {
       setSelected(event.target.checked)
   };

  const handleChangeUser = (props) => (event) => {
    setUserSelected(event.target.value);
  };

  const handleChangeCheckBox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    if (event.target.name === "shooting/technical" && event.target.checked === true){
      category.includes("shooting/technical")?null:category.push("shooting/technical")
    } else if (event.target.name === "fitness/yoga" && event.target.checked === true) {
      category.includes("fitness/yoga") ? null : category.push("fitness/yoga")
    }
    else if (event.target.checked === false){
      category.splice(category.indexOf(event.target.name),1)
    }
    setCategory(category)
  };

  const addAttendance = (value) => {
    // console.log(userSelected, category)
    axios
      .post(`/api/attendance/addUserDetail`, {
        "emailID": userSelected,
        "timeIn": `${time}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[2]}`,
        "timeOut": selected === true ?`${timeOut}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[2]}`:null,
        "type": 'manual',
        "category": category
      })
      .then((response) => {
        setOpen(false)
        getUser(date);
        getAttendanceOfDay();
        setCategory(["shooting/technical"]);
        setState({
          "fitness/yoga": false,
          "shooting/technical": true,
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response);
        getUser(date);
      });
  }

  const scanAttendance = () => (event) => {
    setRfidID(event.target.value)
  }

  const ScanSubmit = () => (event) => {
    if(event.key === 'Enter'){
      event.stopPropagation();
      axios
        .post(`/api/attendance/validateRFID`, {
          "rfidID": rfidID,
        })
        .then((response) => {
          if(response.data.data.length > 0){
            axios
              .post(`/api/attendance/add`, {
                "rfidID": rfidID,
                "timeOut":'',
                "timeIn": `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[2]}`,
                "type": 'web'
              })
              .then((response) => {
                getAttendanceOfDay()
                setRfidID('')
                getUser(date)
              })
              .catch((error) => {
                console.log(error)
                toast.error(error.response.data);
              });
          }
          else{
            toast.error("RFID not mapped to any user");
            setRfidID('')
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });

      
    } 
  }

  const updateAttendance = (data) =>{
    let datavalue = {
      _id:data.attendance._id,
      timeOut: `${timeOut}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[2]}`
    }
     axios
       .put(`/api/attendance/update`, datavalue)
       .then((response) => {
        getUser(date);
        getAttendanceOfDay();
        setOutModal(false)

        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });


  }

  return (
    <Page title="Attendance">
      <Toast />
      <Container> 
        <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6} md={7}>        
            <Typography variant="h4" gutterBottom>
                Attendance
            </Typography>  
          </Grid>
          <Grid item xs={5} sm={2} md={2}>
             <TextField
                id = "outlined-select-course"
                select
                label = "Filter"
                value={date}
                onChange={handleChangeFilter()}
                variant = "standard"
                style={{align:"right"}}
              >
                {
                  DateFilter.map((option) => ( 
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={7} sm={4} md={3}>
            {user[0].role.includes("coach") || user[0].role.includes("superAdmin")?
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button variant="contained" onClick={() => {setOpen(true);
              setTime(`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`)}} 
            >
              New Attendance
            </Button> 
            <Button variant="contained" onClick={() => {setOpenScan(true)}}>
             Scan
            </Button>     
            </ButtonGroup>
            : null}
          </Grid>   
          </Grid>
          {
            attendanceData.length > 0 ?
            user[0].role.includes("superAdmin") || user[0].role.includes("coach") || user[0].role.includes("academy") ?
            <Card sx={{mt:2}}>
            <div>
            <Grid xs={12} sm={12} md={6}>
                  <Scrollbar>
                    <TableContainer component={Paper}>
                      <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Time In</TableCell>
                            <TableCell align="left">Time Out</TableCell>
                            <TableCell align="left">Shooting / Technical</TableCell>
                            <TableCell align="left">Fitness / Yoga</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {attendanceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            // console.log("292 ", row)
                            return (
                                  <TableRow tabIndex={-1}>
                                    <TableCell align="left">{row.attendance.type.toUpperCase()}</TableCell>
                                    <TableCell align="left">{row.attendance.rfidID}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.attendance.timeIn.split('T')[0]} {formatTime(row.attendance.timeIn.split('T')[1])}</TableCell>
                                    <TableCell align="left">{row.attendance.timeOut != undefined && row.attendance.timeOut != null ?
                                        <div>{row.attendance.timeOut.split('T')[0]} {formatTime(row.attendance.timeOut.split('T')[1])}</div> : 
                                        <Button variant="outlined" onClick = {() => {setOutModal(true);setSelectedRow(row)}}>Out</Button> }
                                    </TableCell>
                                    <TableCell align="left">{row.attendance.category !== undefined && row.attendance.category.includes("shooting/technical")
                                      ?<Iconify icon = "subway:tick" sx={{width:"100%",color:'green'}} />
                                      :<Iconify icon = "emojione:cross-mark" sx={{width:"100%",color:'red'}} />}</TableCell>
                                    <TableCell align="left">{row.attendance.category !== undefined && row.attendance.category.includes("fitness/yoga")
                                      ?<Iconify icon = "subway:tick" sx={{width:"100%",color:'green'}} />
                                      :<Iconify icon = "emojione:cross-mark" sx={{width:"100%",color:'red'}} />}</TableCell>
                                  </TableRow>
                              )
                           })} 
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[50, 100, 150]}
                      colSpan={3}
                      count={attendanceData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Scrollbar>
                </Grid>
            </div>
            </Card>
            :
            <Card sx={{mt:2}}>
            <div>
            <Grid xs={12} sm={12} md={6}>
                  <Scrollbar>
                    <TableContainer component={Paper}>
                      <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Time In</TableCell>
                            <TableCell align="left">Time Out</TableCell>
                            <TableCell align="left">Shooting / Technical</TableCell>
                            <TableCell align="left">Fitness / Yoga</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {attendanceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              // {console.log("335 ",row)}
                            return (
                              <TableRow tabIndex={-1}>
                                <TableCell align="left">{row.type.toUpperCase()}</TableCell>
                                <TableCell align="left">{row.rfidID}</TableCell>
                                <TableCell align="left">{row.timeIn.split('T')[0]} {formatTime(row.timeIn.split('T')[1])}</TableCell>
                                    <TableCell align="left">{row.timeOut != undefined && row.timeOut != null ?<div>{row.timeOut.split('T')[0]} {formatTime(row.timeOut.split('T')[1])}</div> : '-'}</TableCell>
                                 <TableCell align="left">{row.category !== undefined && row.category.includes("shooting/technical")
                                  ?<Iconify icon = "subway:tick" sx={{width:"100%",color:'green'}} />
                                  :<Iconify icon = "emojione:cross-mark" sx={{width:"100%",color:'red'}} />}</TableCell>
                                <TableCell align="left">{row.category !== undefined && row.category.includes("fitness/yoga")
                                  ?<Iconify icon = "subway:tick" sx={{width:"100%",color:'green'}} />
                                  :<Iconify icon = "emojione:cross-mark" sx={{width:"100%",color:'red'}} />}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[50, 100, 150]}
                      colSpan={3}
                      // component="div"
                      count={attendanceData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Scrollbar>
                </Grid>
            </div>
            </Card>
            : <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>        
            <Typography variant="h4" gutterBottom>
                No Data
            </Typography>     
          </Stack> 
          }
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {md:'46%',sm:'90%',xs:'90%'},
                bgcolor: 'background.paper',
                p: 4,
              }}
              noValidate
              autoComplete="off"
            >
            <Typography variant="h5" gutterBottom component="div">
              Please select user and date on when you need to update the attendance 
            </Typography>
            <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>
            <TextField
              id = "outlined-search"
              select
              label = "User"
              value={userSelected}
              onChange={handleChangeUser()}
              variant = "standard"
              sx={{width:{md:"70%",xs:"100%",sm:"100%"}}}
            >
              {
                academyUsers.map((option) => (
                <MenuItem key={option._id.user} value={option._id.user}>
                  {option._id.displayName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="datetime-local"
              label="Time In"
              type="datetime-local"
              value={time}   
              InputProps = {
                {
                  inputProps: {
                    max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
                  }
                }
              }
              sx={{mt: { md: 2, xs: 2,sm:2 },width:{md:'70%',xs:'100%',sm:'100%'}}}
              onChange={handleChange('time')}
              // helperText = "Select the match date & time"
            /> 
            <FormControlLabel
             label="Attendance Out"
              control={<Checkbox checked={selected} onChange={handleChangeCheckOut()} name="attendanceOut"/>}
            />
            {selected === true ?<TextField
              id="datetime-local"
              label="Time Out"
              type="datetime-local"
              value={timeOut}   
              InputProps = {
                {
                  inputProps: {
                    max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
                  }
                }
              }
              sx={{mt: { md: 2, xs: 2,sm:2 },width:{md:'70%',xs:'100%',sm:'100%'}}}
              onChange={handleChange('timeOut')}
              // helperText = "Select the match date & time"
            />  : null}
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
              <FormControlLabel
                label="Fitness / Yoga"
                control={<Checkbox checked={state["fitness/yoga"]} onChange={handleChangeCheckBox} name="fitness/yoga"/>}
              />
              <FormControlLabel
                label = "Shooting / Technical"
                control={<Checkbox checked={state["shooting/technical"]} onChange={handleChangeCheckBox} name="shooting/technical"/>}
              />
            </Box>
            </Stack>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
            <Button variant="outlined" onClick = {() => {addAttendance(user)}}>
              submit
            </Button>              
            <Button variant = "outlined" color = "error" onClick = {() => {setOpen(false)}} >
              cancel
            </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal
          open={openScan}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h5" gutterBottom component="div">
            Scan {dynamicDate}
          </Typography>
          <Button variant = "outlined" color = "error" onClick = {() => {setOpenScan(false)}} >
            close
          </Button>
          </Stack>
          <TextField
            required
            id="outlined-required"
            value={rfidID}
            varient="standard"
            helperText="user code"
            type="text"
            inputRef={input => input && input.focus()}
            onKeyDown={ScanSubmit()}
            sx={{ m: 1, width: '100%' }}
            onChange={scanAttendance()}
          />
        <Grid>
        <Typography variant="h5" gutterBottom component="div">
          Today's Data
        </Typography>
        <Scrollbar>
          <TableContainer component={Paper}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Time In</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceOfDay.slice(pageScan * rowsPerPageScan, pageScan * rowsPerPageScan + rowsPerPageScan).map((row) => {
                    // {console.log(row)}
                  return (
                    <TableRow tabIndex={-1}>
                      <TableCell align="left">{row.type[0].toUpperCase()}</TableCell>
                      <TableCell align="left">{row.rfidID[0]}</TableCell>
                      <TableCell align="left">{row.displayName[0][0]}</TableCell>
                      <TableCell align="left">{row.timeIn[0].split('T')[0]} {formatTime(row.timeIn[0].split('T')[1])}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            // component="div"
            count={attendanceOfDay.length}
            rowsPerPage={5}
            page={pageScan}
            onPageChange={handleChangePageScan}
            onRowsPerPageChange={handleChangeRowsPerPageScan}
          />
        </Scrollbar>
        </Grid>
        </Box>
        </Modal>
        <Modal
            open={outModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {md:'46%',sm:'90%',xs:'90%'},
                bgcolor: 'background.paper',
                p: 4,
              }}
              noValidate
              autoComplete="off"
            >
            <Typography variant="h5" gutterBottom component="div">
              Please select out time of the user
            </Typography>
            <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>
            <TextField
              id = "outlined"
              label = "User"
              value={selectedRow !== undefined ?selectedRow.name:''}
              variant = "standard"
              disabled
              sx={{width:{md:"70%",xs:"100%",sm:"100%"}}}
            />
            <TextField
              id="datetime-local"
              label="Time In"
              type="datetime-local"
              disabled
              value={selectedRow !== undefined && selectedRow.attendance !==undefined && selectedRow.attendance.timeIn !==undefined ?`${selectedRow.attendance.timeIn.split('T')[0]}T${selectedRow.attendance.timeIn.split('T')[1].split(':')[0]}:${selectedRow.attendance.timeIn.split('T')[1].split(':')[1]}`:"-"}  
              sx={{mt: { md: 2, xs: 2,sm:2 },width:{md:'70%',xs:'100%',sm:'100%'}}}
            /> 
            <TextField
              id="datetime-local"
              label="Time Out"
              type="datetime-local"
              value={timeOut}   
              InputProps = {
                {
                  inputProps: {
                    max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
                  }
                }
              }
              sx={{mt: { md: 2, xs: 2,sm:2 },width:{md:'70%',xs:'100%',sm:'100%'}}}
              onChange={handleChange('timeOut')}
            /> 
            </Stack>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
            <Button variant="outlined" onClick = {() => {updateAttendance(selectedRow)}}>
              submit
            </Button>              
            <Button variant = "outlined" color = "error" onClick = {() => {setOutModal(false)}} >
              cancel
            </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}

 
export default attendanceView;