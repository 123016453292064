import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import useResponsive from '../hooks/useResponsive';
import { Typography,Box } from '@mui/material';

// ----------------------------------------------------------------------

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 484,
  display: 'flex',
  maxHeight:'90vh',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));
// ----------------------------------------------------------------------

export default function LoginLogoLayout() {
    
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  return (
    <>
      {mdUp && (
          <SectionStyle>
             <Box component="img" src="/static/ishoot.svg" alt="" sx={{ width: '100%', height: '40%',marginLeft:'auto',marginRight:'auto'}} />
            <Typography variant="subtitle1" gutterBottom sx={{ ml:{md:5,xs:1,sm:1},mt:2,textAlign:"center" }}>
              Pro-level Shooter Performance Management & Analytics
            </Typography>
            <Typography variant="subtitle1" sx={{ px: 5,mt:3,ml:3,textAlign:"center" }} >
            <em>"Leveraging Data analytics to develop world class performance for the global shooter community"</em>
            </Typography>
           </SectionStyle>
        )}
    </>
  );
}
