import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect,useRef } from 'react';
// material
import {
  Container,Stack,Grid,Button,Card,CardContent,Typography,Modal,Box,TextField
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import Logo from '../components/Logo';
import "./style.css"


const Sponsorship = () => {

  const [open, setOpen] = React.useState(false);
  useEffect(() => {

}, []);
  return (
    <Page title="Sponsorship">
      <Toast />
      <Container>
        {open == true ?null:
            <Grid container>
            <Card sx={{textAlign:"center"}} >
                <CardContent >
                <Typography variant="h6" sx={{ mb: 1 ,textAlign:"center"}} component="div">
                    DHIYAM-23
                </Typography>  
                <Typography variant="body1" sx={{ mb: 1}} component="div">
                    Rifle & Pistol Shooter
                </Typography>  
                <Typography variant="body2" sx={{ mb: 1 }} component="div">
                    Sponsorship Program 2023
                </Typography>        
                <Stack sx={{alignItems:"center"}}>
                <Button sx={{cursor:"pointer"}} variant="outlined" disableElevation onClick={()=>{setOpen(true)}}>
                    More Info
                </Button>
                </Stack>
                </CardContent>
            </Card>
            </Grid>
        }
      {/* <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          > */}
          {open == true?
            <Stack >
            <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" sx={{ mb: 1.5 }} component="div">
                DHIYAM-23
            </Typography> 
            <Button sx={{mt:0,cursor:"pointer"}} onClick={()=>{setOpen(false)}} startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />}>
            </Button>
            </Stack>
            <Typography variant="body2" gutterBottom component="div">
                DHIYAM-23 is a unique performance based sponsorship program for budding shooters who are inclined to pursue the sport professionally and have a passion to represent INDIA in the world stage. 
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                Objective
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                DHIYAM-23’s objective is to promote and encourage air pistol and rifle shooters of India to improve their performance using  data analysis , scientific methods and artificial intelligence ( AI )
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                Categories & Events 
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                DHIYAM-23 has 5 categories and shooters will be rewarded on a monthly basis
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                The 5 categories are,
                <li>10 M Air Rifle NR </li>
                <li>10 M Air Rifle ISSF</li>
                <li>10 M Air Pistol NR</li>
                <li>10 M Air Pistol ISSF</li>
                <li>0.177 Open Sight </li>
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                How is the toper identified ?
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                <li>Every shooter is expected to track their daily scores in Intellishoot</li>
                <li>The average of Top 4 Scores for the Month will be considered</li>
                <li>Minimum 20 match Scores per month to be updated in the system</li>
                <li>Performance Measure will be decided before the start of the month. Will be announced on Last day of previous Month</li>
                <li>The performance can be measured in any of the following ways </li>
                <ol style={{marginLeft: '5%'}} start="1">
                    <li>Highest Score – Non Decimal</li>
                    <li>Highest Score – With Decimal</li>
                    <li>Highest inner 10s</li>
                    <li>Highest Inner 10s per Series</li>
                    <li>Highest Continuous 10s</li>
                    <li>Lowest Variation </li>
                </ol>
                <li>Scoring , rules and regulations for Monthly reward will be shared every month</li>
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                What are the dates ?
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                For June 2023 ,
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                <li>On 31-May -2023 , competition rules will be published </li>
                <li>All Intellishoot registered users are eligible </li>
                <li>Sponsorship Money will be transferred to Shooter / Parent account directly</li>
            </Typography>
            </Stack>
            :null}
          {/* </Box>
        </Modal> */}
      </Container>
    </Page>
  );
}

 
export default Sponsorship;