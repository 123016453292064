import * as React from 'react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container, Modal, Box, TextField,
  Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import {userDetails} from '../constants'

const AcademyUserRequest = forwardRef(({
            children,
            user,
            academyDetails,
            ...other
        }, ref) => {
    
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedFile, setselectedFile] = useState("");
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [value, setValue] = React.useState({
    userId:'',
    tnxId:'',
    tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
  });

  useEffect(() => {
    getDetails()
  }, []);

  const handleChangeInput = (props) => (event) => {
      setValue({...value, [props]:event.target.value});
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDetails = ()=>{
    axios
      .post(`/api/userAcademyRequest/getDetailsByAcademyId`, {
        academyId: academyDetails._id,
      })
      .then((response) => {
        setDetails(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.message);
      });
  }
  const onChangeHandlerForZip = (e) => {
    try {
      let extension = e.target.files[0].name.split(".")[1];
      if (extension.toLowerCase() !== "png") {
        toast.error("Please upload the image in .png format");
      } else if (e.target.files[0].size >= 1 * 1024 * 1024) {
        toast.error("Image size should be less than 1 MB");
      } else {
        setselectedFile(e.target.files[0]);
      }
    } catch {
      console.log("No File selected");
    }
  };

  const handleUpload = (data) => {
    setisSubmitting(true);
    const data1 = new FormData();
    data1.append("requestId", data._id);
    data1.append("file", selectedFile);
    // console.log(user[0].academyId)
    axios
      .post(`/api/userAcademyRequest/uploadFile`, data1, {})
      .then((res) => {
        if (res.status === 200) {
          setisSubmitting(false)
          setselectedFile('')
          setOpen(false);
          getDetails()
          setValue({
            ...value,
            tnxId: "",
            userId: '',
            tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
          })
          sendMail(data)
          toast.success(res.data.message);
        } else {
          toast.error("Error in uploading the image");
        }
      })
      .catch((e) => {
        // console.log(e.response);
        toast.error(e.response.data.message);
      });
  };

  const addRequest = ()=>{
    if (value.userId === "" || value.tnxId==="" || selectedFile.length == 0){
      toast.error("Please provide all the inputs");
    }else{
      axios
        .post(`/api/userAcademyRequest/add`, {
          academyId: academyDetails._id,
          requestBy: user[0]._id,
          userId: value.userId,
          status: "requested",
          tnxDate: value.tnxDate,
          tnxId: value.tnxId
        })
        .then((response) => {
          // console.log(response.data.data._id)
          if (selectedFile.length != 0){
            handleUpload(response.data.data)
          }else{
            setselectedFile('')
            setOpen(false);
            getDetails()
            setValue({
              ...value,
              tnxId: "",
              userId: '',
              tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
            })
            sendMail(response.data.data)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.message == "Request failed with status code 404"){
            toast.error("Requested user not found")
          }else{
          toast.error(error.message);}
        });
    }
  }

  const sendMail =(data)=>{
    axios
      .post(`/api/userAcademyRequest/sendMail`, {
        emailID: user[0].emailID,
        requestBy: user[0].emailID,
        userId: data.userId,
        tnxDate: data.tnxDate,
        tnxId: data.tnxId,
        id:data._id
      })
      .then((response) => {
        // console.log(response.data.data._id)
        setValue({
          ...value,
          tnxId: "",
          userId: '',
          tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
        })
      })
      .catch((error) => {
          toast.error(error.message);
      });
  }

  return (
    <Page title="User Request">
      <Toast />
      <Container>
        <Stack direction="row" justifyContent="space-between">        
          <Typography variant="h6" gutterBottom>
            Academy Change
          </Typography>
         {open === false ? 
         <Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Request
          </Button> 
          :null }
        </Stack>      
        {open === true ?<Stack>
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }}>
           <TextField
            id = "filled-flexible"
            defaultValue = {value.userId}
            value={value.userId}
            varient="standard"
            label = "Shooter Id"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('userId')}
            /> 
            <TextField
            id = "filled-flexible"
            defaultValue = {value.tnxId}
            value={value.tnxId}
            varient="standard"
            label = "Transcation Id"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('tnxId')}
            />
            <TextField
              id="datetime-local"
              label="Transcation Date"
              type="date"
              value={value.tnxDate}  
              sx={{mt: { md: 2, xs: 2,sm:2 }}}
              onChange={handleChangeInput('tnxDate')}
              InputProps = {
                {
                  inputProps: {
                    max: `${(new Date(new Date() - (new Date()).getTimezoneOffset()*60000)).toISOString().split('T')[0]}`
                  }
                }
              }
            /> 
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} mt={2}>
              Upload Transcation screenshot
          </Typography>
              <input
                accept = "image/png"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="logo"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFile ? selectedFile.name : <>select screenshot</>}
                </Typography>
                </label>
        </Stack>
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={1}>
          <Button variant="outlined" onClick={addRequest}>Request</Button>
          <Button variant="outlined" onClick={()=>{setOpen(false);
            setValue({...value,
                userId: '',
                tnxId: '',
                tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
              })
          }}>Close</Button>
        </Stack> </Stack>:<div>
          <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }} >
                <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                  <TableHead>
                  <TableRow>
                  <TableCell align="left">Shooter Id</TableCell>
                  <TableCell align="left">Shooter Name</TableCell>
                  <TableCell align="left">Requested On</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.length >0 && details.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
                    return (
                      <TableRow tabIndex={-1}>
                        <TableCell align="left">{item.user[0].length>0?item.user[0][0].userId:null}</TableCell>
                        <TableCell align="left">{item.user[0].length>0?item.user[0][0].displayName:null}</TableCell>
                        <TableCell align="left">{item.request[0].tnxDate.split('T')[0]}</TableCell>
                        <TableCell align="left" sx={{color:item.request[0].status==='approved'?"green":item.request[0].status==='declined'?"red":"orange"}}>{item.request[0].status}</TableCell>
                        <TableCell align="left">{item.request[0].comment === undefined ? '':item.request[0].comment}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3, 5, 10, 25]}
                colSpan={3}
                count={details.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </Scrollbar>
          </div>
        }
        {/* <Modal
          open={deleteData}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5" gutterBottom component="div">
              Do you need to delete the comment?
            </Typography>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
              <Button variant="outlined" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant = "outlined" color = "error" onClick = {() => {setDelete(false)}} >
                No
              </Button>
            </Stack>
          </Box>
        </Modal> */}
      </Container>
    </Page>
  );
});
AcademyUserRequest.propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.array,
    academyDetails: PropTypes.object
};
export default AcademyUserRequest;