// @mui
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {
    Card,
    Box,CardHeader
} from '@mui/material';

MatchDonutChart.propTypes = {
    Details: PropTypes.array,
    name: PropTypes.string
};

export default function MatchDonutChart({
    Details,
    name
}) {
    // {console.log(Details)}
    const series = Details.data
    const options = {
              chart: {
                width: 300,
                type: 'donut',
              },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270,
                }
              },
              dataLabels: {
                enabled: false
              },
              labels:['10','9','8','7','<6'],
              colors: ['#21a925', '#22bdae', '#d5be1f', '#1963bf', '#bf2819'],
              noData: {
                text: "No Data",
                align: 'center',
                verticalAlign: 'top',
                // offsetX: 0,
                // offsetY: 0,
                style: {
                  color: '#21a925',
                  fontSize: '30px',
                  fontFamily: 'roboto'
                }
              },
            //   legend: {
            //     formatter: (val, opts) =>{
            //       return `${val}`
            //     //   return `${val} - ${opts.w.globals.series[opts.seriesIndex]} score`
            //     }
            //   },  
            legend: 'none',
              responsive: [{
                breakpoint: 480,
                options: {
                  labels: ['10', '9', '8', '7', '<6'],
                  chart: {
                    width: 300
                  },
                //   legend: 'none',
                }
              }]
            }
    const name2 = name;
    return (
        <Card >
            <CardHeader title={name2} />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart options={options} series={series} type="donut" height={150} />
            </Box>
         </Card>
    );
}
