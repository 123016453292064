import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography,Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import LoginLogoLayout from '../layouts/LoginLogoLayout';
// sections
import { RegisterForm } from '../sections/auth/register';
import AuthSocial from '../sections/auth/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'right',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  maxHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const [userVerified, setUserVerified] = useState(false);

  const notifyUser = (value) => {
      setUserVerified(value)
  }
  return (
    <Page title="Register">
      <RootStyle>        
        <LoginLogoLayout/>
        {!smUp && (<div>
          <Typography variant="h2" gutterBottom sx={{ ml:{md:21,xs:13,sm:10},mt:{md:9,sm:5,xs:3} }}>
              <div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div>
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ ml:{md:16,xs:3,sm:10},textAlign:"center" }}>
              Pro-level Shooter Performance Management & Analytics
            </Typography></div>
            )}
        {userVerified === true ?null:
        <HeaderStyle>
          <p></p>
           {smUp && (
                <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                  Already have an account? {''}
                  <Link variant="subtitle2" component={RouterLink} to="/login">
                    Login
                  </Link>
                </Typography>
              )}
        </HeaderStyle>}
        <Container>
          <ContentStyle>         
             
            <Typography variant="h4" gutterBottom mt={{xs:-5,md:-1,sm:-1}}>
              Getting started
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 2 }}>Kickstart your shooting journey with niche Performance management.</Typography>
            <RegisterForm notifyUser = {notifyUser} />         

            {userVerified === true ?null:
              <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                By registering, I agree to intelliShoot&nbsp;
                <Link underline="always" color="text.primary" href="#">
                  Terms of Service
                </Link>
                {' '}and{' '}
                <Link underline="always" color="text.primary" href="#">
                  Privacy Policy
                </Link>
                .
              </Typography>
            }

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link variant="subtitle2" to="/login" component={RouterLink}>
                  Login
                </Link>
              </Typography>
            )}
            {userVerified === true ?null:
              <AuthSocial msg= "Sign up"/>
            }
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
