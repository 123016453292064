// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  score: PropTypes.number,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total,score, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        height: '100%',
        ...sx,
      }}
      {...other}
    >
     
      <Typography variant="h3">{(total)}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72}}>
        {title}
      </Typography>
      {score != ""?
      <Typography variant="caption" sx={{ opacity: 0.72}}>
        {score}
      </Typography>
      :null}
    </Card>
  );
}
