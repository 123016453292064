import PropTypes from 'prop-types';
import { useState,useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import axios from 'axios';
import toast from 'react-hot-toast';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import AccountPopover from './AccountPopover';
//
import navConfig from './NavConfig';
import { defaultAcademy} from '../../constants';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [academy, setAcademy] = useState([{displayName:"",name:""}]);
  const [user, setUser] = useState([{role:'student'}]);

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    getAcademyDetail();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getAcademyDetail = () => {
    axios
      .post(`/api/user/userAcademyDetails`, {
        emailID: account.email
      })
      .then((response) => {
        const AcademyData = response.data.data;
        // console.log(AcademyData)
        setAcademy(AcademyData[0].academy);
      })
      .catch((error) => {
        // console.log(error)
        toast.error(error.response.message);
      });
  };

  const getUser = () => {
    axios
      .post(`/api/user/getParticularUserByEmail`, {
        emailID: account.email
      })
      .then((response) => {
        const userData = response.data.data;
        setUser(userData);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 9, py: 3, display: 'inline-flex' }}>
        <Logo user={user}/>
      </Box>
      <Box sx={{ }}>
        <Typography variant="subtitle2" display="block" gutterBottom color='black' align="center" mt={{md:-3,xs:-1,sm:-2}}>
        Powered By <span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot
      </Typography>
      </Box>

      <Box sx={{ mb: 1, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {/* {account.displayName} */}
                {user[0].displayName}
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.primary' }}>
                {user[0].points} Points
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "" }}>
                {academy[0].name.toLowerCase() === defaultAcademy ?"Free Version":"Premium"}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} user={user} academy={academy}/>

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
