// @mui
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {
    Card,
    Box,CardHeader
} from '@mui/material';

userAreaChart.propTypes = {
    Details: PropTypes.array,
    seriesName: PropTypes.string,
    chartMin: PropTypes.number
};

export default function userAreaChart({
    Details,
    seriesName,
    chartMin
}) {
 
    const series = [{
        name: seriesName,
        type:'column',
        data: Details.map(o => parseFloat(o.overAllScore).toFixed(0))
        }, {
            name: "Over All Decimal",
            type: 'area',
            data: Details.map(o => parseFloat(o.overAllScoreDecimal).toFixed(0))
        },
        {
            name:"percentage",
            type:'line',
            data: Details.map(o => parseFloat(o.percentage).toFixed(0))
        }]
    const options = {
        chart: {
            height: 350,
            // type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '30%'
            }
        },
        fill: {
            // opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                // shade: 'light',
                // type: "vertical",
                opacityFrom: 1.85,
                opacityTo: 1.55,
                // stops: [0, 5, 5, 5]
            }
        },
        xaxis: {
            type: 'string',
            categories: Details.map(o => o.matchOn === undefined ? o.createdAt.split("T")[0] : o.matchOn.split("T")[0])
        },
        yaxis: [{
                seriesName: 'Over All Decimal',
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
                // title: {
                //     text: "Over All Score"
                // },
                min: chartMin
            },
            {
                seriesName: 'Over All Decimal',
                show: false
            }, {
                opposite: true,
                seriesName: '',
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
                show: false,
                // title: {
                //     text: "Percentage"
                // }
            }
        ]
    }
    return (
        <Card>
            <CardHeader title="Match Details" />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart options={options} series={series} type="area" height={350} />
            </Box>
        </Card>
    );
}
