import { Link as RouterLink, useParams } from 'react-router-dom';
import * as React from 'react';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Card,
    Link,
    MenuItem,
    Container,
    Stack,
    Typography, TextField, Button, Select,Box
} from '@mui/material';
import axios from 'axios';
import Scrollbar from "react-scrollbar";

// components
import Page from '../components/Page';
import LoginLogoLayout from '../layouts/LoginLogoLayout';
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import useResponsive from '../hooks/useResponsive';
import {userDetails} from '../constants'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '30vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function VerifyUser() {
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');  
    const { id } = useParams();
    const [userVerified, setUserVerified] = useState(false);
    const [verificationText, setVerificationText] = useState('User verifying');

    useEffect(() => {
        // console.log({id});
        axios
        .post(`/api/user/local/confirm/mail`, {
          "id": id
        })
        .then((response) => {
            // console.log(response, response.data.data.detail);
            if (response.data.data.detail === 'code not found' && response.data.data.mail === undefined){
              setUserVerified(false)
              setVerificationText("Verification Code is wrong")
            }else if (response.data.data.mail !== undefined){
              setUserVerified(true);
              setVerificationText("User updated")
              setTimeout(() => {
               window.location.assign(`/login`)
              }, 5000)
            }
            else {
              setUserVerified(false);
              setVerificationText(response.data.message)
            }
        })
        .catch((error) => {
          console.log(error)
           toast.error(error);
        });
    }, []);
    

  return (
    <Page title="User Verification">
      <RootStyle>        
        <LoginLogoLayout/>
        {!smUp && (<div>
          <Typography variant="h2" gutterBottom sx={{ ml:{md:21,xs:13,sm:10},mt:{md:9,sm:5,xs:3} }}>
              <div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div>
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ ml:{md:16,xs:3,sm:10},textAlign:"center" }}>
              Pro-level Shooter Performance Management & Analytics
            </Typography></div>
            )}

        <Container>
          <ContentStyle>         
            {userVerified === false ? 
            <div>
              <Typography variant="h4" gutterBottom mt={{xs:3,md:8,sm:3}}>
                {verificationText}
              </Typography>
              {verificationText === "Verification Code is wrong" ?
              <img src="/static/illustrations/wrong_bg.png" alt="No Details" style={{width:'300px'}}/>
            :null}
            </div>
              
            :
            <div>
              <Typography variant="h4" gutterBottom mt={{xs:3,md:8,sm:3}} sx={{color:"green"}}>
                Code is verified successfully. Will redirect you in 5 seconds...
              </Typography>
            </div>
            }
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
