import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Home from './pages/Home';
import Awards from './pages/Awards';
import AcademyHome from './pages/AcademyHome';
import Match from './pages/Match';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import ChangePassword from './pages/ChangePassword';
import RegisterLocal from './pages/RegisterLocal';
import Profile from './pages/Profile';
import UserDashboard from './pages/UserDashboard';
import AcademyDashboard from './pages/AcademyDashboard';
import MatchDetails from './pages/matchDetailsStore';
import Diary from './pages/myDiary';
import TrainingProgram from './pages/TrainingProgram';
import MyAchievements from './pages/MyAchievements';
import LeaderBoard from './pages/LeaderBoard';
import MyLinks from './pages/MyLinks';
import Competition from './pages/Competition';
import CompetitionDashboard from './pages/CompetitionDashboard';
import PublicCompetitionDashboard from './pages/PublicCompetitionDashboard';
import PublicAttendance from './pages/Attendance';
import Attendance from './pages/AttendanceView';
import AcademyDetails from './pages/AcademyDetails';
import Booking from './pages/Booking';
import AssetManagement from './pages/AssetManagement';
import Analytics from './pages/Analytics';
import AcademyConfig from './pages/AcademyConfig';
import AdminConfig from './pages/AdminConfig';

import VerifyUser from './pages/VerifyUser';
import ScoreCal from './pages/liveScorePublic';
import ScoreUser from './pages/liveScoreUser';
import ScoreUserDetails from './pages/liveScoreUserDetails';
import ChangePasswordConfirm from './pages/ChangePasswordConfirm';
import UserProfile from './pages/userProfile';
import Sponsorship from './pages/Sponsorship';
import LiveScore from './pages/LiveScore';
import ShootersList from './pages/ShootersList';

// ----------------------------------------------------------------------

export default function CustomRouter() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'academyHome', element: <AcademyHome /> },
        { path: 'app', element: <UserDashboard /> },
        { path: 'academy', element: <AcademyDashboard /> },
        { path: 'match', element: <Match /> },
        { path: 'match/user/:emailId', element: <Match /> },
        { path: 'match/:matchId', element:<MatchDetails/> },
        { path: 'match/:matchId/user/:emailId', element:<MatchDetails/> },
        { path: 'mydiary', element: <Diary /> },
        { path: 'trainingProgram', element: <TrainingProgram /> },
        { path: 'myachievements', element: <MyAchievements /> },
        { path: 'leaderboard', element: <LeaderBoard /> },
        { path: 'mylinks', element: <MyLinks /> },
        { path: 'competition', element: <Competition /> },
        { path: 'competition/:competitionId', element: <CompetitionDashboard /> },
        { path: 'attendance', element: <Attendance /> },
        { path: 'academyDetails', element: <AcademyDetails /> },
        { path: 'booking', element: <Booking /> },
        { path: 'assetManagement', element: <AssetManagement /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'academyConfig', element: <AcademyConfig /> },
        { path: 'adminConfig', element: <AdminConfig /> },
        { path: 'livescoreuser', element: <ScoreUser /> },
        { path: 'livescoreuser/:matchId', element: <ScoreUserDetails /> },
        { path: 'sponsorship', element: <Sponsorship /> },
      ],
    },
    {
      path: '/',
      // element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '404', element: <NotFound /> },
        { path: 'confirm/:id', element: <VerifyUser /> },
        { path: 'changepasswordconfirm/:id', element: <ChangePasswordConfirm /> },
        { path: 'livescore', element: <ScoreCal /> },
        { path: 'awards', element: <Awards /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: 'login', element: <Login /> },
    { path: 'profile', element: <Profile /> }, 
    { path: 'changepassword', element: <ChangePassword /> }, 
    { path: 'register', element: <Register /> }, 
    { path: 'register/user', element: <RegisterLocal /> }, 
    { path: '/public/competition/:competitionId', element: <PublicCompetitionDashboard /> },
    { path: '/:competitionId/livescore', element: <LiveScore /> },
    { path: '/attendance/:rfidID', element: <PublicAttendance /> },
    { path: '/:uniqueName', element: <UserProfile /> },
    { path: '/shooterslist',element:<ShootersList/> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
