export const userDetails = {
  userName: localStorage.getItem('username'),
  email: localStorage.getItem('email'),
  dp: localStorage.getItem('dp'),
};

export const url="https://intellishoot.com"
// export const url="http://localhost:3000"

export const matchType = [
  {
    label: 'NR',
  },
  {
    label: 'ISSF',
  },
];

export const Range = [
  {
    value: '0.5',
    label: '5',
  },
  {
    value: '1',
    label: '10',
  },
  {
    value: '2',
    label: '20',
  },
  {
    value: '3',
    label: '30',
  },
  {
    value: '4',
    label: '40',
  },
  {
    value: '6',
    label: '60',
  },
];

export const Type = [
  {
    value: 'practice',
    label: 'Practice',
  },
  {
    value: 'academy_level',
    label: 'Academy Level',
  },
  {
    value: 'inter_academy',
    label: 'Inter Academy',
  },
  {
    value: 'state',
    label: 'State',
  },
  {
    value: 'prenational',
    label: 'Pre-National',
  },
  {
    value: 'national',
    label: 'National',
  },
  {
    value: 'team_trials',
    label: 'Team Trials',
  },
  {
    value: 'international',
    label: 'International',
  },
  {
    value: 'olympics',
    label: 'Olympics',
  },
  {
    value: 'mini_olympics',
    label: 'Mini Olympics',
  },
];

export const DateFilter = [
  {
    value: 7,
    label: 'Last 7 days',
  },
  {
    value: 30,
    label: 'Last 30 days',
  },
  {
    value: 60,
    label: 'Last 60 days',
  },
  {
    value: 90,
    label: 'Last 90 days',
  },
  {
    value: 365,
    label: 'Last 1 year',
  },
];

export const competitionCategory = [
  {
    value: 'veteran',
    label: 'Veteran',
  },
  {
    value: 'master',
    label: 'Master',
  },
  {
    value: 'senior',
    label: 'Senior',
  },
  {
    value: 'junior',
    label: 'Junior',
  },
  {
    value: 'youth',
    label: 'Youth',
  },
  {
    value: 'sub youth',
    label: 'Sub Youth',
  },
  {
    value: 'under 14',
    label: 'Under 14',
  },
];

export const teamType = [
  {
    label: 'Individual',
  },
  {
    label: 'Team',
  },
  {
    label: 'Mixed',
  },
  {
    label: 'Final',
  },
];

export const Gender = [
  {
    label: 'Men',
  },
  {
    label: 'Women',
  },
  {
    label: 'NA',
  },
];
export const shooterCategory = [
  {
    label:'General'
  },{
    label:'Para'
  },{
    label:'Service'
  }
]
export const BookingDurations = [{
    label: '1 Hr',
    key: 70900
  },
  {
    label: '1.5 Hr/90 Mins',
    key: 76400
  },
  {
    label: '2 Hrs',
    key: 81900
  },
  {
    label: '3 Hrs',
    key: 92700
  },
];

export const logoPath = "./logoImage";
export const defaultAcademy = "intellishoot academy";
export const defaultAcademyId = "62e27b4eb4a478f7f9d13888";
// export const defaultAcademy = "madhu academy"; //madhu academy for dev testing
// export const defaultAcademyId = "627f63cff2b8db1a643c3c20"; //madhu academy for dev testing
export const navConfigConst = ["home", "dashboard", "match", 'knowledge base', 'my diary', "live score","sponsorship","competitions"];
export const ACADEMY_POINT_SEQUENCE=[5,3,2,1,1]