import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Typography, Container, Autocomplete,Grid,TextField,Stack,
  Card, CardHeader,Box,Modal,MenuItem,
Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
import Scrollbar from "react-scrollbar";
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import Iconify from '../components/Iconify';
import { AppWidgetSummary,MatchHeatMap} from '../sections/@dashboard/app';
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import {Type,Range,userDetails,defaultAcademy} from '../constants';
import MatchDashboard from './matchDashboard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function LiveScore() {  
  const [timeNow, setTimeNow] = useState({time:new Date()})

  useEffect(() => {
    getMatches();
    getCourse();
    getUser();
    const interval = setInterval(() => getTime(timeNow.time), 1000);
    return () => clearInterval(interval);
  }, [timeNow.time]);
  
  const { matchId } = useParams();
  const [values, setValues] = React.useState({
    course: '',
    type: '',
    range: '',
    matchDate: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
    noOfShot:'10',
    completedTime:"",
    score:""
  });
  const [rowsPerPage, setRowsPerPage] = useState(60);
  const [page, setPage] = useState(0);
  const [match, setMatch] = useState(false);
  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [editScore, setEditScore] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [newScore, setNewScore] = React.useState(0);
  const [Course, setCourse] = React.useState([]);
  const [partCourse, setPartCourse] = React.useState([]);
  const [courseDetails, setCourseDetails] = React.useState([]);
  const [user, setUser] = React.useState([{displayName:''}]);
  const [matchData, setMatchData] = React.useState([{break:[],status:"inprogres",noOfSeries:10}]);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [totalShot, setTotalShot] = React.useState(0);
  const [shotvalues, setShotValues] = React.useState([]);
  const [seriesDetail, setSeriesDetail] = React.useState({});
  const [series, setSeries] = React.useState([]);
  const [seriesLength, setSeriesLength] = React.useState(0);
  const [academyDetails, setAcademyDetails] = React.useState([{name:""}]);
  const [matchShot, setMatchShot] = React.useState([]);
  const [seriesDataFormated, setSeriesDataFormated] = React.useState([]);
  const [seriesScore, setSeriesScore] = React.useState([]);  
  const [shot, setShot] = React.useState([]);
  const [liveData, setLiveData] = React.useState({
    "avg8": 0,
    "avg9": 0,
    "avg10": 0,
    "sum": 0,
    "sumDecimal": 0
  })

  const getTime = () => {
    const time = Date.now() - Date.parse(timeNow.time) ;

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const getMatches = () => {
    axios
      .post(`/api/matches/getParticularMatches`, {
        _id: matchId
      })
      .then((response) => {
        const MatchData = response.data.data;
        setMatchData(MatchData);
        getseries(MatchData);
        getCoursePar(MatchData);
      })

      .catch((error) => {
        console.log("match ",error)
        toast.error(error);
      });
  };
  const getCoursePar = (CourseData) => {
    axios
      .post(`/api/course/getParticularCourse`, {
        _id: CourseData[0].courseId,
      })
      .then((response) => {
        const courseData = response.data.data;
        setPartCourse(courseData);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const getCourse = () => {
    axios
      .get(`/api/course/getAll`)
      .then((response) => {
        const courseData = response.data.data;
        setCourseDetails(courseData);
        const value = [];
        courseData.map((item, index) => {
          const value1 = {
            value: `${item.type}-${item.range}`,
            label: `${item.displayName} ${item.range}m`,
          }
          value.push(value1)
        })
        setCourse(value);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getUser = () => {    
    axios
      .post(`/api/user/getParticularUserByEmail`,{"emailID":userDetails.email})
      .then((response) => {
        const userData = response.data.data;
        setUser(userData);
        getAcademy(userData)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

    const getAcademy = (data) => {
      axios
        .post(`/api/academy/getParticularAcademy`, {
          "_id": data[0].academyId
        })
        .then((response) => {
          let acadata = response.data.data;
          setAcademyDetails(acadata);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (props) => (event) => {
    if (props === 'score' && (parseFloat(event.target.value)>10.9)){
      toast.error("Score cannot be greater than 10.9");
    }else{
      let value = event.target.value;
      if(props === 'score'){value = parseFloat(event.target.value)}
      setValues({
        ...values,
        [props]: value
      });
    }
  };

  const getseries = (MatchData) => {
    axios
      .post(`/api/series/getSeriesByMatchId`, { matchId: MatchData[0]._id })
      .then((response) => {
        if (response.data.data.length > 0) {
          const seriesData = response.data.data;
          setSeries(seriesData);
          setSeriesScore(seriesData)
          seriesData.some((item, index) => {
            if (item.status === 'Inprogress') {
              setSeriesDetail(item);
              setSeriesLength(index);
              getshot(item);
              return true;
            }
            return false;
          });
          getMatchShots(MatchData, seriesData);
        }
      })
      .catch((error) => {
        console.log("series ",error)
        toast.error(error);
      });
  };
    const getshot = (series) => {
      axios
        .post(`/api/shot/getShotBySeriesId`, {
          seriesId: series._id
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            const shotData = response.data.data;
            setShot(shotData);
          }
        })
        .catch((error) => {
          console.log("shots ",error);
          toast.error(error);
        });
    };

  const getMatchShots = (MatchData, seriesData) => {
    axios
      .post(`/api/shot/getShotByMatchId`, {
        matchId: seriesData[0].matchId,
      })
      .then((response) => {
        const shotData = response.data.data;
        setMatchShot(shotData);
        setShotValues(shotData);
        setTotalShot(shotData.length);
        if(shotData.length >0){
        liveScoreCalculation(seriesData[0].matchId, MatchData, shotData.length)
        }
        const seriesArray = [];
        seriesData.map((item, index) => {
          const obj = {
            data: [],
            direction: [],
          };
          shotData.map((shot, index1) => {
            if (item._id === shot.seriesId) {
              obj.data.push(shot.score);
              obj.direction.push(shot.direction);
            }
            return obj;
          });
          seriesArray.push(obj);
          return seriesArray;
        });
        setSeriesDataFormated(seriesArray);
      })
      .catch((error) => {
        console.log("match shots ",error);
        toast.error(error);
      });
  }; 
  const matchStatusUpdate = () => {
    axios
      .put(`/api/matches/update`, {
        _id: matchData[0]._id,
        status: 'Completed',
        timeTakenToComplete:`${hours<10?'0'+hours:hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
      })
      .then((response) => {
        getMatches();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const liveScoreCalculation = (matchId, match, shotLength) => {
    axios
      .post(`/api/shot/liveScoreCalculation`, {
        matchId: matchId,
      })
      .then((response) => {
        const shotData = response.data.data;
        setLiveData({
          ...liveData,
          "sum": shotData[0].sum,
          "sumDecimal": shotData[0].sumDecimal
        })
        liveCalculation(0, match, shotData[0].sum, shotData[0].sumDecimal, shotLength);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  const liveCalculation = (score, match, sum, sumDecimal, shotLength) => {
    let sumDecimalvalue = Math.round((sumDecimal + score) * 10) / 10;
    let sumvalue = Math.round(sum + parseInt(score));
    let scoreLength = (match[0].noOfSeries * 10) - shotLength
    let avg8 = Math.floor((scoreLength * 8) + sumvalue)
    let avg9 = Math.floor((scoreLength * 9) + sumvalue)
    let avg10 = Math.floor((scoreLength * 10) + sumvalue)
    setLiveData({
      ...liveData,
      "avg8": avg8,
      "avg9": avg9,
      "avg10": avg10,
      "sum": sumvalue,
      "sumDecimal": sumDecimalvalue
    })
  }

  const graphseries = [{
    name: "shots",
    data: matchShot.map(o => o.score),
  }]
  const options = {
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'stepline'
    },
     yaxis: [{
       min:7,max:10.9,
       labels: {
         formatter: function (val) {
           return val.toFixed(1);
         },
       }
     }]
  }

  const handleSave=()=>{
    if (values.score === '') {
      toast.error('Enter score');
    } else {
      // setisSubmitting(true);
      const totalShotCount = totalShot + 1;
      const data = {
        matchId: matchData[0]._id,
        seriesId: seriesDetail._id,
        userId: user[0]._id,
        score: parseFloat(parseFloat(values.score).toFixed(1)),
        direction: "E",
        name: `shot_${totalShotCount}`,
        displayName: `shot ${totalShotCount}`,
      };
      // console.log(data)
      setTotalShot(totalShotCount);
      // liveCalculation(parseFloat(score).toFixed(1))
      axios
        .post(`/api/shot/add`, data)
        .then((response) => {
          
          if (matchData[0].noOfSeries === 0.5 && shot.length + 1 >= 5) {
            // setShotOver(true);
            getMatches();
            setValues({...values,completedTime:timeNow.time})
            matchStatusUpdate();
            toast.success('Successfully done all the shot');
          } else if (series.length === seriesLength + 1 && shot.length + 1 >= 10) {
            // setShotOver(true);
            getMatches();
            setValues({...values,completedTime:timeNow.time})
            matchStatusUpdate();
            toast.success('Successfully done all the shot');
          } else {
            toast.success(response.data.message);
          }

          if (shot.length + 1 >= 10 && series.length >= seriesLength + 1) {
            setShot([]);
            axios
              .put(`/api/series/update`, {
                _id: seriesDetail._id,
                status: 'Completed',
              })
              .then((response) => {
                // const seriesData = response.data.data;
                // setMatchShot(shotData);
                console.log('series status update');
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response.data);
              });

            setSeriesDetail(series[seriesLength + 1]);
            setSeriesLength(seriesLength + 1);
            getshot(series[seriesLength + 1]);

            axios
              .put(`/api/series/update`, {
                _id: series[seriesLength + 1]._id,
                status: 'Inprogress',
              })
              .then((response) => {
                // const seriesData = response.data.data;
                // setMatchShot(shotData);
                console.log('series status update');
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response.data);
              });
          } else {
            getshot(seriesDetail);
          }
          getMatchShots(matchData, series);
          setValues({...values,"score":""});
          setisSubmitting(false);
          getseries(matchData);
          // liveScoreCalculation(matchData[0]._id, matchData, totalShotCount)
        })
        .catch((error) => {
          setisSubmitting(false);
          console.log(error);
          toast.error(error.response.data.message);
        });
    }
  }

const onkeyScore = () => (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleSave();
    }
  }
  
  const breakPoint = () => {
    axios
      .post(`/api/matches/breakUpdate`, {
        _id: matchData[0]._id,
        shot: totalShot,
      })
      .then((response) => {
        getMatches();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  }

  const editScoreFun = (data) => {
    axios
      .put(`/api/shot/update`, {_id:data._id,score:newScore,userId:data.userId,seriesId:data.seriesId})
      .then((response) => {
        if (response) {
          const data = response.data.data;
          setEditScore(false)
          getMatchShots(matchData, series);
          getMatches();
          setSelectedItem({});
          liveScoreCalculation(matchData[0]._id, matchData, totalShot)
          
        }
      })
      .catch((error) => {
        console.log(error);
        setisSubmitting(false);
        toast.error(error.response.data);
      });
  }

  const handleChangeEdit = (props) => (event) => {
    if (props === 'new score' && (parseFloat(event.target.value) > 10.9)) {
      toast.error("Score cannot be greater than 10.9");
    } else {
      setNewScore(parseFloat(event.target.value))
      setValues({...values,score:parseFloat(event.target.value)})
    }
  };


  return (
    <Page title="Live Score">
      <Toast />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" >
          <Stack direction="row" alignItems="center">
            <Typography variant="h4" gutterBottom>
              Match Details
            </Typography>
          </Stack>        
          <Iconify
            icon="akar-icons:arrow-back-thick"
            sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
            width={22}
            height={22}
            onClick={() => { window.location.href = `/dashboard/livescoreuser`} }/>
        </Stack>
        {matchData[0].status.toLowerCase() !== 'completed'?
        <Stack direction={{md:"row",sx:'row',xs:"column"}} alignItems="center" justifyContent="center">  
         <div className="timer" role="timer">
          {/* <p id="day">{days < 10 ? "0" + days : days}</p> */}
           <Typography variant="h4" gutterBottom>{hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</Typography>
        </div>
        </Stack> :null}
        {matchData[0].status.toLowerCase() === 'completed'?
        <Stack direction="column">
        <Typography variant="h6" gutterBottom mt={1} mb={6}>Completed in {matchData[0].timeTakenToComplete} {matchData[0].break.length>0? `and breaks taken after ${matchData[0].break.map((item=>{return item }))} shots`:null}</Typography>
          <MatchDashboard
            courseDetails={partCourse}
            matchDetails={matchData}
            shotDetails={matchShot}
            series={series}
            seriesDataFormated={seriesDataFormated}
            directionCount={[]}
            academyDetails={academyDetails}
          />
        </Stack>
        :
        <div>
        <Grid container spacing={2} mt={0} mb={4}>
          <Grid item xs={6} sm={3} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:'20vh'}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"55%",md:"25%"}}} score="" title={`${totalShot}/${matchData[0].noOfSeries*10} shots`} total= {`${liveData.sum}` + `(${liveData.sumDecimal})`} icon={'ant-design:android-filled'} />
          </Grid>  
          <Grid item xs={6} sm={1.5} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"75%",md:"25%"}}} score="" title="All 8s" total= {liveData.avg8} icon={'ant-design:windows-filled'} />
          </Grid> 
          <Grid item xs={6} sm={1.5} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"75%",md:"25%"}}} score="" title="All 9s" total= {liveData.avg9} icon={'ant-design:bug-filled'} />
          </Grid> 
          <Grid item xs={6} sm={2} md={2} sx={{height:{md:"30vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"55%",md:"25%"}}} score="" title="Max" total= {liveData.avg10} icon={'ant-design:android-filled'} />
          </Grid> 
        <Grid item xs={12} sm={5} md={4}> 
        <Stack direction={{md:"row",sm:"row",xs:'row'}} justifyContent="" spacing={2} mt={2}>
          {seriesScore.map((item)=>{return (<div>
            <h4>{item.name == "series_0.5"?"S 1":item.name.charAt(0).toUpperCase()+item.name.charAt(item.name.length-1)}</h4><h4>{item.overAllScore}</h4>
            </div>)})}
        </Stack>
       <TextField
          id = "outlined-number"
          defaultValue = {values.score}
          value={values.score}
          label="Score"
          type="number"
          inputProps={{ min: 0, max: 10.9, maxLength: 5}}
          step= "2"
          sx={{mt:2,width:'100%'}}
          onChange={handleChange('score')}          
          onKeyDown={onkeyScore()}
        />
          
        <Stack direction={{md:"row",sm:"row",xs:'column'}} justifyContent="" spacing={2} mt={2}>
          <Button variant="outlined" onClick={handleSave} mt={2}>
            Submit
          </Button>
          <Button variant="outlined" color="error" mt={2} onClick={()=>{setValues({...values,"score":0})}}>
            Clear
          </Button>
          <Button variant="outlined" color="secondary" onClick={()=>{breakPoint()}} >
            Break
          </Button>
          </Stack>
          </Grid>
        </Grid>
        {matchData[0].break.length > 0?<Typography variant="h6" mb={1}>Breaks taken after {matchData[0].break.map((item=>{return `${item},`}))} shots</Typography>:null}
        
        <Card>
          <CardHeader title="Shot Details" />
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ReactApexChart options={options} series={graphseries} type="line" height={350} />
          </Box>
        </Card>
        
        {/* <Scrollbar style={{ height: "70vh", width: "98.5%", paddingRight: "1rem",marginTop:'2%' }}> */}
          <TableContainer component={Paper} style={{marginTop:'2%'}}>
          <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
            <TableHead>
            <TableRow>
            <TableCell align="left">SNo</TableCell>
            <TableCell align="left">Score</TableCell>
            <TableCell align="left">Score Decimal</TableCell>
            <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchShot.length >0 && matchShot.reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
              return (
                <TableRow >
                  <TableCell align="left">{index+1+(page * rowsPerPage)}</TableCell>
                  <TableCell align="left">{Math.floor(item.score)}</TableCell>
                  <TableCell align="left">{item.score}</TableCell>
                  <TableCell align="left">{<Iconify icon = "material-symbols:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} onClick={()=>{setNewScore(item.score);setEditScore(true);setSelectedItem(item)}} />}</TableCell>                  
                  </TableRow>
              ) 
            })}
          </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5,10,20,30,40,60]}
          colSpan={3}
          count={shot.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* </Scrollbar> */}
        </div>}

        <Modal
            open={editScore}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {md:'46%',sm:'90%',xs:'90%'},
                bgcolor: 'background.paper',
                p: 4,
              }}
              noValidate
              autoComplete="off"
            >
            <Typography variant="h5" gutterBottom component="div">
              Please enter the score you need to update
            </Typography>
            <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>
            <TextField
              id = "outlined-number"
              defaultValue = {newScore}
              value={newScore}
              label="New Score"
              type="number"
              inputProps={{ min: 0, max: 10.9, maxLength: 5}}
              step= "2"
              sx={{mt:2,width:'100%'}}
              onChange={handleChangeEdit("new score")}
            />
            </Stack>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
            <Button variant="outlined" onClick = {() => {editScoreFun(selectedItem)}}>
              submit
            </Button>              
            <Button variant = "outlined" color = "error" onClick = {() => {setEditScore(false);setSelectedItem({})}} >
              cancel
            </Button>
            </Stack>
          </Box>
        </Modal> 
        {isSubmitting && (
          <Modal
            open={isSubmitting}
          >         
            <Box
              component="form"
              sx = {{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height:'80%',
                bgcolor: 'background.paper',
              }}
              noValidate
              autoComplete="off"
            >
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              bgcolor: 'background.paper',
            }}
          />
          </Box>
          </Modal>
        )}
      </Container>
    </Page>
  );
}
