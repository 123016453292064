import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Page from '../components/Page';
import { Stack,Typography,Box,Button,Grid,
} from '@mui/material';
import Iconify from '../components/Iconify';

const AwardsPage = () => {
  const styles = {
    body: {
      fontFamily: 'Arial, sans-serif',
      margin: 0,
      padding: 0,
      backgroundColor: '#f4f4f4',
    },
    container: {
      width: '90%',
      maxWidth: '1200px',
      margin: '20px auto',
      padding: '20px',
      backgroundColor: 'white',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    awardDetails: {
      padding: '20px',
      backgroundColor: '#e0f7fa',
      borderBottom: '2px solid #ccc',
    },
    hallOfFame: {
      padding: '20px',
    },
    h1: {
      fontSize: '24px',
      color: '#00796b',
    },
    p: {
      lineHeight: 1.6,
    },
    h2: {
      marginBottom: '10px',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
    },
    th: {
      padding: '12px',
      textAlign: 'left',
      backgroundColor: '#00796b',
      color: 'white',
      border: '1px solid #ddd',
    },
    td: {
      padding: '12px',
      textAlign: 'left',
      border: '1px solid #ddd',
    },
    a: {
      textDecoration: 'none',
      color: '#00796b',
    },
  };
  const [awardees, setAwardees] = useState([]);
  useEffect(() => {
    const fetchAwardees = async () => {
      try {
        const response = await axios.get('/api/awards/getAll'); // Adjust the API endpoint as needed
        setAwardees(response.data.data);
      } catch (error) {
        console.error('Error fetching awardees:', error);
      }
    };

    fetchAwardees();
  }, []);
  return (
    <Page title="Home" style={{backgroundColor:"white"}}>
      {/* <Card> */}
        <Grid container spacing={2} style={{paddingTop:"1%"}}>
          <Grid item xs={3} md={2} sm={3}>
            <Stack direction="row" justifyContent="center">
             <Box component="img" src={`/static/ishoot.svg`} alt="Intellishoot" sx={{ width: 100, height: 100 }}  />
             </Stack>
          </Grid>
          <Grid item xs={9} md={4} sm={9} >
            <Stack direction="column" alignItem="center" justifyContent="center">
            <Typography variant="h3" gutterBottom style={{textAlign:"center"}}><div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div></Typography>
            <Typography variant="subtitle1" gutterBottom style={{textAlign:"center"}}>Pro-level Shooter Performance Management & Analytics</Typography>
          </Stack>
          </Grid> 
          <Grid item xs={12} md={6} sm={10} mt={2} >
            <Stack direction="row" justifyContent={{md:"flex-end",xs:"center",sm:"center"}} spacing={1}>
            <Button variant="text" href="#competitions">Competitions</Button>
              <Button variant="text" onClick={()=>{window.open(`${url}/shooterslist`, '_blank')}}>Intelliverse</Button>
              <Button variant="text" href="#feature">Features</Button>
              {/* <Button variant="text"  href="#shootingevent" disabled>Shooting Event</Button> */}
              <Button variant="text" onClick={()=>{window.open(`${url}/livescore`, '_blank')}}>Live Score</Button>
              <Button variant="text" href="#about">Contact Us</Button>
              <Button variant="contained" onClick={()=>{window.open(`${url}/login`, '_blank')}}>Sign In</Button>
            </Stack>
          </Grid>
        </Grid>
    <div style={styles.body}>
      <div style={styles.container}>
        {/* Award Description on Top */}
        <div style={styles.awardDetails}>
          <h1 style={styles.h1}>GARUDA AWARD</h1>
          <p style={styles.p}>
            This prestigious award celebrates the shooter who demonstrates exceptional skill and precision by achieving the highest number of 10X shots during the competition. The 10X signifies the pinnacle of shooting accuracy, with each shot surpassing a score of 10.4. Open to all participants, regardless of gender or age, this award honors the shooter who best exemplifies mastery, focus, and unparalleled accuracy across the field.
          </p>
          <p style={styles.p}>
            Precision and accuracy are the foundations of shooting excellence, and this award underscores their critical importance. By recognizing top talent, it aims to inspire and nurture upcoming shooters, paving the way for future champions and potential Olympians.
          </p>
          <p style={styles.p}>
            Starting this year, we recognize several individuals for their remarkable achievements and dedication in precision shooting in Rifle and Pistol. 
            Congratulations to all the awardees!
          </p>
        </div>

        {/* Hall of Fame Table */}
        <div style={styles.hallOfFame}>
          <h2 style={styles.h2}>Hall of Fame - Awardees</h2>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Name</th>
                <th style={styles.th}>Year</th>
                <th style={styles.th}>Award Category</th>
                <th style={styles.th}>Event</th>
              </tr>
            </thead>
            <tbody>
            {awardees.map((awardee, index) => (
                <tr key={index}>
                  <td style={styles.td}>
                    <a rel="noopener noreferrer" style={styles.a}>
                      {awardee.name}
                    </a>
                  </td>
                  <td style={styles.td}>{awardee.year}</td>
                  <td style={styles.td}>{awardee.category}</td>
                  <td style={styles.td}>{awardee.event}</td>
                </tr>
              ))}
              {/* Add more awardees as needed */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
        <div id="about" >
          <Stack direction="row" justifyContent="center" mb={{md:3,xs:1,sm:1}} mt={{md:4,sm:5,xs:5}}>
            <Typography variant="h3" gutterBottom color="#2065D1">Who we are....</Typography>
          </Stack>
        <Grid container spacing={2} >
          <Grid item xs={12} md={8} sm={8}>
            <Stack direction="column" alignItems="baseline" ml={{md:5,sm:5,xs:1}}>
            <Typography variant="h6" gutterBottom style={{width:'50%'}}><Stack direction="row" justifyContent="space-equally" spacing={1} >Contact Us<Iconify icon="logos:whatsapp-icon" sx={{width:{md:"7%",sm:'14%',xs:'15%'},height:{md:"7%",sm:'14%',xs:'15%'},marginLeft:'2%'}} onClick={()=>{window.open('https://api.whatsapp.com/send?phone=919380364274&text=I%20am%20interested%20in%20intellishoot', '_blank')}} /></Stack></Typography>
            <Typography variant="h6" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>You can reach us through the following</Typography>
            <Typography variant="body1" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>Phone : +91 9380364274</Typography>
            <Typography variant="body1" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>Email : <a href="mailto:support@intellishoot.com?subject=Intellishoot Query">support@intellishoot.com</a></Typography>
              <Typography variant="body1" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  Social :
                  <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <div></div>
                    <Iconify icon="logos:facebook" sx={{width:"100%",height:"100%"}} />
                    <Iconify icon="logos:instagram-icon" sx={{width:"100%",height:"100%",color:"#f60085"}} />
                    <Iconify icon="logos:youtube-icon" sx={{width:"100%",height:"100%",color:"red"}} />
                  </Stack>
                </Stack>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sm={4} >
            {/* <Typography variant="body1" gutterBottom>
              Intellishoot is a shooter performance Analytics solution designed to bring the power of data analytics and digitzation 
            </Typography> */}
          </Grid>
        </Grid>
        </div>
    </Page>
  );
};

export default AwardsPage;