import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from 'react';
import toast from "react-hot-toast";
// material
import {
    Button,
    Typography,
    Container,
    Box,
    TextField,
    Stack,
    MenuItem, Table, TableHead, TableRow,
    TableBody,
    TableCell, TableContainer,
    TablePagination,Card, Grid, Paper, Modal
} from '@mui/material';
import Popover from '@mui/material/Popover';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import {userDetails,defaultAcademyId} from '../constants'
import "./style.css"


const academyDetails = () => {

//   const { rfidID } = useParams();
  const [time, setTime] = React.useState(`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`);
  const [user, setUser] = React.useState([{displayName:"",role:["student"]}]);
  const [academyList, setAcademyList] = React.useState([])
  const [academyUsers, setAcademyUsers] = React.useState([])
  const [totalUser, setTotalUser] = React.useState([])
  const [academy, setAcademy] = React.useState({name:'All',displayName:"All",_id:1})
  const [academyDetail, setAcademyDetail] = React.useState({name:'All',displayName:"All",_id:1})
  const [filter, setFilter] = React.useState("all");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [attendanceModel, setAttendanceModel] = React.useState(false);
  const [rfidModel, setRfidModel] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({_id:{displayName:[""]}});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverContent, setpopOverContent] = React.useState('');
  const [rfidID, setRfidID] = React.useState('');
  const [deRegisterComment, setComment] = React.useState('');
  const [deRegisterModel, setDeRegisterModel] = React.useState(false);

  useEffect(() => {
    getUser();    
  }, []);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

   const getUser = () => {
    axios
      .post(`/api/user/getParticularUserByEmail`, {
        "emailID": userDetails.email
      })
      .then((response) => {
        const userData = response.data.data;
        setUser(userData);
        if(userData[0].role.includes("superAdmin")){
           getAcademy();
           getUserDetails();
        }
        else{
           setFilter('other');
          getAcademyById(userData[0].academyId);
          getAcademyUserDetails(userData[0].academyId)
          setAcademy({_id:userData[0].academyId}); 
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getAcademy = () => {
    axios
      .get(`/api/academy/getAll`)
      .then((response) => {
        const academyData = response.data.data;
        academyData.sort((a, b) => a.name.localeCompare(b.name))
        academyData.unshift({name:'All',displayName:"All",_id:1})
        setAcademyList(academyData);
      })

      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  } 

  const getAcademyById = (data) => {
    axios
      .post(`/api/academy/getParticularAcademy`,{_id:data})
      .then((response) => {
        // console.log(response.data.data)
        setAcademyDetail(response.data.data[0])
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getUserDetails = () => {
    axios
      .get(`/api/user/academyAllUsers`)
      .then((response) => {
        
        let data = response.data.data;
        let total = 0
        data.map((item,index)=>{
            total = total+item.totalUser
        })  
        setTotalUser(total)
        setAcademyUsers(data)
        setFilter('all')
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getAcademyUserDetails = (academyId) => {
      axios
          .post(`/api/user/userByAcademyID`, {
              academyId: academyId
          })
          .then((response) => {
              let data = response.data.data;
              setTotalUser(data.length)
              setAcademyUsers(data)
              
          })
          .catch((error) => {
              console.log(error)
              toast.error(error.response.data);
          });
  }

  const handleChange = () => (event) => { 
      setPage(0)
      if (event.target.value === 1 ){
          setAcademyDetail({name:'All',displayName:"All",_id:1})
          getUserDetails()
        }else{
        getAcademyById(event.target.value)
        getAcademyUserDetails(event.target.value)
        setFilter('other')
    }
    setAcademy({_id:event.target.value})
  }

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };
    
  const handleAttenDate = (props) => (event) => {
    setTime(event.target.value);
  };

  const addAttendance = (value) => {

    axios
      .post(`/api/attendance/addUserDetail`, {
        "emailID": value._id.user,
        "time": time
      })
      .then((response) => {
        setAttendanceModel(false)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleChangeRfid = (props) => (event) => {
    setRfidID(event.target.value);
  };
  const handleChangeComment = (props) => (event) => {
    setComment(event.target.value);
  };

  const updateRFID = () =>{
    
    if (rfidID === "" || rfidID === undefined){
      toast.error("RFID input can't be empty");
    }
    else{
      axios
        .put(`/api/user/update`, {
          "_id": selectedItem._id.id,
          "rfidID": rfidID
        })
        .then((response) => {
          setRfidModel(false)
          getAcademyUserDetails(academy._id)
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

   const handleDeRegister =(selectedItem)=>{
    if (deRegisterComment === '') {
      toast.error("Please provide the comment to de-register");
    } else {
    let data ={
      "_id": selectedItem._id.id,
      academyId: defaultAcademyId
    }
    let academyData = {
      "academyId": defaultAcademyId,
      "userId": selectedItem._id.id
    }
    axios
      .put(`/api/user/update`, data)
      .then((response1) => {
        axios
          .post(`/api/user/deRegisterShooter`, { "_id": selectedItem._id.id,comment:deRegisterComment})
          .then((response1) => {
            axios
              .put(`/api/myLinks/updateAcademyId`, academyData)
              .then((response2) => {
                  setDeRegisterModel(false);setComment('');setSelectedItem({_id:{displayName:[""]}});
                  toast.success("Successfully de-register the shooter from your academy")
                })
                .catch((error) => {
                  //   console.log(error)
                  toast.error(error.message);
                });
            })
            .catch((error) => {
              //   console.log(error)
              toast.error(error.message);
            });
      })
      .catch((error) => {
        //   console.log(error)
        toast.error(error.message);
      });
    }
  }

  return (
    <Page title="Academy Report">
      <Toast />
      < Container >
       {user[0].role.includes("superAdmin") ?
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>        
          <Typography variant="h4" gutterBottom>
            Academy Details
          </Typography>
            <TextField
              id = "outlined-select-course"
              select
              fullWidth
              // label = "Academy"
              value={academy._id}
              onChange={handleChange()}
              variant = "standard"
              style={{width:'50%'}}
            >
              {
                academyList.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.displayName}
                </MenuItem>
              ))}
            </TextField>
          </Stack>  
        : null}
        <Stack direction="row"   alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h6" gutterBottom>
                  Total User in {academyDetail.displayName} - {totalUser}
            </Typography>
        </Stack>
        <Card>
            <Grid xs={12} sm={12} md={6}>
            <Scrollbar>
                <TableContainer component={Paper}>
                    <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                    <TableHead>
                        {filter === 'all'?
                            <TableRow>
                            <TableCell align="left">Academy Name</TableCell>
                            <TableCell align="left">Total User</TableCell>
                            </TableRow> :
                            <TableRow>
                            <TableCell align="left">User ID</TableCell>
                            <TableCell align="left">User Name</TableCell>
                            {/* <TableCell align="left">Email ID</TableCell> */}
                            <TableCell align="left">Total Matches</TableCell>
                            <TableCell align="left">Last Update</TableCell>
                            <TableCell align="left">Action</TableCell>
                            </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {academyUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {  
                          return (     
                            filter === 'all'?
                                <TableRow tabIndex={-1} >  
                                <TableCell align="left">{row._id.name[0]}</TableCell>
                                <TableCell align="left">{row.totalUser}</TableCell>
                                </TableRow>
                                :
                                <TableRow tabIndex={-1} > 
                                <TableCell align="left">{row.userId}</TableCell>
                                <TableCell align="left">{row._id.displayName}</TableCell>
                                {/* <TableCell align="left">{row._id.user}</TableCell> */}
                                <TableCell align="left">{row.totalMatch}</TableCell>
                                <TableCell align="left">{row.totalMatch>0 ?row.lastUpdate[0][0].split("T")[0]:"-"}</TableCell>
                                <TableCell>
                                  <Stack direction='row'>
                                    <Iconify icon = "material-symbols:person-add-outline-rounded" sx={{cursor: "pointer"}}
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                    onMouseOver={()=>setpopOverContent("Add/Update user RFID details")}
                                    onClick={() => {
                                      <div>
                                      {setRfidID(row.rfid[0])}
                                      {setSelectedItem(row)}
                                      {setRfidModel(true)}
                                      </div>
                                    }}/>
                                    <Iconify icon = "carbon:dashboard-reference" sx={{cursor: "pointer",ml:2}}
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                    onMouseOver={()=>setpopOverContent("Redirect to dashboard")}
                                    onClick={() => {
                                      window.location.assign(`/dashboard/match/user/${row._id.user}`)
                                    }}/>
                                    <Iconify icon="carbon:report" sx={{cursor: "pointer",ml:2}} 
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                    onMouseOver={()=>setpopOverContent("Click to update the attendance")}
                                    onClick={() => {
                                      <div>
                                      {setSelectedItem(row)}
                                      {setAttendanceModel(true)}
                                      </div>
                                    }}/>
                                    <Iconify icon="mdi:user-multiple-minus-outline" sx={{cursor: "pointer",ml:2}} 
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                    onMouseOver={()=>setpopOverContent("De-register Shooter from academy")}
                                    onClick={() => {
                                      <div>
                                      {setSelectedItem(row)}
                                      {setDeRegisterModel(true)}
                                      </div>
                                    }}/>
                                  </Stack>
                                </TableCell>
                                </TableRow>
                        );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    // component="div"
                    count={academyUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Scrollbar>
            </Grid>
        </Card>
        <Modal
            open={attendanceModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '46%',
                bgcolor: 'background.paper',
                p: 4,
              }}
              noValidate
              autoComplete="off"
            >
            
            <Typography variant="h5" gutterBottom component="div">
              Please select the date you need to update the attendance for {selectedItem._id.displayName} ?
            </Typography>
            <TextField
              id="datetime-local"
              label="Achievement On"
              type="datetime-local"
              value={time}   
              InputProps = {
                {
                  inputProps: {
                    max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
                  }
                }
              }
              sx={{mt: { md: 2, xs: 2,sm:2 },width:{md:'100%',xs:'120%',sm:'100%'}}}
              onChange={handleAttenDate('date')}
              // helperText = "Select the match date & time"
            /> 
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
            <Button variant="outlined" onClick = {() => {addAttendance(selectedItem)}}>
              submit
            </Button>              
            <Button variant = "outlined" color = "error" onClick = {() => {setAttendanceModel(false)}} >
              cancel
            </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal
            open={rfidModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '46%',
                bgcolor: 'background.paper',
                p: 4,
              }}
              noValidate
              autoComplete="off"
            >
            
            <Typography variant="h5" gutterBottom component="div">
              Update/Add User RFID
            </Typography>
            <TextField
              required
              id="outlined-required"
              value={rfidID}
              varient="standard"
              helperText="user code"
              type="text"
              sx={{ m: 1, width: '100%' }}
              onChange={handleChangeRfid()}
            /> 
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
            <Button variant="outlined" onClick = {() => {updateRFID(selectedItem)}}>
              submit
            </Button>              
            <Button variant = "outlined" color = "error" onClick = {() => {setRfidModel(false);setSelectedItem({_id:{displayName:[""]}})}} >
              cancel
            </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal
            open={deRegisterModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '46%',
                bgcolor: 'background.paper',
                p: 4,
              }}
              noValidate
              autoComplete="off"
            >          
            <Typography variant="h5" gutterBottom component="div">
              Once you deregister the Shooter, you will need to pay the subscription fee to add again.
              Do you still need to de-register the shooter from your academy?
            </Typography>
            <TextField
              required
              id="outlined-required"
              value={deRegisterComment}
              varient="standard"
              label="Comment"
              type="text"
              multiple
              sx={{ m: 1, width: '100%' }}
              onChange={handleChangeComment()}
            /> 
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
            <Button variant="outlined" onClick = {() => {handleDeRegister(selectedItem)}}>
              yes
            </Button>              
            <Button variant = "outlined" color = "error" onClick = {() => {setDeRegisterModel(false);setComment('');setSelectedItem({_id:{displayName:[""]}})}} >
              No
            </Button>
            </Stack>
          </Box>
        </Modal>
        <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popOverContent}</Typography>
      </Popover>
      </Container>
    </Page>
  );
}

 
export default academyDetails;